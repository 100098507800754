import './App.css';
import { Routes, Route} from 'react-router-dom';
import Login from './pages/Login/Login';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import Homepage from './pages/Homepage/Homepage';
import Waitlist from './pages/Waitlist/Waitlist';
import Dashboard from './pages/Dashboard/Dashboard';
import ProtectedRoutes from './Routes/UserRoutes';
import useLocalStorage from './../node_modules/react-use/esm/useLocalStorage';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import PublicRoutes from './Utils/PublicRoutes';
import UserRoutes from './Routes/UserRoutes';
import OtherRoutes from './Routes/OtherRoutes'
import Spinner from './Components/loading/Spinner';
import { ToastContainer,} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function App() {

  const [user, setUser] = useLocalStorage('User');

  const {loading} = useSelector(state => state.alerts)

  return (
    <>
    
    <div className="App">
    {loading && <Spinner/>}
      <Routes>
     <Route path='/login' element={<PublicRoutes><Login/></PublicRoutes>}/>
     {user ? (<Route path='/*' element={<ProtectedRoutes><UserRoutes/></ProtectedRoutes>}/>) : (
      <Route path='/*' element={<PublicRoutes>
        <OtherRoutes/>
      </PublicRoutes>} />
     )}
     </Routes>
    </div>
    {/* <ToastContainer theme="colored" position="top-right" autoClose={3000} /> */}
    </>
  );
}

export default App;
