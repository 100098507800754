import {initializeApp} from "firebase/app"
import {getAnalytics} from "firebase/analytics"
import {getMessaging} from "firebase/messaging"

const firebaseConfig = {
    apiKey: "AIzaSyCI5r-gwLQY6vJBy3uLqyELGOuArAW3kOQ",
    authDomain: "confidentialbee-abdf2.firebaseapp.com",
    projectId: "confidentialbee-abdf2",
    storageBucket: "confidentialbee-abdf2.appspot.com",
    messagingSenderId: "550794919848",
    appId: "1:550794919848:web:c5baa8d97050d1cf0c679d",
    measurementId: "G-0JGVVQLQ70",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const messaging = getMessaging(app)
