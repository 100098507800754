import { Base5 } from '../Base5';

export class SaveEntity extends Base5 {
    Items = [];

    constructor() {
        super();
        this.Controller = 'Accounts/Save';
    }

    async List(body) {
        this.Params = body
        await super.load();
        this.Items = this.ResponseBody;
    }
}
