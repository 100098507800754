import React, { useEffect, useState } from "react";
import mapI from "../../images/maps.png";
import "./EditYourAddress.css";
import { USER_ID } from "../../Constants/User";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../../redux/features/alertSlice";
import "react-toastify/dist/ReactToastify.css";
import { GetByMember } from "../../Apis/Accounts/Models/GetByMember";
import { SaveEntity } from "../../Apis/Accounts/Models/SaveEntity";
import { GoogleMap, useJsApiLoader, Marker} from "@react-google-maps/api";
import { setAcitveNav } from "../../redux/features/userSlice";
import { dataInfo } from "../../pruebasJson/data";

const containerStyle = {
  width: "90%",
  height: "480px",
};

const center = {
  lat: 34.052235,
  lng: -118.243683,
};

const EditYourAddress = () => {
  const [entity, setEntity] = useState({
    nameEntry: "",
    type: "",
    state: "",
    city: "",
    state2: "",
    zipCode: "",
    street: "",
    latitude: "",
    longitude: "",
    country: "",
    addressState: "",
    suite: "",
  });
  const [inputValue, setInputValue] = useState("");
  const [suite, setSuite] = useState("");
  const [predictions, setPredictions] = useState([]);
  const [currentLocation, setCurrentLocation] = useState("");
  const [accountId, setAccountId] = useState("");
  const dispatch = useDispatch();
  const currentId = USER_ID();
  const navigate = useNavigate();
  const year = new Date().getFullYear();
  const [map, setMap] = React.useState(null);
  const [mapView, setMapView] = useState(false)
  const [markerPosition, setMarkerPosition] = React.useState(center);
  const [street, setStreet] = useState("");
  const { favoriteLenguaje } = useSelector((state) => state.user);

  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [entryName,setEntryName] = useState("")
  const user = useSelector((state) => state.user);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBclHx78W6z0mPxJG8p-LPFzyfeWihiM20",
  });

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onMarkerDragEnd = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setMarkerPosition({ lat, lng });

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const addressComponents = results[0].address_components;
        let street = '';
        let suite = '';
        let state = '';
        let zipCode = '';

        addressComponents.forEach(component => {
          const types = component.types;
          if (types.includes('street_number')) {
            street += component.long_name + ' ';
          }
          if (types.includes('route')) {
            street += component.long_name;
          }
          if (types.includes('subpremise')) {
            suite = component.long_name;
          }
          if (types.includes('administrative_area_level_1')) {
            state = component.long_name;
          }
          if (types.includes('postal_code')) {
            zipCode = component.long_name;
          }
        });

        console.log('Street:', street);
        console.log('Suite/Apt:', suite);
        console.log('State:', state);
        console.log('ZipCode:', zipCode);
        setStreet(street);
        setSuite(suite);
        setState(state);
        setZipCode(zipCode)
      } else {
        console.error('Geocode was not successful for the following reason:', status);
      }
    });
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    if (value.trim() === "") {
      setPredictions([]);
      return;
    }

    const autocompleteService =
      new window.google.maps.places.AutocompleteService();
    autocompleteService.getPlacePredictions(
      { input: value },
      (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setPredictions(predictions);
        } else {
          setPredictions([]);
        }
      }
    );
  };

  const handlePredictionClick = (prediction) => {
    setInputValue(prediction.description);
    setPredictions([]);

    getAddressDetails(prediction.description);
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const geocoder = new window.google.maps.Geocoder();
        const latlng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        geocoder.geocode({ location: latlng }, (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            const formattedAddress = results[0].formatted_address;
            const addressComponent = results[0].address_components;
            console.log("=>>>>", results);
            setCurrentLocation(addressComponent);
            setInputValue(formattedAddress);
            getAddressDetails(formattedAddress);
          }
        });
      });
    }
  };
  const getAddressDetails = (address) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      if (
        status === window.google.maps.GeocoderStatus.OK &&
        results.length > 0
      ) {
        const addressComponents = results[0].address_components;
        const formattedAddress = results[0].formatted_address;
        console.log("Formatted Address:", formattedAddress);

        // Obtener latitud y longitud
        const latitude = results[0].geometry.location.lat();
        const longitude = results[0].geometry.location.lng();

        // Obtener otros detalles de dirección
        const street = addressComponents.find((component) =>
          component.types.includes("route")
        )?.long_name;
        const city = addressComponents.find((component) =>
          component.types.includes("locality")
        )?.long_name;
        const state = addressComponents.find((component) =>
          component.types.includes("administrative_area_level_1")
        )?.long_name;
        const zipcode = addressComponents.find((component) =>
          component.types.includes("postal_code")
        )?.long_name;

        const addressState = addressComponents.find((component) =>
          component.types.includes("country")
        )?.short_name;

        const country = addressComponents.find((component) =>
          component.types.includes("country")
        )?.long_name;

        setCurrentLocation(results[0].address_components);
        console.log("Current location", results);
        setEntity((prevState) => ({
          ...prevState,
          street: street,
          city: city,
          state2: state,
          zipCode: zipcode,
          latitude: latitude,
          longitude: longitude,
          country: country,
          addressState: addressState,
        }));

        console.log("Street:", street);
        console.log("City:", city);
        console.log("State:", state);
        console.log("Zip Code:", zipcode);
        console.log("Latitude:", latitude);
        console.log("Longitude:", longitude);
        console.log("Country", country);
        console.log("addressState", addressState);
      } else {
        console.error("Failed to get address details.");
      }
    });
  };

  const getUserInfo = async () => {
    try {
      dispatch(showLoading());
      const classRef = new GetByMember();
      await classRef.List({ MembersId: parseInt(currentId) });
      let result = classRef.ResponseBody;
      dispatch(hideLoading());
      if (result.AccountInfo) {
        const user = result.AddressInfo;
        setInputValue(user.Street);
        setEntryName(result.AccountInfo.Name)
        setEntity({
          nameEntry: "",
          type: "",
          state: "",
          city: user.City,
          state2: user.Location,
          zipCode: user.Zipcode,
          street: "",
          latitude: "",
          longitude: "",
          country: "",
          addressState: "",
          suite: "",
        });
        setAccountId(result.AccountInfo.Id);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const saveEditAddress = async () => {
    try {
    dispatch(showLoading());
    const classRef = new SaveEntity();
    await classRef.List({
      AccountsId: parseInt(accountId),
      AddressLocation: entity.country ? entity.country : "",
      AddressStreet: inputValue ? inputValue : "",
      AddressAppOrSuite: "",
      AddressCity: entity.city ? entity.city : "",
      AddressState: entity.addressState ? entity.addressState : "",
      AddressZipCode: entity.zipCode ? entity.zipCode : "",
      AddressLongitude: entity.longitude ? entity.longitude : "",
      AddressLatitude: entity.latitude ? entity.latitude : "",
      EntityTypesId: entity.type ? entity.type : null,
      StatesId: entity.state ? entity.state : null,
      Name: entryName ? entryName : "",
      Description: "test",
    })
    dispatch(hideLoading());
    let result = classRef.ResponseBody;
    if(result){
      toast.success(dataInfo[favoriteLenguaje][5]?.successAddress);
      setTimeout(() => {
        dispatch(setAcitveNav("settings"))
        navigate("/Settings");
      }, 1000);
    }
    } catch (error) {
      dispatch(hideLoading());
      console.log("Error", error)
    }
    // const myHeaders = new Headers();
    // myHeaders.append("AppName", "DbBee");
    // myHeaders.append("Content-Type", "application/json");
    // myHeaders.append(
    //   "Authorization",
    //   "Bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9"
    // );

    // const raw = JSON.stringify({
    //   AccountsId: parseInt(accountId),
    //   AddressLocation: entity.country ? entity.country : "",
    //   AddressStreet: inputValue ? inputValue : "",
    //   AddressAppOrSuite: "",
    //   AddressCity: entity.city ? entity.city : "",
    //   AddressState: entity.addressState ? entity.addressState : "",
    //   AddressZipCode: entity.zipCode ? entity.zipCode : "",
    //   AddressLongitude: entity.longitude ? entity.longitude : "",
    //   AddressLatitude: entity.latitude ? entity.latitude : "",
    //   EntityTypesId: entity.type ? entity.type : "",
    //   StatesId: entity.state ? entity.state : "",
    //   Name: entity.nameEntry ? entity.nameEntry : "",
    //   Description: "test",
    // });

    // const requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow",
    // };

    // fetch(
    //   "https://api.softsmartinc.com/Services/v2/Bee/Accounts/Save",
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((result) => {
    //     toast.success("The address was updated successfully");
    //     setTimeout(() => {
    //       navigate("/Docs");
    //     }, 1000);
    //   })
    //   .catch((error) => console.error(error));
  };

  useEffect(() => {
    getUserInfo();
  }, []);
  return (
    <>
      <div className="eaddress-principal-container">
        <div></div>
        <div className="eaddress-card-container">
        <>
          <div className="step-card-title mt-4">{dataInfo[favoriteLenguaje][3]?.editYourEntity}</div>
          <div className="step-card-line"></div>
          <div className="google-autocomplete">
            {!mapView && (
              <div className="search2">
              <div
                className="mb-2"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label htmlFor="location" className="form-label label-title">
                  {dataInfo[favoriteLenguaje][3]?.entityName}
                </label>
                <input
                  type="text"
                  placeholder="Name of entry"
                  className="form-control"
                  value={entryName}
                  onChange={(e)=>{
                    setEntryName(e.target.value)
                  }}
                />
              </div>
              <div
                className="mb-4"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label htmlFor="location" className="form-label label-title">
                  {dataInfo[favoriteLenguaje][3]?.address}
                </label>
                <input
                  id="location"
                  type="text"
                  placeholder="Street"
                  className="form-control"
                  value={inputValue}
                  onChange={handleInputChange}
                />
                <button onClick={() => {}}>
                  <img src={mapI} alt="logo-map" onClick={()=>{
                     if(mapView){
                      setMapView(false)
                    } else{
                      setMapView(true)
                      setInputValue("")
                      setPredictions([])
                    }
                  }} />
                </button>
                {/* <button onClick={getCurrentLocation}>
                  <img src={map} alt="logo-map" />
                </button> */}
                <div className="predictions-place">
                  {predictions.map((prediction, index) => (
                    <div
                      key={prediction.place_id}
                      onClick={() => handlePredictionClick(prediction)}
                      className="prediction"
                      style={{
                        borderBottom:
                          predictions.length - 1 === index
                            ? "1px solid #dee2e6"
                            : "none",
                      }}
                    >
                      {prediction.description}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            )}
            {isLoaded && mapView ? (
              <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={8}
              onLoad={onLoad}
              onUnmount={onUnmount}
              options={{
                streetViewControl: false,
                mapTypeControl: false,
              }}
            >
              <Marker
                position={markerPosition}
                draggable={true}
                onDragEnd={onMarkerDragEnd}
              />
            </GoogleMap>
            ) : (
              <></>
            )}
          </div>

          
          {inputValue ? (
            <>
            <div style={{width:"90%"}}>
            <div
                className="mb-4"
                style={{ width: "100%", display: "flex", flexDirection: "column" }}
              >
                <label htmlFor="location" className="form-label label-title">
                  {dataInfo[favoriteLenguaje][3]?.suite}
                </label>
                <input
                  type="text"
                  placeholder={dataInfo[favoriteLenguaje][3]?.suite}
                  className="form-control form-input-adjust"
                  defaultValue={entity.suite ? entity.suite : ""}
                />
              </div>
            </div>
            <div
             style={{
               width: "90%",
               display: "flex",
               justifyContent: "space-between",
             }}
           >
           
             <div
               className="mb-4"
               style={{ display: "flex", flexDirection: "column", width: "48%" }}
             >
               <label htmlFor="state" className="form-label label-title">
                 {dataInfo[favoriteLenguaje][3]?.state}
               </label>
               <input
                 id="state"
                 type="text"
                 placeholder={dataInfo[favoriteLenguaje][3]?.state}
                 className="form-control form-input-adjust"
                 defaultValue={entity.state2 ? entity.state2 : ""}
               />
             </div>
 
             <div
               className="mb-4"
               style={{ display: "flex", flexDirection: "column", width: "48%" }}
             >
               <label htmlFor="zipcode" className="form-label label-title">
                 {dataInfo[favoriteLenguaje][3]?.zipCode}
               </label>
               <input
                 id="zipcode"
                 type="text"
                 placeholder={dataInfo[favoriteLenguaje][3]?.zipCode}
                 className="form-control form-input-adjust"
                 defaultValue={entity.zipCode ? entity.zipCode : ""}
               />
             </div>
           </div>
            </>
          ) : 
          <div style={{height:`${mapView ? "0px" : "300px"}`}}></div>}
          <div
            className="step-card-line"
            style={{ backgroundColor: "#0572D2", width: "89px", height: "1px" }}
          ></div>
        </>
          {/* <div className="step-card-title mt-4">Your address</div>
          <div className="step-card-line"></div>
          <div className="google-autocomplete">
            <div className="search">
              <div
                className="mb-4"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label htmlFor="location" className="form-label label-title">
                  Address
                </label>
                <input
                  id="location"
                  type="text"
                  placeholder="Search for a place..."
                  className="form-control"
                  value={inputValue}
                  onChange={handleInputChange}
                />
                <button onClick={getCurrentLocation}>
                  <img src={map} alt="logo-map" />
                </button>
                <div className="predictions-place">
                  {predictions.map((prediction, index) => (
                    <div
                      key={prediction.place_id}
                      onClick={() => handlePredictionClick(prediction)}
                      className="prediction"
                      style={{
                        borderBottom:
                          predictions.length - 1 === index
                            ? "1px solid #dee2e6"
                            : "none",
                      }}
                    >
                      {prediction.description}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="suite-apt">
              <div
                className="mb-4"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label htmlFor="location" className="form-label label-title">
                  Suite/Apt
                </label>
                <input
                  type="text"
                  placeholder="Suite/Apt"
                  className="form-control form-input-adjust"
                  defaultValue={entity.suite}
                />
              </div>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <div
              className="mb-4"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <label htmlFor="city" className="form-label label-title">
                City
              </label>
              <input
                id="city"
                type="text"
                placeholder="City"
                className="form-control form-input-adjust"
                defaultValue={entity.city ? entity.city : ""}
              />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              className="mb-4"
              style={{ display: "flex", flexDirection: "column", width: "48%" }}
            >
              <label htmlFor="state" className="form-label label-title">
                State
              </label>
              <input
                id="state"
                type="text"
                placeholder="State"
                className="form-control form-input-adjust"
                defaultValue={entity.state2 ? entity.state2 : ""}
              />
            </div>

            <div
              className="mb-4"
              style={{ display: "flex", flexDirection: "column", width: "48%" }}
            >
              <label htmlFor="zipcode" className="form-label label-title">
                ZipCode
              </label>
              <input
                id="zipcode"
                type="text"
                placeholder="Zip Code"
                className="form-control form-input-adjust"
                defaultValue={entity.zipCode ? entity.zipCode : ""}
              />
            </div>
          </div>
          <div
            className="step-card-line"
            style={{ backgroundColor: "#0572D2", width: "89px", height: "1px" }}
          ></div>
          <div className="step-button-container">
            <button
              type="button"
              className="btn btn-primary button-steper"
              style={{ width: "100%" }}
              onClick={() => {
                saveEditAddress();
              }}
            >
              Update
            </button>
          </div> */}
           {!mapView && (
        <div className="step-button-container">
        <button
          type="button"
          className="btn btn-outline-primary button-steper"
          onClick={() => {
            dispatch(setAcitveNav("home"))
            navigate("/");
          }}
        >
          {dataInfo[favoriteLenguaje][3]?.cancelButton}
        </button>
        <button
          className="btn btn-primary button-steper"
          type="button"
          disabled={inputValue && entryName ? false : true}
          onClick={() => {
            saveEditAddress();
          }}
        >
          {dataInfo[favoriteLenguaje][2]?.update}
        </button>
      </div>
      )}
       {mapView && (
        <div className="step-button-container">
        <button
          type="button"
          className="btn btn-outline-primary button-steper"
          onClick={() => {
            setMapView(false)
          }}
        >
          {dataInfo[favoriteLenguaje][3]?.cancelButton}
        </button>
        <button
          className="btn btn-primary button-steper"
          type="button"
          disabled={street ? false : true}
          onClick={() => {
          setInputValue(street)
          setEntity({
            type: "",
            state: "",
            city: "",
            state2: state,
            zipCode: zipCode,
            street: street,
            latitude: "",
            longitude: "",
            country: "",
            addressState: "",
            suite: suite,
          });
          setMapView(false);
          }}
        >
         {dataInfo[favoriteLenguaje][2]?.continue}
        </button>
      </div>
      )}
        </div>
        <div className="eaddress-footer">© Copyright {year} NDABee.</div>
      </div>
      <ToastContainer theme="colored" position="top-right" autoClose={2000} />
    </>
  );
};

export default EditYourAddress;
