import React, { useEffect, useRef, useState, Component } from "react";
import SignaturePad from 'react-signature-canvas';
import styles from "./TestPage.css";

export default class TestPage extends Component {
  state = {
    trimmedDataURL: null
  };

  sigPad = React.createRef();

  clear = () => {
    this.sigPad.current.clear();
  };

  trim = () => {
    this.setState({
      trimmedDataURL: this.sigPad.current.getTrimmedCanvas().toDataURL('image/png')
    });
    console.log(this.sigPad.current.getTrimmedCanvas().toDataURL('image/png'))
  };

  render() {
    const { trimmedDataURL } = this.state;
    return (
      <div className={styles.container}>
        <div className={styles.sigContainer}>
          <SignaturePad
            canvasProps={{ className: styles.sigPad }}
            ref={this.sigPad}
          />
        </div>
        <div>
          <button className={styles.buttons} onClick={this.clear}>
            Clear
          </button>
          <button className={styles.buttons} onClick={this.trim}>
            Trim
          </button>
        </div>
        {trimmedDataURL ? (
          <img className={styles.sigImage} src={trimmedDataURL} alt="Signature" />
        ) : null}
      </div>
    );
  }
}

