import React, { useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import * as htmlToImage from "html-to-image";
import "./PreviewNDA.css";
import { useNavigate } from 'react-router-dom';
import { setAcitveNav } from "../../redux/features/userSlice";
import { useDispatch } from "react-redux";
const PreviewNDA = () => {
  const [step, setStep] = useState(1);
  const [showSingnature, setShowSignature] = useState(true);
  const [signature, setSignature] = useState("");
  const [sign, setSign] = useState();
  const [url, setUrl] = useState();
  const navigate =useNavigate();
  const year = new Date().getFullYear();
  const dispatch = useDispatch();
  const wrongSignature =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";

  function dataURLtoBlob(dataURL) {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  const handleSaveButtonClick = () => {
    if (signature === "") {
      console.log("Wrong signature");
      return;
    }
    htmlToImage.toPng(document.getElementById("signature"));
  };

  const handleClear = () => {
    sign.clear();
    setUrl("");
  };
  const handleGenerate = () => {
    const signatureDataURL = sign.getTrimmedCanvas().toDataURL("image/png");

    if (signatureDataURL === wrongSignature) {
      console.log("Wrong Signature");
      return;
    }

    setUrl(signatureDataURL);
  };

  return (
    <div className="preview-principal-container ">
      <div
        className="preview-card"
        style={{ marginTop: `${step === 1 ? "35px" : "70px"}` }}
      >
        {step === 1 ? (
          <div>
            <div className="preview-card-title">Preview NDA</div>
            <div className="greenline-container">
              <div className="preview-linegreen mt-3 mb-3"></div>
            </div>
            <div className="preview-iframe mt-1 mb-3">
              <iframe
                src="https://oz.stern.nyu.edu/startups/nda2.pdf"
                title="PDF Viewer"
                width="90%"
                height="600px"
              ></iframe>
            </div>
            <div className="greenline-container">
              <div
                className="preview-linegreen mt-3 mb-3"
                style={{ backgroundColor: "#0572D2", width: "89px" }}
              ></div>
            </div>
          </div>
        ) : null}

        {step === 2 ? (
          // <div className="signature-cont">
          //   <div className="preview-card-title">Sign to Complete</div>

          //     <div className="step-card-container w-100">
          //       <div
          //         style={{
          //           width: "100%",
          //           display: "flex",
          //           justifyContent: "center",
          //           gap: "70px",
          //         }}
          //       >
          //         <span
          //           onClick={() => {
          //             setShowSignature(true);
          //             setSignature("");
          //           }}
          //           style={{
          //             color: `${
          //               showSingnature ? "#383E45" : "rgba(56, 62, 69, 0.50)"
          //             }`,
          //             borderBottom: `${
          //               showSingnature
          //                 ? "1px solid green"
          //                 : "1px solid transparent"
          //             }`,
          //             paddingBottom: "4px",
          //             cursor: "pointer",
          //           }}
          //         >
          //           Draw Signature
          //         </span>
          //         <span
          //           onClick={() => {
          //             setShowSignature(false);
          //             setSignature("");
          //           }}
          //           style={{
          //             color: `${
          //               !showSingnature ? "#383E45" : "rgba(56, 62, 69, 0.50)"
          //             }`,
          //             borderBottom: `${
          //               !showSingnature
          //                 ? "1px solid green"
          //                 : "1px solid transparent"
          //             }`,
          //             paddingBottom: "4px",
          //             cursor: "pointer",
          //           }}
          //         >
          //           Type Signature
          //         </span>
          //       </div>
          //       {showSingnature ? (
          //         <>
          //           <div className="type-signature-title ">
          //             Draw your signature below
          //           </div>
          //           <div className="type-signature-container2">
          //             <SignatureCanvas
          //               canvasProps={{
          //                 width: 550,
          //                 height: 200,
          //                 className: "sigCanvas",
          //               }}
          //               ref={(data) => setSign(data)}
          //             />
          //             <button
          //               className="btn btn-outline-primary btn-sm btn-clear mt-2"
          //               style={{ fontFamily: "Inter" }}
          //               onClick={handleClear}
          //             >
          //               Clear
          //             </button>
          //           </div>
          //         </>
          //       ) : (
          //         <div style={{ width: "100%" }} className="mt-2">
          //           <div
          //             className="mb-4 w-100"
          //             style={{ display: "flex", flexDirection: "column" }}
          //           >
          //             <label
          //               htmlFor="typeSignature"
          //               className="form-label label-title"
          //             >
          //               Entry your signature
          //             </label>
          //             <input
          //               type="text"
          //               className="form-control"
          //               style={{ padding: "10px", height: "52px" }}
          //               id="typeSignature"
          //               placeholder="Name of entry"
          //               autoComplete="off"
          //               value={signature ? signature : ""}
          //               onChange={(e) => {
          //                 setSignature(e.target.value);
          //               }}
          //             />
          //           </div>
          //           <div className="draw-signature-container" id="signature">
          //             {signature}
          //           </div>
          //         </div>
          //       )}

          //     </div>

          //   <div className="greenline-container">
          //     <div
          //       className="preview-linegreen mt-3 mb-3"
          //       style={{ backgroundColor: "#0572D2", width: "89px" }}
          //     ></div>
          //   </div>
          // </div>
          <div className="signature-preview-container">
            <div className="preview-card-title">Sign to Complete</div>
            <div className="greenline-container">
              <div className="preview-linegreen mt-3 mb-3"></div>
            </div>
            <div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  gap: "70px",
                }}
              >
                <span
                  onClick={() => {
                    setShowSignature(true);
                    setSignature("");
                  }}
                  style={{
                    color: `${
                      showSingnature ? "#383E45" : "rgba(56, 62, 69, 0.50)"
                    }`,
                    borderBottom: `${
                      showSingnature
                        ? "1px solid green"
                        : "1px solid transparent"
                    }`,
                    paddingBottom: "4px",
                    cursor: "pointer",
                  }}
                >
                  Draw Signature
                </span>
                <span
                  onClick={() => {
                    setShowSignature(false);
                    setSignature("");
                  }}
                  style={{
                    color: `${
                      !showSingnature ? "#383E45" : "rgba(56, 62, 69, 0.50)"
                    }`,
                    borderBottom: `${
                      !showSingnature
                        ? "1px solid green"
                        : "1px solid transparent"
                    }`,
                    paddingBottom: "4px",
                    cursor: "pointer",
                  }}
                >
                  Type Signature
                </span>
              </div>
               {showSingnature ? (
                  <>
                    <div className="type-signature-title ">
                      Draw your signature below
                    </div>
                    <div className="type-signature-container2">
                      <SignatureCanvas
                        canvasProps={{
                          width: 550,
                          height: 200,
                          className: "sigCanvas",
                        }}
                        ref={(data) => setSign(data)}
                      />
                      <button
                        className="btn btn-outline-primary btn-sm btn-clear mt-2"
                        style={{ fontFamily: "Inter" }}
                        onClick={handleClear}
                      >
                        Clear
                      </button>
                    </div>
                  </>
                ) : (
                  <div style={{ width: "100%" }} className="mt-2">
                    <div
                      className="mb-4 w-100"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <label
                        htmlFor="typeSignature"
                        className="form-label label-title"
                      >
                        Entry your signature
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        style={{ padding: "10px", height: "52px" }}
                        id="typeSignature"
                        placeholder="Name of entry"
                        autoComplete="off"
                        value={signature ? signature : ""}
                        onChange={(e) => {
                          setSignature(e.target.value);
                        }}
                      />
                    </div>
                    <div className="draw-signature-container" id="signature">
                      {signature}
                    </div>
                  </div>
                )}
            </div>
            <div className="greenline-container">
              <div
                className="preview-linegreen mt-3 mb-3"
                style={{ backgroundColor: "#0572D2", width: "89px" }}
              ></div>
            </div>
          </div>
        ) : null}
        <div className="preview-buttons-container mt-3 mb-4">
          <button
            type="button"
            className="btn btn-outline-primary"
            style={{ width: "266px" }}
            onClick={()=>{
              dispatch(setAcitveNav("home"))
              navigate("/")
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary"
            style={{ width: "266px" }}
            onClick={() => {
              if (step === 1) {
                setStep((activeStep) => activeStep + 1);
              }
            }}
          >
            Sign
          </button>
        </div>
      </div>

      <div className="preview-footer">© Copyright {year} NDABee.</div>
    </div>
  );
};

export default PreviewNDA;
