import React, { useEffect, useRef, useState } from "react";
import "./ViewDocument.css";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { USER_ID } from "../../Constants/User";
import * as htmlToImage from "html-to-image";
import SignaturePad from "react-signature-canvas";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../../redux/features/alertSlice";
import { GetByMember } from "../../Apis/Accounts/Models/GetByMember";
import { GetSignature } from "../../Apis/Accounts/Models/GetSignature";
import { GetDocument } from "../../Apis/Documents/Models/GetDocuments";
import { SendAgain } from "./../../Apis/Documents/Models/SendAgain";
import { Sign } from "../../Apis/Documents/Models/Sign";
import { setAcitveNav } from "../../redux/features/userSlice";
import { dataInfo } from "../../pruebasJson/data";
import InfoModal3 from "../../Components/InfoModal/InfoModal3";
import notification from "../../images/notification-logo.png";
import vector from "../../images/Vector.png";

export const ViewDocument = () => {
  const { AccountId, DocumentId, isFromReceiver } = useParams();
  const currentId = USER_ID();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const wrongSignature =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";
  const isLocalhost = window.location.hostname === "localhost";
  const iframeSrc = isLocalhost
    ? "http://localhost:3000/nda-terms"
    : "https://confidentialbee.com/nda-terms";
  const sigPad = useRef();
  const year = new Date().getFullYear();

  const [documentInfo, setDocumentoInfo] = useState();
  const [stepView, setStepView] = useState(2);
  const [memberInfo, setMemberInfo] = useState();
  const [showSingnature, setShowSignature] = useState(true);
  const [signature, setSignature] = useState("");
  const [userSignature, setUserSignature] = useState("");
  const [signedDocument, setSignedDocument] = useState("");
  const { favoriteLenguaje } = useSelector((state) => state.user);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  /*-------------Signature--------------*/
  function dataURLtoBlob(dataURL) {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }
  /*Write signature*/
  const handleSaveButtonClick = async () => {
    if (signature === "") {
      console.log("Wrong signature");
      return;
    }

    htmlToImage
      .toPng(document.getElementById("signature"), {
        backgroundColor: "transparent",
      })
      .then(async function (dataUrl) {
        const formdata = new FormData();
        formdata.append("AccountId", documentInfo?.AccountId_Recipient);
        formdata.append("DocumentId", DocumentId);
        formdata.append("File", dataURLtoBlob(dataUrl), "[PROXY]");

        const classRef = new Sign();
        await classRef.List(formdata);
        let result = classRef.ResponseBody;

        setSignedDocument(result.DocumentSigned);
        console.log("Document signed successfully");
        setStepView(3);

        // const myHeaders = new Headers();
        // myHeaders.append("AppName", "DbBee");
        // myHeaders.append(
        //   "Authorization",
        //   "Bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9"
        // );

        // const requestOptions = {
        //   method: "PATCH",
        //   headers: myHeaders,
        //   body: formdata,
        //   redirect: "follow",
        // };

        // fetch(
        //   "https://api.softsmartinc.com/Services/v2/Bee/Documents/Sign",
        //   requestOptions
        // )
        //   .then((response) => response.text())
        //   .then((result) => {
        //     setSignedDocument(result.DocumentSigned);
        //     console.log("Document signed successfully");
        //     setStepView(3);
        //   })
        //   .catch((error) => console.error(error));
      })
      .catch(function (error) {
        console.error("Error:", error);
      });
  };
  /*Draw Signature*/
  const clear = () => {
    sigPad.current.clear();
  };

  const trim = async () => {
    try {
      const img = sigPad.current.getTrimmedCanvas().toDataURL("image/png");
      const formdata = new FormData();
      formdata.append("AccountId", documentInfo?.AccountId_Recipient);
      formdata.append("DocumentId", DocumentId);
      formdata.append("File", dataURLtoBlob(img), "[PROXY]");

      const classRef = new Sign();
      await classRef.List(formdata);
      let result = classRef.ResponseBody;

      setSignedDocument(result.DocumentSigned);
      console.log("Document signed successfully");
      setStepView(3);
    } catch (error) {
      console.log("Error", error);
    }
    // const img = sigPad.current.getTrimmedCanvas().toDataURL("image/png");

    // const myHeaders = new Headers();
    // myHeaders.append("AppName", "DbBee");
    // myHeaders.append(
    //   "Authorization",
    //   "Bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9"
    // );

    // const formdata = new FormData();
    // formdata.append("AccountId", documentInfo?.AccountId_Recipient);
    // formdata.append("DocumentId", DocumentId);
    // formdata.append("File", dataURLtoBlob(img), "[PROXY]");

    // const requestOptions = {
    //   method: "PATCH",
    //   headers: myHeaders,
    //   body: formdata,
    //   redirect: "follow",
    // };

    // fetch(
    //   "https://api.softsmartinc.com/Services/v2/Bee/Documents/Sign",
    //   requestOptions
    // )
    //   .then((response) => response.text())
    //   .then((result) => {
    //     setSignedDocument(result.DocumentSigned);
    //     console.log("Document signed successfully");
    //     setStepView(3);
    //   })
    //   .catch((error) => console.error(error));
  };

  const registerEntity = () => {
    if (showSingnature) {
      trim();
    } else {
      handleSaveButtonClick();
    }
  };
  /*------------------------------------*/

  /*----------Send Again----------------*/
  const sendAgain = async () => {
    try {
      const classRef = new SendAgain();
      await classRef.List({
        AccountId: parseInt(memberInfo?.AccountInfo?.Id),
        DocumentId: parseInt(DocumentId),
      });
      let result = classRef.ResponseBody;
      if (result.Status === "Successful!") {
        toast.success(dataInfo[favoriteLenguaje][5]?.successNDA);
        setTimeout(() => {
          dispatch(setAcitveNav("docs"))
          navigate("/Docs");
        }, 1500);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };
  /*------------------------------------*/

  const getDocument = async () => {
    try {
      dispatch(showLoading());
      const classRef = new GetDocument();
      await classRef.List({
        AccountId: parseInt(AccountId),
        DocumentId: parseInt(DocumentId),
        IsFromReceiver: false,
      });
      let result = classRef.ResponseBody;
      setDocumentoInfo(result.DocumentInfo);
      setSignedDocument(result.DocumentInfo.PdfUrl);
      console.log("Recipient id", result.DocumentInfo.AccountId_Recipient);
      console.log("Recipient id2", AccountId);
      setTimeout(() => {
        dispatch(hideLoading());
      }, 2500);
    } catch (error) {
      dispatch(hideLoading());
      console.log("Error", error);
    }
    // dispatch(showLoading());
    // const myHeaders = new Headers();
    // myHeaders.append("AppName", "DbBee");
    // myHeaders.append("Content-Type", "application/json");
    // myHeaders.append(
    //   "Authorization",
    //   "Bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9"
    // );

    // const raw = JSON.stringify({
    //   AccountId: parseInt(AccountId),
    //   DocumentId: parseInt(DocumentId),
    //   IsFromReceiver: false,
    // });

    // const requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow",
    // };

    // fetch(
    //   "https://api.softsmartinc.com/Services/v2/Bee/Documents/Get",
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((result) => {
    //     setDocumentoInfo(result.DocumentInfo);
    //     setSignedDocument(result.DocumentInfo.PdfUrl);
    //     console.log("Recipient id", result.DocumentInfo.AccountId_Recipient);
    //     console.log("Recipient id2", AccountId);
    //     setTimeout(()=>{
    //       dispatch(hideLoading())
    //     },2500)
    //   })
    //   .catch((error) => {
    //     console.error(error)
    //     dispatch(hideLoading())
    //   });
  };
  /*get by member*/
  const getByMember = async () => {
    try {
      const classRef = new GetByMember();
      await classRef.List({ MembersId: parseInt(currentId) });
      let result = classRef.ResponseBody;
      setMemberInfo(result);
      if (result.AccountInfo.FilesId_Signature > 0) {
        getSignature(result.AccountInfo.Id);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };
  /*get signature*/
  const getSignature = async (id) => {
    try {
      const classRef = new GetSignature(id);
      await classRef.List();
      let result = classRef.ResponseBody;

      setUserSignature(result);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const signDocument = async () => {
    try {
      dispatch(showLoading());
      const formdata = new FormData();
      formdata.append("AccountId", documentInfo?.AccountId_Recipient);
      formdata.append("DocumentId", DocumentId);

      const classRef = new Sign();
      await classRef.List(formdata);
      let result = classRef.ResponseBody;

      dispatch(hideLoading());
      // setSignedDocument(result.DocumentSignedImages[0]);
      // setStepView(3);
      setShow(true);
      setTimeout(()=>{
        setShow(false);
        navigate("/Docs");
      },2500)
    } catch (error) {
      dispatch(hideLoading());
      console.log("Error", error);
    }
  };

  useEffect(() => {
    getDocument();
    getByMember();
  }, []);
  return (
    <>
      <div className="viewDocument-principal-container">
        <div></div>
        <div className="viewDocument-main">
          {parseInt(AccountId) === documentInfo?.AccountId_Recipient &&
          !documentInfo?.SignedOn &&
          isFromReceiver === "false" ? (
            <div className="view-princial-container">
              <div className="view-card-container">
                <div className="view-card-title mb-0"> {dataInfo[favoriteLenguaje][3]?.previewNda}</div>
                <div className="view-card-line mb-2 mt-2"></div>
                <div className="view-preview">
                  <div className="iframe iframe-document p-1">
                    {/* {documentInfo?.PdfImages?.map((doc, index) => (
                      <img src={doc} alt="" key={index} />
                    ))} */}
                     <iframe
                          src={iframeSrc}
                          style={{
                            width: "100%",
                            height: "100vh",
                            border: "none",
                          }}
                          title="NDA Terms"
                        ></iframe>
                  </div>
                  {/* <DocViewer documents={docs}  pluginRenderers={DocViewerRenderers}
                 initialActiveDocument={docs[1]}
                 style={{
                   height:"410px"
                 }}/> */}
                </div>
                <div
                  className="view-card-line mb-1"
                  style={{
                    backgroundColor: "#0572D2",
                    width: "89px",
                    height: "1px",
                  }}
                ></div>
              </div>
              <div className="view-button-container">
                <button
                  className="btn btn-primary button-view"
                  type="button"
                  onClick={() => {
                    sendAgain();
                  }}
                >
                  {dataInfo[favoriteLenguaje][3]?.resendNDA}
                </button>
                <button
                  className="btn btn-outline-primary button-view"
                  type="button"
                  onClick={() => {
                    dispatch(setAcitveNav("docs"))
                    navigate("/Docs");
                  }}
                >
                  {dataInfo[favoriteLenguaje][3]?.cancelButton}
                </button>
              </div>
            </div>
          ) : null}
          {parseInt(AccountId) === documentInfo?.AccountId_Recipient &&
          !documentInfo?.SignedOn &&
          isFromReceiver === "true" ? (
            <>
              {stepView === 1 ? (
                <div className="view-princial-container">
                  <div className="view-card-container">
                    <div className="view-card-title mb-0"> {dataInfo[favoriteLenguaje][3]?.previewNda}</div>
                    <div className="view-card-line mb-2 mt-2"></div>
                    <div className="view-preview">
                      <div className="iframe iframe-document">
                        <iframe
                          src={iframeSrc}
                          style={{
                            width: "100%",
                            height: "100vh",
                            border: "none",
                          }}
                          title="NDA Terms"
                        ></iframe>
                        {/* {documentInfo?.PdfImages?.map((doc) => (
                          <img src={doc} alt="" />
                        ))} */}
                      </div>
                      {/* <iframe
                        className="iframe"
                        src={documentInfo?.PdfUrl}
                        title="PDF Viewer"
                        width="100%"
                        height="100%"
                      ></iframe> */}
                      {/* <DocViewer documents={docs}  pluginRenderers={DocViewerRenderers}
                 initialActiveDocument={docs[1]}
                 style={{
                   height:"410px"
                 }}/> */}
                    </div>
                    <div
                      className="view-card-line mb-1"
                      style={{
                        backgroundColor: "#0572D2",
                        width: "89px",
                        height: "1px",
                      }}
                    ></div>
                  </div>
                  <div className="view-button-container">
                    <button
                      className="btn btn-outline-primary button-view"
                      type="button"
                      onClick={() => {
                        dispatch(setAcitveNav("docs"))
                        navigate("/Docs");
                      }}
                    >
                      {dataInfo[favoriteLenguaje][3]?.cancelButton}
                    </button>
                    <button
                      className="btn btn-primary button-view"
                      type="button"
                      onClick={() => {
                        setStepView(2);
                      }}
                    >
                      {dataInfo[favoriteLenguaje][3]?.sign}
                    </button>
                  </div>
                </div>
              ) : null}

              {stepView === 2 ? (
                <div className="view-princial-container">
                  <div className="view-card-container">
                    <div className="view-card-title mb-0"> {dataInfo[favoriteLenguaje][3]?.signToComplete}</div>
                    <div className="view-card-line mb-4 mt-2"></div>
                    <div className="view-signature-container">
                      {memberInfo?.AccountInfo.FilesId_Signature > 0 ? (
                        <div className="view-signature-img">
                          <img src={userSignature} alt="User Signature" />
                        </div>
                      ) : (
                        <>
                          <div className="view-card-container">
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                gap: "70px",
                                marginTop: "20px",
                              }}
                            >
                              <span
                                onClick={() => {
                                  setShowSignature(true);
                                  setSignature("");
                                }}
                                style={{
                                  color: `${
                                    showSingnature
                                      ? "#383E45"
                                      : "rgba(56, 62, 69, 0.50)"
                                  }`,
                                  borderBottom: `${
                                    showSingnature
                                      ? "1px solid green"
                                      : "1px solid transparent"
                                  }`,
                                  paddingBottom: "4px",
                                  cursor: "pointer",
                                }}
                              >
                                {dataInfo[favoriteLenguaje][3]?.drawSignature}
                              </span>
                              <span
                                onClick={() => {
                                  setShowSignature(false);
                                  setSignature("");
                                }}
                                style={{
                                  color: `${
                                    !showSingnature
                                      ? "#383E45"
                                      : "rgba(56, 62, 69, 0.50)"
                                  }`,
                                  borderBottom: `${
                                    !showSingnature
                                      ? "1px solid green"
                                      : "1px solid transparent"
                                  }`,
                                  paddingBottom: "4px",
                                  cursor: "pointer",
                                }}
                              >
                                {dataInfo[favoriteLenguaje][3]?.typeSignature}
                              </span>
                            </div>
                            {showSingnature ? (
                              <>
                                <div className="type-signature-title ">
                                  {dataInfo[favoriteLenguaje][3]?.drawYourSignatureBelow}
                                </div>
                                <div className="type-signature-container2">
                                  <div
                                    className="container"
                                    style={{ backgroundColor: "white" }}
                                  >
                                    <div className="sigContainer">
                                      <SignaturePad
                                        canvasProps={{ className: "sigPad" }}
                                        ref={sigPad}
                                      />
                                    </div>
                                  </div>
                                  <button
                                    className="btn btn-outline-primary btn-sm btn-clear mt-2"
                                    style={{ fontFamily: "Inter" }}
                                    onClick={clear}
                                  >
                                    {dataInfo[favoriteLenguaje][3]?.clear}
                                  </button>
                                </div>
                              </>
                            ) : (
                              <div style={{ width: "100%" }} className="mt-2">
                                <div
                                  className="mb-4 w-100"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <label
                                    htmlFor="typeSignature"
                                    className="form-label label-title"
                                  >
                                    {dataInfo[favoriteLenguaje][3]?.entryYourSignature}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    style={{ padding: "10px", height: "52px" }}
                                    id="typeSignature"
                                    placeholder="Name of entry"
                                    autoComplete="off"
                                    value={signature ? signature : ""}
                                    onChange={(e) => {
                                      setSignature(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="border-write-signature">
                                  <div
                                    className="draw-signature-container"
                                    id="signature"
                                    style={{
                                      backgroundColor: "transparent",
                                      border: "none",
                                    }}
                                  >
                                    {signature}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className="view-card-line mt-4"
                      style={{
                        backgroundColor: "#0572D2",
                        width: "89px",
                        height: "1px",
                      }}
                    ></div>
                  </div>
                  <div
                    className="view-button-container mb-4"
                    style={{ marginTop: "-20px" }}
                  >
                    <button
                      className="btn btn-outline-primary button-view"
                      type="button"
                      onClick={() => {
                        dispatch(setAcitveNav("docs"))
                        navigate("/Docs");
                      }}
                    >
                      {dataInfo[favoriteLenguaje][3]?.cancelButton}
                    </button>
                    <button
                      className="btn btn-primary button-view"
                      type="button"
                      onClick={() => {
                        if (memberInfo.AccountInfo.FilesId_Signature > 0) {
                          signDocument();
                        } else {
                          registerEntity();
                        }
                      }}
                    >
                      {dataInfo[favoriteLenguaje][2]?.submit}
                    </button>
                  </div>
                </div>
              ) : null}

              {stepView === 3 ? (
                <div className="view-princial-container">
                  <div className="view-card-container">
                    <div className="view-card-title mb-0"> {dataInfo[favoriteLenguaje][3]?.previewNda}</div>
                    <div className="view-card-line mb-2 mt-2"></div>
                    <div className="view-preview">
                      <div className="iframe iframe-document">
                        {documentInfo?.PdfImages?.map((doc) => (
                          <img src={doc} alt="" />
                        ))}
                      </div>
                      {/* <DocViewer documents={docs}  pluginRenderers={DocViewerRenderers}
                 initialActiveDocument={docs[1]}
                 style={{
                   height:"410px"
                 }}/> */}
                    </div>
                    <div
                      className="view-card-line mb-1"
                      style={{
                        backgroundColor: "#0572D2",
                        width: "89px",
                        height: "1px",
                      }}
                    ></div>
                  </div>
                  <div className="view-button-container">
                    <button
                      className="btn btn-primary button-view"
                      type="button"
                      onClick={() => {
                        dispatch(setAcitveNav("docs"))
                        navigate("/Docs");
                      }}
                    >
                      {dataInfo[favoriteLenguaje][2]?.continue}
                    </button>
                  </div>
                </div>
              ) : null}
            </>
          ) : null}
          {documentInfo?.SignedOn ? (
            <div className="view-princial-container">
              <div className="view-card-container">
                <div className="view-card-title mb-0"> {dataInfo[favoriteLenguaje][3]?.previewNda}</div>
                <div className="view-card-line mb-2 mt-2"></div>
                <div className="view-preview">
                  <div className="iframe iframe-document">
                    {documentInfo?.PdfImages?.map((doc) => (
                      <img src={doc} alt="" />
                    ))}
                  </div>
                  {/* <DocViewer documents={docs}  pluginRenderers={DocViewerRenderers}
                 initialActiveDocument={docs[1]}
                 style={{
                   height:"410px"
                 }}/> */}
                </div>
                <div
                  className="view-card-line mb-1"
                  style={{
                    backgroundColor: "#0572D2",
                    width: "89px",
                    height: "1px",
                  }}
                ></div>
              </div>
              <div className="view-button-container">
                <button
                  className="btn btn-primary button-view"
                  type="button"
                  onClick={() => {
                    dispatch(setAcitveNav("docs"))
                    navigate("/Docs");
                  }}
                >
                  {dataInfo[favoriteLenguaje][2]?.continue}
                </button>
              </div>
            </div>
          ) : null}
        </div>
        <div className="viewDocument-footer">© Copyright {year} NDABee.</div>
      </div>
      <ToastContainer theme="colored" position="top-right" autoClose={2000} />
      <InfoModal3
      notificationImage={vector}
      notificationTitle={dataInfo[favoriteLenguaje][5]?.signed}
      show={show}
      handleClose={handleClose}
      notificationMessage={
        <div>
          {dataInfo[favoriteLenguaje][5]?.signedText}
          <br />
          {dataInfo[favoriteLenguaje][5]?.signedText2}
        </div>
      }
    />
    </>
  );
};
