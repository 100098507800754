import React, { useEffect, useState } from "react";
import "./DownloadWeb.css";
import { useParams, useNavigate, Link } from "react-router-dom";
import logo from "../../images/logo2.png";
import arrow from "../../images/carrot-arrow.png";
import googleplay from "../../images/googleplay-2.png";
import applestore from "../../images/appstore-transparent.png";
import axios from "axios";
import android from "../../images/isAndroid.png";
import iphone from "../../images/isIphone.png";
import {
  BASE_URL,
  HEADERS,
  SERVICES,
  SERVICES_NAME,
  VERSION,
} from "../../Constants/Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput from "react-phone-input-2";
import downloadLogo from "../../images/submit&download.png";
import {
  parsePhoneNumberFromString,
  getCountryCallingCode,
} from "libphonenumber-js";
import {
  isMobile,
  isAndroid,
  isIOS,
  isBrowser,
  deviceType,
  osVersion,
  mobileModel,
  browserName,
  browserVersion,
  osName,
} from "react-device-detect";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "../../redux/features/alertSlice";
import { v4 as uuidv4 } from "uuid";
import { Countries } from "../../Apis/List/Models/Countries";
import iphoneGroup from "../../images/AppleGroup.png";
import GoogleGroup from "../../images/GoogleGroup.png";
import { GetByReferenceCode } from "../../Apis/Documents/Models/GetByReferenceCode";
import { RegisterFromInvitation } from "./../../Apis/Members/Models/RegisterFromInvitation";
import { dataInfo } from "../../pruebasJson/data";

const DownloadWeb = () => {
  const baseUrl = BASE_URL + SERVICES + "/" + VERSION + "/" + SERVICES_NAME;
  const navigate = useNavigate();
  const { guid } = useParams();
  const pguid = "74ecc575-c765-4dda-a504-027919485732";
  const dispatch = useDispatch();

  const [userInfo, setUserInfo] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [valid, setValid] = useState(true);
  const [phone, setPhone] = useState();
  const [countryCode, setCountryCode] = useState();
  const [country, setCountry] = useState();
  const [device, setDevice] = useState();
  const [documentError, setDocumentError] = useState(false);
  const [documentRead, setDocumentRead] = useState(false);
  const [email,setEmail] = useState("");
  const [formulario, setFormulario] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    PhoneCountryCode: "",
    PhoneNo: "",
    MemberExists: "",
  });
  const [lenguaje, setLenguaje] = useState("en");

  const resetFrom = () => {
    setFormulario({
      FirstName: "",
      LastName: "",
      Email: "",
      PhoneCountryCode: "",
      PhoneNo: "",
    });
    setPhoneNumber("");
  };

  const handlePhoneChange = (value, country, e, formattedValue) => {
    setPhoneNumber(formattedValue);
    setFormulario({ ...formulario, PhoneNo: formattedValue });
    setFormulario({ ...formulario, PhoneCountryCode: `+${country.dialCode}` });
    setDialCode("+" + country.dialCode);
    setValid(validatePhoneNumber(formattedValue));
  };

  const formatNumber = (value) => {
    setPhoneNumber(value);
    const phoneNumber2 = parsePhoneNumberFromString(value);
    if (phoneNumber2) {
      const dialCode3 = phoneNumber2.countryCallingCode;
      const number = phoneNumber2.nationalNumber;
      setCountryCode(dialCode3);
      setPhone(number);
      console.log("Dialcode:", dialCode3);
      console.log("Número:", number);
      getCountry(`+${dialCode3}`);
    }
  }

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;

    return phoneNumberPattern.test(phoneNumber);
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setFormulario({ ...formulario, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("form", formulario);
    console.log("phone", phoneNumber);
    console.log("dialcode", dialCode);
  };

  const getInvitation = async () => {
    dispatch(showLoading());
    try {
      const classRef = new GetByReferenceCode();
      await classRef.List({
        ReferenceCode: guid,
      });
      let result = classRef.ResponseBody;
      console.log("Result", result);
      if (result.FirstName) {
        localStorage.setItem("guid", guid);
        setFormulario({
          FirstName: result.FirstName,
          LastName: result.LastName,
          Email: result.Email,
          PhoneCountryCode: "",
          PhoneNo: formulario.PhoneNo,
          MemberExists: result.MemberExists,
        });
        setEmail(result.Email);
        setPhoneNumber(result.PhoneNo);
        const phoneNumber2 = parsePhoneNumberFromString(result.PhoneNo);
        if (phoneNumber2) {
          const dialCode3 = phoneNumber2.countryCallingCode;
          const number = phoneNumber2.nationalNumber;
          setCountryCode(dialCode3);
          setPhone(number);
          console.log("Dialcode:", dialCode3);
          console.log("Número:", number);
          getCountry(`+${dialCode3}`);
        }
        dispatch(hideLoading());
      } else if (result.message === "Document not found") {
        setDocumentError(true);
        console.log("Document not found");
        dispatch(hideLoading());
      }
    } catch (error) {
      dispatch(hideLoading());
      console.error("Error", error);
      setDocumentError(true);
    }

    // const myHeaders = new Headers();
    // myHeaders.append("AppName", "DbBee");
    // myHeaders.append("Content-Type", "application/json");
    // myHeaders.append(
    //   "Authorization",
    //   "Bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9"
    // );

    // const raw = JSON.stringify({
    //   ReferenceCode: guid,
    // });

    // const requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow",
    // };

    // fetch(
    //   "https://api.softsmartinc.com/Services/v2/Bee/Documents/GetByReferenceCode",
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((result) => {
    //     if (result.FirstName) {
    //       localStorage.setItem("guid", guid);
    //       setFormulario({
    //         FirstName: result.FirstName,
    //         LastName: result.LastName,
    //         Email: "",
    //         PhoneCountryCode: "",
    //         PhoneNo: "",
    //       });
    //       setPhoneNumber(result.PhoneNo);
    //       const phoneNumber2 = parsePhoneNumberFromString(result.PhoneNo);
    //       if (phoneNumber2) {
    //         const dialCode3 = phoneNumber2.countryCallingCode;
    //         const number = phoneNumber2.nationalNumber;
    //         setCountryCode(dialCode3);
    //         setPhone(number);
    //         console.log("Dialcode:", dialCode3);
    //         console.log("Número:", number);
    //         getCountry(`+${dialCode3}`);
    //       }
    //       dispatch(hideLoading())
    //     } else if (result.message === "Document not found") {
    //       setDocumentError(true);
    //       console.log("Document not found");
    //       dispatch(hideLoading())
    //     }
    //   })
    //   .catch((error) => {
    //     dispatch(hideLoading())
    //     console.error(error)});
  };
  const getCountry = async (dial) => {
    const classRef = new Countries();
    await classRef.List({ SearchString: dial });
    let result = classRef.ResponseBody;
    setCountry(result.Response[0].CountryName);
  };
  const goHome = () => {
    navigate("/");
  };

  const registerByInvitation = async () => {
    if (!formulario.Email) {
      return toast.warning(dataInfo[lenguaje][5]?.warningRequestEmail);
    }
   try {
      const classRef = new RegisterFromInvitation();
      if(!formulario.Email  && !phoneNumber){
        return toast.warning(dataInfo[lenguaje][5]?.warningEmailAndPhone)
      }
      await classRef.List({
        Email: formulario.Email,
        FirstName: formulario.FirstName,
        LastName: formulario.LastName,
        Country: country,
        PhoneCountryCode: countryCode ? `+${countryCode}` : null,
        PhoneNo: phone,
        ReferenceCode: guid,
      });
      let result = classRef.ResponseBody;
      if (result.MemberInfo) {
        localStorage.setItem("ConfidentialBeeGuid", guid);
        localStorage.setItem("ConfidentialBeeMembersId", result.MemberInfo.Id);
        toast.success(dataInfo[lenguaje][5]?.successRegistered);
      } else {
        toast.warning(result.message);
      }
      if (result.DeepLink) {
        setTimeout(() => {
          window.location.href = result.DeepLink;
        }, 1500);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const registerByInvitationEmail = async () => {
    if(!formulario.Email  && !phoneNumber){
      return toast.warning(dataInfo[lenguaje][5]?.warningEmailAndPhone)
    }
   try {
    const phoneNumber2 = parsePhoneNumberFromString(phoneNumber);
    if (phoneNumber2) {
      const dialCode3 = phoneNumber2.countryCallingCode;
      const number = phoneNumber2.nationalNumber;
      setCountryCode(dialCode3);
      setPhone(number);

      const classRef = new Countries();
      await classRef.List({ SearchString: `+${dialCode3}` });
      let result = classRef.ResponseBody;
      setCountry(result.Response[0].CountryName);
      const searchCountry = result.Response[0].CountryName

      const classRef2 = new RegisterFromInvitation();
      await classRef2.List({
        Email: formulario.Email,
        FirstName: formulario.FirstName,
        LastName: formulario.LastName,
        Country: searchCountry,
        PhoneCountryCode: "+" + dialCode3,
        PhoneNo: number,
        ReferenceCode: guid,
      });
      let result2 = classRef2.ResponseBody;
      if (result2.MemberInfo) {
        localStorage.setItem("ConfidentialBeeGuid", guid);
        localStorage.setItem("ConfidentialBeeMembersId", result2.MemberInfo.Id);
        toast.success(dataInfo[lenguaje][5]?.successRegistered);
      } else {
        toast.warning(result2.message);
      }
      if (result2.DeepLink) {
        setTimeout(() => {
          window.location.href = result2.DeepLink;
        }, 1500);
      }

      console.log("formulario",{
          Email: formulario.Email,
          FirstName: formulario.FirstName,
          LastName: formulario.LastName,
          Country: searchCountry,
          PhoneCountryCode: dialCode3,
          PhoneNo: number,
          ReferenceCode: guid,
        })
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    getInvitation();
    const userLanguage = navigator.language || navigator.userLanguage;
    const languageCode = userLanguage.startsWith('es') ? 'es' : 'en';
    setLenguaje(languageCode);
    if (isMobile && isAndroid) {
      setDevice("Android");
      console.log("Androidddd");
    } else if (isMobile && isIOS) {
      setDevice("IPhone");
      console.log("iphoneeee");
    } else if (isBrowser && !isMobile) {
      setDevice("Desktop");
      console.log("desktopppp");
    }
    console.log(isMobile && isAndroid && "Android.");
    console.log(isMobile && isIOS && "IPhone.");
    console.log(isBrowser && !isMobile && "Desktop");
    console.log(deviceType && `Device: ${deviceType}`);
    console.log(osVersion && `osVersion: ${osVersion}`);
    console.log(mobileModel && !isMobile && `mobileModel: ${mobileModel}`);
    console.log(browserName && `browserName: ${browserName}`);
    console.log(browserVersion && `browserVersion: ${browserVersion}`);
    console.log(osName && `osName: ${osName}`);
  }, []);
  return (
    <>
      <div className="download-principal-container">
        {/*logo*/}
        <div className="row w-100">
          <div className="download-principla-logo">
            <img
              src={logo}
              alt="bee-logo"
              onClick={goHome}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        {/*Principal container*/}
        {/* {userInfo ? (
        <div className="download-info-container">
          <div className="download-title">
            <span className="download-title1">Welcome</span>
            <span className="download-title2">
              {userInfo.FirstName} {userInfo.LastName}
            </span>
            <span className="download-title1">{userInfo.PhoneNo}</span>
          </div>
          <div className="download-container">
            <div className="download-line mt-4 mb-4"></div>
          </div>
          <div className="download-container">
            <div className="download-nda">
            your NDA is ready for signature
            </div>
          </div>
          <div className="download-container">
            <div className="download-line mt-4 mb-4"></div>
          </div>
          <div className="download-container download-application-title">
          Download the <br /> application
          </div>
          <div className="download-container mt-3 mb-4">
            <img src={arrow} alt="download arrow" />
          </div>
          <div className="download-container mt-3 mb-3">
            <img src={googleplay} alt="download arrow" width="280px" height="50px"/>
          </div>
          <div className="download-container mt-3 mb-3">
            <img src={applestore} alt="download arrow"  width="280px" height="50px"/>
          </div>
        </div>
      ) : null} */}

        {formulario.MemberExists ? (
          <div className="download-info-container">
            <div className="download-title">{dataInfo[lenguaje][3]?.welcome}</div>
            <div className="margin-title">
              <div className="col-12">
                <div className="button-download2">
               {dataInfo[lenguaje][3]?.downloadMessage1} <br />
                {dataInfo[lenguaje][3]?.downloadMessage2}
                </div>
              </div>
            </div>
            <Link
            to="https://confidentialbee.app.link"
              className="download-button-container2 mt-5"
              // onClick={() => {
              //   navigate("/login");
              // }}
            >
              <div>{dataInfo[lenguaje][3]?.openNDA}</div>
            </Link>
          </div>
        ) : null}

        {!documentError && !documentRead && !formulario.MemberExists ? (
          <div className="download-info-container">
            <div className="download-title">{dataInfo[lenguaje][3]?.welcome}</div>
            <div>
              <div className="mb-4 div-form">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label label-title-download"
                >
                  {dataInfo[lenguaje][2]?.firstname}
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  value={formulario.FirstName}
                  name="FirstName"
                  onChange={handleChange2}
                  className="form-control"
                  style={{ padding: "10px", height: "52px" }}
                  id="exampleFormControlInput1"
                  placeholder={dataInfo[lenguaje][2]?.firstname}
                  required
                />
              </div>

              <div className="mb-4 div-form">
                <label
                  htmlFor="exampleFormControlInput2"
                  className="form-label label-title-download"
                >
                  {dataInfo[lenguaje][2]?.lastname}
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  value={formulario.LastName}
                  name="LastName"
                  onChange={handleChange2}
                  className="form-control"
                  style={{ padding: "10px", height: "52px" }}
                  id="exampleFormControlInput2"
                  placeholder={dataInfo[lenguaje][2]?.lastname}
                  required
                />
              </div>

              <div className="mb-4 div-form">
                <label
                  htmlFor="exampleFormControlInput3"
                  className="form-label label-title-download"
                >
                  {dataInfo[lenguaje][2]?.phoneNumber}
                </label>
                <div className="phone-input">
                  <PhoneInput
                    country={"us"}
                    required
                    value={phoneNumber}
                    onChange={handlePhoneChange}
                    inputProps={{
                      style: {
                        height: "52px",
                        border: "1px solid lightgray",
                        width: "100%",
                      },
                      required: true,
                    }}
                    disabled={formulario.PhoneNo ? true : false}
                  />
                </div>
              </div>

              <div className="mb-4 div-form">
                <label
                  htmlFor="exampleFormControlInput4"
                  className="form-label label-title-download"
                >
                  {dataInfo[lenguaje][2]?.email}
                </label>
                <input
                  type="email"
                  className="form-control"
                  value={formulario.Email}
                  name="Email"
                  onChange={handleChange2}
                  style={{ padding: "10px", height: "52px" }}
                  id="exampleFormControlInput4"
                  placeholder={dataInfo[lenguaje][2]?.email}
                  disabled={email ? true : false}
                  required
                />
              </div>

              <div className="col-12">
                <div className="button-download">
                {dataInfo[lenguaje][3]?.documentReady} <br />
                {dataInfo[lenguaje][3]?.documentReady2}
                </div>
              </div>
            </div>
            {/* <button onClick={()=>{console.log("Formulario",formulario)}}>prueba</button> */}
            <div
              className="download-button-container"
              onClick={() => {
                if(!formulario.PhoneNo){
                  registerByInvitationEmail()
                } else{
                  registerByInvitation();
                }
              }}
            >
              {device === "Android" || device === "IPhone" ? (
                <div className="download-button">
                  <div className="button-download-title">
                    {dataInfo[lenguaje][3]?.submitAndDownload}
                  </div>
                  <div className="whiteline-download"></div>
                  <div className="img-button-container">
                    <img
                      src={
                        device === "Android"
                          ? GoogleGroup
                          : device === "IPhone"
                          ? iphoneGroup
                          : null
                      }
                      alt="download-logo"
                    />
                  </div>
                </div>
              ) : (
                <img src={downloadLogo} alt="download-logo" />
              )}
            </div>
          </div>
        ) : null}

        {documentError && !documentRead ? (
          <div className="download-info-container">
            <div className="download-title">{dataInfo[lenguaje][3]?.welcome}</div>
            <div>
              <div className="col-12">
                <div className="button-download2">
                  {dataInfo[lenguaje][3]?.downloadMessage3} <br />
                  {dataInfo[lenguaje][3]?.downloadMessage4}
                </div>
              </div>
            </div>
            {/* <div
              className="download-button-container2"
              onClick={() => {
                navigate("/login");
              }}
            >
              <div>Open The App to See the NDA.</div>
            </div> */}
             <Link
            to="https://confidentialbee.app.link"
              className="download-button-container2 mt-5"
              // onClick={() => {
              //   navigate("/login");
              // }}
            >
              <div>{dataInfo[lenguaje][3]?.openNDA}</div>
            </Link>
          </div>
        ) : null}

        {!documentError && documentRead ? (
          <div className="download-info-container">
            <div className="download-title">{dataInfo[lenguaje][3]?.welcome}</div>
            <div>
              <div className="col-12">
                <div className="button-download2">
                  {dataInfo[lenguaje][3]?.downloadMessage5} <br />
                  {dataInfo[lenguaje][3]?.downloadMessage6}
                </div>
              </div>
            </div>
            {/* <div
              className="download-button-container2"
              onClick={() => {
                navigate("/login");
              }}
            >
              <div>Go to login</div>
            </div> */}
             <Link
            to="https://confidentialbee.app.link"
              className="download-button-container2 mt-5"
              // onClick={() => {
              //   navigate("/login");
              // }}
            >
              <div>{dataInfo[lenguaje][3]?.openNDA}</div>
            </Link>
          </div>
        ) : null}

        {/*footer */}
        <div className="download-footer">
          <span
            onClick={() => {
              navigate("/terms-and-conditions");
            }}
            style={{ cursor: "pointer" }}
          >
            {dataInfo[lenguaje][3]?.terms}
          </span>
          <span
            onClick={() => {
              navigate("/privacy-policy");
            }}
            style={{ cursor: "pointer" }}
          >
            {dataInfo[lenguaje][3]?.privacy}
          </span>
          <span>© 2024 ConfidencialBee.</span>
        </div>
      </div>
      <ToastContainer theme="colored" position="top-right" autoClose={2000} />
    </>
  );
};

export default DownloadWeb;
