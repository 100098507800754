import React, { useEffect, useState } from "react";
import logo from "../../images/logo.png";
import appstore from "../../images/appstore.png";
import playstore from "../../images/googleplay.png";
import phone1 from "../../images/Asset3.png";
import { NavLink, useNavigate, Link} from "react-router-dom";
import menu from "../../images/menu.png"
import close from "../../images/close.png"
import "./Homepage.css";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { AiFillInstagram } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { setFavoriteLenguaje } from "../../redux/features/userSlice";
import { dataInfo } from "../../pruebasJson/data";

const Homepage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [lenguaje, setLenguaje] = useState("en");
  const { favoriteLenguaje } = useSelector((state) => state.user);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const userLanguage = navigator.language || navigator.userLanguage;
    const languageCode = userLanguage.startsWith('es') ? 'es' : 'en';
    setLenguaje(languageCode);
    dispatch(setFavoriteLenguaje(languageCode));
    console.log("Idioma del navegador:", languageCode);
  }, [dispatch]);
  return (
    <>
      <div className="homepage-container">
        {/*logo and menu container*/}
        <div className="menu-logo-container">
          <div className="logo-home-container">
            <img src={logo} alt="logo" />
          </div>
          <div className="menu-home-container">
          <div className="web-menu">
            <NavLink to="/privacy-policy">{dataInfo[lenguaje][0]?.description1}</NavLink>
            <NavLink to="/terms-and-conditions">{dataInfo[lenguaje][0]?.description2}</NavLink>
            <NavLink to="/remove-account">{dataInfo[lenguaje][0]?.description3}</NavLink>
           
            <NavLink to="/wait-list">
            <div className="box-waitlist" style={{textAlign:`${lenguaje === "es" ? "center" : null}`}}>
             {dataInfo[lenguaje][0]?.description4}
            </div>
            </NavLink>
            
            <NavLink to="/login">
            <div className="box-login" style={{textAlign:`${lenguaje === "es" ? "center" : null}`}}>
            {dataInfo[lenguaje][0]?.description5}
              </div>
            </NavLink>
          </div>
          <div className="mobile-menu">
            <div onClick={toggleDrawer}>
              <img src={menu} alt="menu" className="img-menu"/>
            </div>
            <div className={`drawer ${isOpen ? "open" : ""}`}>
             <div className="header-menu">
              <div></div>
              <div onClick={toggleDrawer} className="closeBtn">
                <img src={close} alt="close" />
              </div>
             </div>
             <div className="drawer-menu">
             <NavLink to="/privacy-policy">{dataInfo[lenguaje][0]?.description1}</NavLink>
            <NavLink to="/terms-and-conditions">{dataInfo[lenguaje][0]?.description2}</NavLink>
            <NavLink to="/remove-account">{dataInfo[lenguaje][0]?.description3}</NavLink>
            <NavLink to="/wait-list">{dataInfo[lenguaje][0]?.description4}</NavLink>
            <NavLink to="/login">{dataInfo[lenguaje][0]?.description5}</NavLink>
             </div>
            </div>
          </div>
          </div>
        </div>
        {/*Main content*/}
        <div className="main-homepage-content">
          <div className="main-homepage-image">
            <img src={phone1} alt="iphone-phone" />
          </div>
          <div className="main-homepage-title">
            <div className="main-home-principal-title1">{dataInfo[lenguaje][0]?.welcomeTitle1}</div>
            <div className="main-home-principal-title2">{dataInfo[lenguaje][0]?.welcomeTitle2}</div>
            <div className="main-home-principal-title3">{dataInfo[lenguaje][0]?.welcomeTitle3}</div>
            <p>{dataInfo[lenguaje][0]?.welcomeText1}
            <br /><br />
            {dataInfo[lenguaje][0]?.welcomeText2}
            </p>
            {/* <button onClick={()=>{console.log("lenguaje favorito",favoriteLenguaje)}}>lenguaje favorito</button> */}
            <h2>{dataInfo[lenguaje][0]?.welcomeButton}</h2>
            <div className="store-images">
              <Link to="https://apps.apple.com/ca/app/confidential-bee/id6472225083" target="_blank">
              <img src={appstore} alt="appstore" />
              </Link>
              <Link to="https://play.google.com/store/apps/details?id=com.softsmart.confidentialbee&hl=es&gl=US" target="_blank">
              <img src={playstore} alt="playstore" />
              </Link>
            
            </div>
            <div className="homepage-social-media">
              <Link to="https://www.facebook.com" target="_blank">
              <FaFacebookF size={24} color="white"/>
              </Link>

              <Link to="https://x.com/?lang=en" target="_blank">
              <FaXTwitter size={25} color="white"/>
              </Link>

              <Link to="https://www.instagram.com/" target="_blank">
              <AiFillInstagram size={25} color="white"/>
              </Link>

              <Link to="https://www.linkedin.com" target="_blank">
              <FaLinkedinIn size={25} color="white"/>
              </Link>

            </div>
          </div>
        </div>
        {/*footer content*/}
        <div className="footer-homepage-content">
        © Copyright 2024 ConfidencialBee.
        </div>
      </div>
    </>
  );
};

export default Homepage;
