import React from 'react'
import "./OtherCards.css"
import { Link } from 'react-router-dom'
const OtherCards = (props) => {
  return (
    // <Link to={props.link} target='_blank'>
    <div className='other-card-container' style={{background:`${props.background}`}} onClick={props.onClick}>
    <div className='box-model' style={{backgroundColor:`${props.backgroundModel}`}}></div>
    <div>
        <div className='other-img'>
            <img src={props.image} alt="logo" />
        </div>
        <div className='other-line-container'>
            <div className='other-line' style={{backgroundColor:`${props.backgroundLine}`}}></div>
        </div>
        <div className='text-center' style={{color:`${props.colorText1}`}}>
        {props.text1}
        </div>
        <div className='text-center2' style={{color:`${props.colorText2}`}}>
        {props.text2}
        </div>
    </div>
    <div className='box-model-footer' style={{backgroundColor:`${props.backgroundFooter}`}}></div>
    </div>
    // </Link>
  )
}

export default OtherCards