import { Base } from '../../Accounts/Base';

export class ValidateSms extends Base {
    Items = [];

    constructor() {
        super();
        this.Model = 'Members/Verify/Validate';
    }

    async List(body) {
        this.Params = body
        await super.load();
        this.Items = this.ResponseBody;
    }
}
