import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    FirstName: "",
    LastName: "",
    AvatarURL: "",
    ActiveNav: "home",
    favoriteLenguaje: "en",
  },
  reducers: {
    setFirstName: (state, action) => {
      state.FirstName = action.payload;
    },
    setLastName: (state, action) => {
      state.LastName = action.payload;
    },
    setAvatarURL: (state, action) => {
      state.AvatarURL = action.payload;
    },
    setAcitveNav: (state, action) => {
      state.ActiveNav = action.payload;
    },
    setFavoriteLenguaje: (state, action) => {
      state.favoriteLenguaje = action.payload;
    },
  },
});

export const { setFirstName, setLastName, setAvatarURL, setAcitveNav, setFavoriteLenguaje } = userSlice.actions;
