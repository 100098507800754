import { Base } from '../Base';
import { Modal } from 'react-bootstrap/Modal';

export class Register extends Base {
    Items = [];

    constructor() {
        super();
        this.Model = 'Accounts';
        this.Controller="Register"
    }

    async List(body) {
        this.Params = body
        await super.load();
        this.Items = this.ResponseBody;
    }
}
