import { Base3 } from '../Base3';

export class Sign extends Base3 {
    Items = [];

    constructor() {
        super();
        this.Controller = 'Documents/Sign';
    }

    async List(body) {
        this.Params = body
        await super.load();
        this.Items = this.ResponseBody;
    }
}