import React, { useEffect, useState } from "react";
import "./EditEntity.css";
import arrow from "../../images/arrow.png";
import { useNavigate } from "react-router-dom";
import { USER_ID } from "../../Constants/User";
import { GetByMember } from "../../Apis/Accounts/Models/GetByMember";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../../redux/features/alertSlice";
import { GetSignature } from "../../Apis/Accounts/Models/GetSignature";
import { dataInfo } from "../../pruebasJson/data";

const EditEntity = () => {
  const navigate = useNavigate();
  const currentId = USER_ID();
  const [userInfo, setUserInfo] = useState();
  const [userSignature, setUserSignature] = useState();
  const year = new Date().getFullYear();
  const dispatch = useDispatch();
  const { favoriteLenguaje } = useSelector((state) => state.user);

  const getByMember = async () => {
    dispatch(showLoading());
    try {
      const classRef = new GetByMember();
      await classRef.List({ MembersId: parseInt(currentId) });
      let result = classRef.ResponseBody;
      dispatch(hideLoading());
      if (result.AccountInfo) {
        console.log("Result edit info", result);
        setUserInfo(result);
        getSignature(result?.AccountInfo.Id);
      }
    } catch (error) {
      dispatch(hideLoading());
      console.log("Error", error);
    }
  };
  const getSignature = async (id) => {
    try {
    const classRef = new GetSignature(id);
    await classRef.List();
    let result = classRef.ResponseBody;
    if(result.message === "Signature not found" ){
      toast.warning(result.message)
    } else{
      setUserSignature(result);
    }

    } catch (error) {
      console.log("Error",error)
    }
  };
  
  useEffect(() => {
    getByMember();
  }, []);
  return (
    <>
      <div className="eentity-principal-container">
        <div></div>
        <div className="eentity-main">
          <div className="eentity-title">{dataInfo[favoriteLenguaje][3]?.yourEntity2}</div>
          <div className="eentity-greenline-container">
            <div className="eentity-greenline mt-2 mb-2"></div>
          </div>
          <div className="eentity-card-container">
            {/* <div
              className="eentity-card"
              onClick={() => {
                navigate("/edit-your-entity");
              }}
            >
              <div className="eentity-header">
                <div className="eentity-card-title">Your Entity</div>
                <img src={arrow} alt="arrow-img" />
              </div>
              <div className="eentity-card-content">
                {userInfo?.AccountInfo.Name}{" "}
              </div>
            </div> */}
            <div className="e-card-greenline"></div>
            <div
              className="eentity-card"
              onClick={() => {
                navigate("/edit-your-address");
              }}
            >
              <div className="eentity-header">
                <div className="eentity-card-title">{dataInfo[favoriteLenguaje][3]?.editYourEntity}</div>
                <img src={arrow} alt="arrow-img" />
              </div>
              <div className="eentity-card-content">
                {userInfo?.AddressInfo.Street}
              </div>
              <div className="eentity-card-content">
                {userInfo?.AddressInfo.Apporsuite}
              </div>
              <div className="eentity-card-content">
                {userInfo?.AddressInfo.City}
              </div>
              <div className="eentity-card-content">
                {userInfo?.AddressInfo.Location}
              </div>
            </div>
            <div className="e-card-greenline"></div>
            <div className="eentity-card">
              <div className="eentity-header">
                <div className="eentity-card-title">{dataInfo[favoriteLenguaje][3]?.documents}</div>
                <div></div>
              </div>
              <div className="eentity-card-content-agree" onClick={()=>{navigate("/nda-terms")}}>
                NDA Contract Agrement
              </div>
            </div>
            <div className="e-card-greenline"></div>
            <div
              className="eentity-card"
              onClick={() => {
                navigate("/edit-your-signature");
              }}
            >
              <div className="eentity-header">
                <div className="eentity-card-title">{dataInfo[favoriteLenguaje][3]?.yourSignature}</div>
                <img src={arrow} alt="arrow-img" />
              </div>
              <div className="eentity-show-signature">
                <img src={userSignature} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="eentity-footer">© Copyright {year} NDABee.</div>
      </div>
      <ToastContainer theme="colored" position="top-right" autoClose={2000} />
    </>
  );
};

export default EditEntity;
