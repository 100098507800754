import React from 'react'
import { Routes,Route, Navigate } from 'react-router-dom';
import Homepage from '../pages/Homepage/Homepage';
import Waitlist from '../pages/Waitlist/Waitlist';
import Login from '../pages/Login/Login';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import DownloadWeb from '../pages/DownloadWeb/DownloadWeb';
import TermsOfService from '../pages/TermsOfService/TermsOfService';
import Privacy from '../pages/Privacy/Privacy';
import RemoveAccount from '../pages/RemoveAccount/RemoveAccount';
import NdaTerms from '../pages/NDATerms/NdaTerms';

const PublicRoutes = () => {
  return (
    <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/wait-list" element={<Waitlist />} />
        <Route path="/terms-and-conditions" element={<TermsOfService />} />
        <Route path='/privacy-policy' element={<Privacy/>}/>
        <Route path='/nda-terms' element={<NdaTerms/>}/>
        <Route path='/remove-account' element={<RemoveAccount/>}/>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path='/inv/:guid' element={<DownloadWeb/>}/>
        <Route path='/*' element={<Navigate to="/"/>}/>
    </Routes>
  )
}

export default PublicRoutes