import { createSlice } from "@reduxjs/toolkit"; 

export const accountInfo = createSlice({
    name:"account",
    initialState:{
        data:null
    },
    reducers:{
        setAccountInfo: (state, action) => {
            state.data = action.payload;
          },
    }
})

export const {setAccountInfo} = accountInfo.actions