export const USER_ID = () => {
    const User = JSON.parse(localStorage.getItem("User"));
    return User.Auth.Authentication.Id;
};
export const USER_NAME = () => {
    const User = JSON.parse(localStorage.getItem("User"));
    return User.Auth.UserInfo.FirstName + ' ' + User.Auth.UserInfo.LastName;
};
export const USER_AVATAR = () => {
    const User = JSON.parse(localStorage.getItem("User"));
    return User.Auth.UserInfo.AvatarGuid;
};
export const ROLE_ID = () => {
    const User = JSON.parse(localStorage.getItem("User"));
    return User.Auth.UserInfo.RolesId;
};
export const ROLE_NAME = () => {
    const Role = JSON.parse(localStorage.getItem("Roles"));
    return Role.Name;
};
export const ROLE_DESCRIPTION = () => {
    const Role = JSON.parse(localStorage.getItem("Roles"));
    return Role.Description;
};
export const USER_TOKEN = () => {
    const User = JSON.parse(localStorage.getItem("User"));
    return User?.Auth.Authentication.AccessToken;
};
export const USER_TOKEN_ID = () => {
    const User = JSON.parse(localStorage.getItem("User"));
    return User.Auth.UserInfo.TokensId;
};