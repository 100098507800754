import { createSlice } from "@reduxjs/toolkit"; 

export const userInfo = createSlice({
    name:"info",
    initialState:{
        data:null
    },
    reducers:{
        setUserInfo: (state, action) => {
            state.data = action.payload;
          },
    }
})

export const {setUserInfo} = userInfo.actions