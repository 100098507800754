import React, { useEffect, useRef, useState, useCallback } from "react";
import { FaCheck } from "react-icons/fa";
import "./Stepper.css";
import axios from "axios";
import {
	BASE_URL,
	HEADERS,
	SERVICES,
	SERVICES_NAME,
	VERSION,
} from "../../Constants/Auth";
import mapI from "../../images/maps.png";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import SignaturePad from "react-signature-canvas";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../../pages/TestPages/TestPage.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../../redux/features/alertSlice";
import { GetByMember } from "../../Apis/Accounts/Models/GetByMember";
import { GetAccountType } from "../../Apis/Accounts/Models/GetAccountType";
import { SignatureSave } from "../../Apis/Accounts/Models/SignatureSave";
import { Register } from "./../../Apis/Accounts/Models/Register";
import { GetStatesList } from "../../Apis/External/Models/GetStatesList";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { setAcitveNav } from "../../redux/features/userSlice";
import { dataInfo } from "../../pruebasJson/data";

const containerStyle = {
	width: "90%",
	height: "480px",
};

const center = {
	lat: 34.052235,
	lng: -118.243683,
};

const Stepper = () => {
	const baseURL = BASE_URL + SERVICES + "/" + VERSION + "/" + SERVICES_NAME;
	const navigate = useNavigate();
	const { Id } = useParams();
	const wrongSignature =
		"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";
	const isLocalhost = window.location.hostname === "localhost";
	const iframeSrc = isLocalhost
		? "http://localhost:3000/nda-terms"
		: "https://confidentialbee.com/nda-terms";
	const dispatch = useDispatch();
	const [activeStep, setActiveStep] = useState(1);
	const [entityTypesList, setEntityTypesList] = useState([]);
	const [stateList, setStateList] = useState([]);
	const [typeSelectedId, setTypeSelectedId] = useState();
	const [entity, setEntity] = useState({
		type: "",
		state: "",
		city: "",
		state2: "",
		zipCode: "",
		street: "",
		latitude: "",
		longitude: "",
		country: "",
		addressState: "",
		suite: "",
	});
	const [inputValue, setInputValue] = useState("");
	const [predictions, setPredictions] = useState([]);
	const [currentLocation, setCurrentLocation] = useState("");
	const [showSingnature, setShowSignature] = useState(true);
	const [signature, setSignature] = useState("");
	const [sign, setSign] = useState();
	const [url, setUrl] = useState();
	const [accountInfo, setAccountInfo] = useState();
	const [resgisterInfo, setRegisterInfo] = useState();
	const [latLng, setLatLng] = useState(null);
	const [autocomplete, setAutocomplete] = useState(null);
	const [mapView, setMapView] = useState(false);
	const [markerPosition, setMarkerPosition] = React.useState(center);
	const [street, setStreet] = useState("");
	const [suite, setSuite] = useState("");
	const [state, setState] = useState("");
	const [zipCode, setZipCode] = useState("");
	const [entryName, setEntryName] = useState("");
	const [map, setMap] = React.useState(null);
	const user = useSelector((state) => state.user);
	const { favoriteLenguaje } = useSelector((state) => state.user);
	const [checked, setChecked] = useState(false);

	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: "AIzaSyBclHx78W6z0mPxJG8p-LPFzyfeWihiM20",
	});

	const onLoad = React.useCallback(function callback(map) {
		// This is just an example of getting and using the map instance!!! don't just blindly copy!
		const bounds = new window.google.maps.LatLngBounds(center);
		map.fitBounds(bounds);

		setMap(map);
	}, []);

	const onUnmount = React.useCallback(function callback(map) {
		setMap(null);
	}, []);

	const onMarkerDragEnd = (e) => {
		const lat = e.latLng.lat();
		const lng = e.latLng.lng();
		setMarkerPosition({ lat, lng });

		const geocoder = new window.google.maps.Geocoder();
		geocoder.geocode({ location: { lat, lng } }, (results, status) => {
			if (status === "OK" && results[0]) {
				const addressComponents = results[0].address_components;
				let street = "";
				let suite = "";
				let state = "";
				let zipCode = "";

				addressComponents.forEach((component) => {
					const types = component.types;
					if (types.includes("street_number")) {
						street += component.long_name + " ";
					}
					if (types.includes("route")) {
						street += component.long_name;
					}
					if (types.includes("subpremise")) {
						suite = component.long_name;
					}
					if (types.includes("administrative_area_level_1")) {
						state = component.long_name;
					}
					if (types.includes("postal_code")) {
						zipCode = component.long_name;
					}
				});

				console.log("Street:", street);
				console.log("Suite/Apt:", suite);
				console.log("State:", state);
				console.log("ZipCode:", zipCode);
				setStreet(street);
				setSuite(suite);
				setState(state);
				setZipCode(zipCode);
			} else {
				console.error(
					"Geocode was not successful for the following reason:",
					status
				);
			}
		});
	};

	function dataURLtoBlob(dataURL) {
		const byteString = atob(dataURL.split(",")[1]);
		const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
		const ab = new ArrayBuffer(byteString.length);
		const ia = new Uint8Array(ab);
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		return new Blob([ab], { type: mimeString });
	}

	/*write signature */
	// const handleSaveButtonClick = () => {
	//   if (signature === "") {
	//     console.log("Wrong signature");
	//     return;
	//   }

	//   htmlToImage
	//     .toPng(document.getElementById("signature"))
	//     .then(function (dataUrl) {
	//       const myHeaders = new Headers();
	//       myHeaders.append("AppName", "DbBee");
	//       myHeaders.append(
	//         "Authorization",
	//         "Bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9"
	//       );

	//       const formdata = new FormData();
	//       formdata.append("AccountId", Id);
	//       formdata.append("File", dataURLtoBlob(dataUrl), "[PROXY]");

	//       const requestOptions = {
	//         method: "PATCH",
	//         headers: myHeaders,
	//         body: formdata,
	//         redirect: "follow",
	//       };

	//       fetch(
	//         "https://api.softsmartinc.com/Services/v2/Bee/Accounts/Signature/Save",
	//         requestOptions
	//       )
	//         .then((response) => response.text())
	//         .then((result) => {
	//           console.log(result);
	//           console.log("Signature uploaded successfully");
	//           registerSubmit();
	//         })
	//         .catch((error) => console.error(error));
	//     })
	//     .catch(function (error) {
	//       console.error("Error al guardar la imagen:", error);
	//     });
	// };

	const handleSaveButtonClick = () => {
		if (signature === "") {
			console.log("Wrong signature");
			return;
		}

		htmlToImage
			.toPng(document.getElementById("signature"), {
				backgroundColor: "transparent",
			})
			.then(async function (dataUrl) {
				// // Crear un enlace para descargar la imagen
				// const downloadLink = document.createElement("a");
				// downloadLink.href = dataUrl;
				// downloadLink.download = "signature.png";
				// document.body.appendChild(downloadLink);
				// downloadLink.click();
				// document.body.removeChild(downloadLink);

				const formdata = new FormData();
				formdata.append(
					"AccountId",
					resgisterInfo?.Id
						? resgisterInfo?.Id
						: accountInfo?.AccountInfo.Id
				);
				formdata.append("File", dataURLtoBlob(dataUrl), "[PROXY]");

				try {
					const classRef = new SignatureSave();
					await classRef.List(formdata);
					let result = classRef.ResponseBody;

					dispatch(hideLoading());
					console.log(result);
					console.log("Signature uploaded successfully");
					toast.success("Signature uploaded successfully");
					// navigate("/");
					dispatch(setAcitveNav("home"));
					window.location.href = "/";
				} catch (error) {
					dispatch(hideLoading());
					console.error("Error:", error);
				}

				// const myHeaders = new Headers();
				// myHeaders.append("AppName", "DbBee");
				// myHeaders.append(
				//   "Authorization",
				//   "Bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9"
				// );

				// const requestOptions = {
				//   method: "PATCH",
				//   headers: myHeaders,
				//   body: formdata,
				//   redirect: "follow",
				// };

				// fetch(
				//   "https://api.softsmartinc.com/Services/v2/Bee/Accounts/Signature/Save",
				//   requestOptions
				// )
				//   .then((response) => response.text())
				//   .then((result) => {
				//     dispatch(hideLoading());
				//     console.log(result);
				//     console.log("Signature uploaded successfully");
				//     navigate("/");
				//   })
				//   .catch((error) => {
				//     dispatch(hideLoading());
				//     console.error(error);
				//   });
			})
			.catch(function (error) {
				console.error("Error:", error);
			});
	};
	/*draw signature */

	const handleClear = () => {
		sign.clear();
		setUrl("");
	};
	const handleGenerate = () => {
		const signatureDataURL = sign.getTrimmedCanvas().toDataURL("image/png");

		if (signatureDataURL === wrongSignature) {
			console.log("Wrong Signature");
			return;
		}

		const downloadLink = document.createElement("a");
		downloadLink.href = signatureDataURL;
		downloadLink.download = "signature.png";
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);

		setUrl(signatureDataURL);
		console.log(signatureDataURL);

		// const myHeaders = new Headers();
		// myHeaders.append("AppName", "DbBee");
		// myHeaders.append("Authorization", "Bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9");

		// const formdata = new FormData();
		// formdata.append("AccountId", Id);
		// formdata.append("File", dataURLtoBlob(signatureDataURL), "[PROXY]");

		// const requestOptions = {
		//   method: "PATCH",
		//   headers: myHeaders,
		//   body: formdata,
		//   redirect: "follow"
		// };

		// fetch("https://api.softsmartinc.com/Services/v2/Bee/Accounts/Signature/Save", requestOptions)
		//   .then((response) => response.text())
		//   .then((result) => {
		//     console.log(result);
		//     console.log("Signature uploaded successfully");
		//     registerSubmit();
		//   })
		//   .catch((error) => console.error(error));
	};

	/*Google place autocomplete */
	const handleInputChange = (e) => {
		const value = e.target.value;
		setInputValue(value);

		if (value.trim() === "") {
			setPredictions([]);
			return;
		}

		const autocompleteService =
			new window.google.maps.places.AutocompleteService();
		autocompleteService.getPlacePredictions(
			{ input: value },
			(predictions, status) => {
				if (
					status === window.google.maps.places.PlacesServiceStatus.OK
				) {
					setPredictions(predictions);
				} else {
					setPredictions([]);
				}
			}
		);
	};

	const handlePredictionClick = (prediction) => {
		setInputValue(prediction.description);
		setPredictions([]);

		getAddressDetails(prediction.description);
	};

	const getCurrentLocation = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {
				const geocoder = new window.google.maps.Geocoder();
				const latlng = {
					lat: position.coords.latitude,
					lng: position.coords.longitude,
				};

				geocoder.geocode({ location: latlng }, (results, status) => {
					if (status === window.google.maps.GeocoderStatus.OK) {
						const formattedAddress = results[0].formatted_address;
						const addressComponent = results[0].address_components;
						console.log("=>>>>", results);
						setCurrentLocation(addressComponent);
						setInputValue(formattedAddress);
						getAddressDetails(formattedAddress);
					}
				});
			});
		}
	};

	// const getAddressDetails = (address) => {
	//   const geocoder = new window.google.maps.Geocoder();
	//   geocoder.geocode({ address: address }, (results, status) => {
	//     if (
	//       status === window.google.maps.GeocoderStatus.OK &&
	//       results.length > 0
	//     ) {
	//       const addressComponents = results[0].address_components;
	//       const formattedAddress = results[0].formatted_address;
	//       console.log("Formatted Address:", formattedAddress);

	//       // Obtener otros detalles de dirección
	//       const street = addressComponents.find((component) =>
	//         component.types.includes("route")
	//       )?.long_name;
	//       const city = addressComponents.find((component) =>
	//         component.types.includes("locality")
	//       )?.long_name;
	//       const state = addressComponents.find((component) =>
	//         component.types.includes("administrative_area_level_1")
	//       )?.long_name;
	//       const zipcode = addressComponents.find((component) =>
	//         component.types.includes("postal_code")
	//       )?.long_name;

	//       setEntity((prevState) => ({
	//         ...prevState,
	//         street: street,
	//         city: city,
	//         state2: state,
	//         zipCode: zipcode,
	//       }));

	//       console.log("Street:", street);
	//       console.log("City:", city);
	//       console.log("State:", state);
	//       console.log("Zip Code:", zipcode);
	//     } else {
	//       console.error("Failed to get address details.");
	//     }
	//   });
	// };
	/*----------------------------------- */

	const getAddressDetails = (address) => {
		const geocoder = new window.google.maps.Geocoder();
		geocoder.geocode({ address: address }, (results, status) => {
			if (
				status === window.google.maps.GeocoderStatus.OK &&
				results.length > 0
			) {
				const addressComponents = results[0].address_components;
				const formattedAddress = results[0].formatted_address;
				console.log("Formatted Address:", formattedAddress);

				// Obtener latitud y longitud
				const latitude = results[0].geometry.location.lat();
				const longitude = results[0].geometry.location.lng();

				// Obtener otros detalles de dirección
				const street = addressComponents.find((component) =>
					component.types.includes("route")
				)?.long_name;
				const city = addressComponents.find((component) =>
					component.types.includes("locality")
				)?.long_name;
				const state = addressComponents.find((component) =>
					component.types.includes("administrative_area_level_1")
				)?.long_name;
				const zipcode = addressComponents.find((component) =>
					component.types.includes("postal_code")
				)?.long_name;

				const addressState = addressComponents.find((component) =>
					component.types.includes("country")
				)?.short_name;

				const country = addressComponents.find((component) =>
					component.types.includes("country")
				)?.long_name;

				setCurrentLocation(results[0].address_components);
				console.log("Current location", results);
				setEntity((prevState) => ({
					...prevState,
					street: street,
					city: city,
					state2: state,
					zipCode: zipcode,
					latitude: latitude,
					longitude: longitude,
					country: country,
					addressState: addressState,
				}));

				console.log("Street:", street);
				console.log("City:", city);
				console.log("State:", state);
				console.log("Zip Code:", zipcode);
				console.log("Latitude:", latitude);
				console.log("Longitude:", longitude);
				console.log("Country", country);
				console.log("addressState", addressState);
			} else {
				console.error("Failed to get address details.");
			}
		});
	};

	// const handleRadioChange = (e) => {
	//   const { name, value } = e.target;
	//   setTypeSelectedId(parseInt(e.target.value));
	//   setEntity({ ...entity, [name]: parseInt(value) });
	// };

	// const getStateList = async () => {
	//   try {
	//     const classRef = new GetStatesList();
	//     await classRef.List();
	//     let result = classRef.ResponseBody;

	//     if (result.StatesList) {
	//       setStateList(result.StatesList);
	//       console.log("=>>", result.StatesList);
	//     }
	//   } catch (error) {
	//     console.log("Error", error);
	//   }
	// };

	// const registerSubmit = () => {
	//   let data = JSON.stringify({
	//     MembersId: parseInt(Id),
	//     AddressLocation: entity.country ? entity.country : "",
	//     AddressStreet: entity.street ? entity.street : "",
	//     AddressAppOrSuite: null,
	//     AddressCity: entity.city ? entity.city : "",
	//     AddressState: entity.addressState ? entity.addressState : "",
	//     AddressZipCode: entity.zipCode ? entity.zipCode : "",
	//     AddressLongitude: entity.longitude ? entity.longitude : "",
	//     AddressLatitude: entity.latitude ? entity.latitude : "",
	//     EntityTypesId: entity.type ? entity.type : "",
	//     StatesId: entity.state ? entity.state : "",
	//     Name: entity.nameEntry ? entity.nameEntry : "",
	//     Description: "test",
	//   });

	//   let config = {
	//     method: "post",
	//     maxBodyLength: Infinity,
	//     url: "https://api.softsmartinc.com/Services/v2/Bee/Accounts/Register",
	//     headers: {
	//       AppName: "DbBee",
	//       "Content-Type": "application/json",
	//       Authorization: "Bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9",
	//     },
	//     data: data,
	//   };

	//   axios
	//     .request(config)
	//     .then((response) => JSON.stringify(response))
	//     .then((result) => {
	//       setRegisterInfo(result.AccountInfo);
	//       toast.success("Usuario desbloqueado correctamente");
	//       console.log("Usuario desbloqueado correctamente");
	//       console.log("PDF", result.AccountInfo);
	//     })
	//     .catch((error) => {
	//       console.log(error);
	//     });
	// };

	const registerSubmit = async () => {
		dispatch(showLoading());
		try {
			const classRef = new Register();
			await classRef.List({
				MembersId: parseInt(Id),
				AddressLocation: entity.country ? entity.country : "",
				AddressStreet: inputValue ? inputValue : "",
				AddressAppOrSuite: entity.suite ? entity.suite : "",
				AddressCity: entity.city ? entity.city : "",
				AddressState: entity.addressState ? entity.addressState : "",
				AddressZipCode: entity.zipCode ? entity.zipCode : "",
				AddressLongitude: entity.longitude ? entity.longitude : "",
				AddressLatitude: entity.latitude ? entity.latitude : "",
				EntityTypesId: entity.type ? entity.type : "",
				StatesId: entity.state ? entity.state : "",
				Name: entryName ? entryName : "",
				Description: "test",
			});
			let result = classRef.ResponseBody;
			dispatch(hideLoading());
			if (result.AccountInfo) {
				setRegisterInfo(result.AccountInfo);
				toast.success(dataInfo[favoriteLenguaje][5]?.successStepper);
				console.log("Usuario desbloqueado correctamente");
				console.log("PDF", result.AccountInfo);
				setTimeout(() => {
					setActiveStep((activeStep) => activeStep + 1);
				}, 1000);
			} else {
				toast.warning(result.message);
			}
		} catch (error) {
			dispatch(hideLoading());
			toast.error(error.message);
			console.error("Error", error);
		}

		// const myHeaders = new Headers();
		// myHeaders.append("AppName", "DbBee");
		// myHeaders.append("Content-Type", "application/json");
		// myHeaders.append(
		//   "Authorization",
		//   "Bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9"
		// );

		// const raw = JSON.stringify({
		//   MembersId: parseInt(Id),
		//   AddressLocation: entity.country ? entity.country : "",
		//   AddressStreet: inputValue ? inputValue : "",
		//   AddressAppOrSuite: null,
		//   AddressCity: entity.city ? entity.city : "",
		//   AddressState: entity.addressState ? entity.addressState : "",
		//   AddressZipCode: entity.zipCode ? entity.zipCode : "",
		//   AddressLongitude: entity.longitude ? entity.longitude : "",
		//   AddressLatitude: entity.latitude ? entity.latitude : "",
		//   EntityTypesId: entity.type ? entity.type : "",
		//   StatesId: entity.state ? entity.state : "",
		//   Name: entity.nameEntry ? entity.nameEntry : "",
		//   Description: "test",
		// });

		// const requestOptions = {
		//   method: "POST",
		//   headers: myHeaders,
		//   body: raw,
		//   redirect: "follow",
		// };

		// fetch(
		//   "https://api.softsmartinc.com/Services/v2/Bee/Accounts/Register",
		//   requestOptions
		// )
		//   .then((response) => response.json())
		//   .then((result) => {
		//     dispatch(hideLoading());
		//     setRegisterInfo(result.AccountInfo);
		//     toast.success("Usuario desbloqueado correctamente");
		//     console.log("Usuario desbloqueado correctamente");
		//     console.log("PDF", result.AccountInfo);
		//     setTimeout(() => {
		//       setActiveStep((activeStep) => activeStep + 1);
		//     }, 1000);
		//   })
		//   .catch((error) => {
		//     dispatch(hideLoading());
		//     console.error(error);
		//   });
	};

	const registerEntity = () => {
		dispatch(showLoading());
		if (showSingnature) {
			trim();
		} else {
			handleSaveButtonClick();
		}
	};

	/*-----------------------------------------------------*/
	const sigPad = useRef();

	const clear = () => {
		sigPad.current.clear();
	};

	const trim = async () => {
		const img = sigPad.current.getTrimmedCanvas().toDataURL("image/png");
		const formdata = new FormData();
		formdata.append(
			"AccountId",
			resgisterInfo?.Id ? resgisterInfo?.Id : accountInfo?.AccountInfo.Id
		);
		formdata.append("File", dataURLtoBlob(img), "[PROXY]");

		try {
			const classRef = new SignatureSave();
			await classRef.List(formdata);
			let result = classRef.ResponseBody;

			dispatch(hideLoading());
			console.log(result);
			console.log("Signature uploaded successfully");
			toast.success("Signature uploaded successfully");
			// navigate("/");
			dispatch(setAcitveNav("home"));
			window.location.href = "/";
		} catch (error) {
			dispatch(hideLoading());
			console.log("Error img", dataURLtoBlob(img));
			console.error("Error", error);
		}

		// const downloadLink = document.createElement("a");
		// // downloadLink.href = img;
		// // downloadLink.download = "signature.png";
		// // document.body.appendChild(downloadLink);
		// // downloadLink.click();
		// // document.body.removeChild(downloadLink);
		// // console.log(img);

		// const myHeaders = new Headers();
		// myHeaders.append("AppName", "DbBee");
		// myHeaders.append(
		//   "Authorization",
		//   "Bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9"
		// );

		// const requestOptions = {
		//   method: "PATCH",
		//   headers: myHeaders,
		//   body: formdata,
		//   redirect: "follow",
		// };

		// fetch(
		//   "https://api.softsmartinc.com/Services/v2/Bee/Accounts/Signature/Save",
		//   requestOptions
		// )
		//   .then((response) => response.text())
		//   .then((result) => {
		//     dispatch(hideLoading());
		//     console.log(result);
		//     console.log("Signature uploaded successfully");
		//     navigate("/");
		//   })
		//   .catch((error) => {
		//     dispatch(hideLoading());
		//     console.error(error);
		//   });
	};

	const getAccountInfo = async () => {
		dispatch(showLoading());
		try {
			const classRef = new GetByMember();
			await classRef.List({ MembersId: parseInt(Id) });
			let result = classRef.ResponseBody;
			dispatch(hideLoading());
			setAccountInfo(result);
			console.log("AddressInfo", result.AddressInfo);
			console.log("EntityTypeInfo", result.EntityTypeInfo);
			console.log(
				"FilesId_Signature",
				result.AccountInfo.FilesId_Signature
			);
			if (
				result.AddressInfo &&
				result.EntityTypeInfo &&
				result.AccountInfo.FilesId_Signature === 0
			) {
				setActiveStep(3);
			}
		} catch (error) {
			console.log("Error", error);
		}
	};

	// const getAccountType = async () => {
	//   const classRef = new GetAccountType();
	//   await classRef.List();
	//   let result = classRef.ResponseBody;

	//   console.log("Result", result);
	//   if (result.EntityTypesList) {
	//     setEntityTypesList(result.EntityTypesList);
	//     setTypeSelectedId(result.EntityTypesList[0].CodeId);
	//     setEntity({
	//       ...entity,
	//       type: parseInt(result.EntityTypesList[0].CodeId),
	//     });
	//   }
	// };

	const handleCheckboxChange = (event) => {
		setChecked(event.target.checked);
	};

	useEffect(() => {
		// getAccountType();
		// getStateList();
		getAccountInfo();
	}, []);

	return (
		<div className="stepper-princial-container">
			{/* <div className="stepper-header">
        <div className="circle-container">
          <div
            className="circle-stepper"
            style={{
              backgroundColor: `${
                activeStep === 1 ? "#0572D2" : activeStep > 1 ? "green" : "gray"
              }`,
            }}
          >
            {activeStep > 1 ? <FaCheck color="white" /> : "1"}
          </div>
          <div>{dataInfo[favoriteLenguaje][3]?.address}</div>
        </div>
        <div className="stepper-line"></div>
        <div className="circle-container">
          <div
            className="circle-stepper"
            style={{
              backgroundColor: `${
                activeStep === 2 ? "#0572D2" : activeStep > 2 ? "green" : "gray"
              }`,
            }}
          >
            {activeStep > 2 ? <FaCheck color="white" /> : "2"}
          </div>
          <div>{dataInfo[favoriteLenguaje][3]?.review}</div>
        </div>
        <div className="stepper-line"></div>
        <div className="circle-container">
          <div
            className="circle-stepper"
            style={{
              backgroundColor: `${
                activeStep === 3 ? "#0572D2" : activeStep > 3 ? "green" : "gray"
              }`,
            }}
          >
            {activeStep > 3 ? <FaCheck color="white" /> : "3"}
          </div>
          <div>{dataInfo[favoriteLenguaje][3]?.sign}</div>
        </div>
      </div> */}

			{/* {activeStep === 1 ? (
        <div className="step-card-container">
          <div className="step-card-title">Create your Entity!</div>
          <div className="step-card-line"></div>
          <div
            className="mb-4 w-100"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label
              htmlFor="name-entry"
              className="form-label label-title"
              style={{ lineHeight: "20px" }}
            >
              Name of Entry <br />
              <span className="input-subtitle">
                Enter the official name of your entity:
              </span>
            </label>
            <input
              type="text"
              className="form-control"
              style={{ padding: "10px", height: "52px" }}
              id="name-entry"
              placeholder="Name of entry"
              autoComplete="off"
              value={entity.nameEntry ? entity.nameEntry : ""}
              onChange={(e) => {
                setEntity({ ...entity, nameEntry: e.target.value });
              }}
            />
          </div>
          <div className="entity-name-container">
            <label
              htmlFor="name-entry"
              className="form-label label-title"
              style={{ lineHeight: "20px" }}
            >
              Entity Name
              <br />
              <span className="input-subtitle">
                Enter the official name of your entity:
              </span>
            </label>
          </div>
          <div className="step-card-radio-buttons">
            <div className="step-card-radio">
              <div className="step-card-radio1">
                {entityTypesList && entityTypesList.length > 0
                  ? entityTypesList.map((list, index) =>
                      index < 3 ? (
                        <div className={`form-check`} key={list.CodeId}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="type"
                            id={`type${list.CodeId}`}
                            value={list.CodeId}
                            checked={typeSelectedId === list.CodeId}
                            onChange={handleRadioChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`type${index}`}
                          >
                            {" "}
                            {list.Description}
                          </label>
                        </div>
                      ) : null
                    )
                  : null}
              </div>
              <div className="step-card-radio2">
                {entityTypesList && entityTypesList.length > 0
                  ? entityTypesList.map((list, index) =>
                      index >= 3 ? (
                        <div className={`form-check`} key={list.Id}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="type"
                            id={`type${list.Id}`}
                            value={list.Id}
                            checked={typeSelectedId === list.Id}
                            onChange={handleRadioChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`type${index}`}
                          >
                            {" "}
                            {list.Description}
                          </label>
                        </div>
                      ) : null
                    )
                  : null}
              </div>
            </div>
          </div>
          <div
            className="mb-4 w-100 mt-4"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label
              htmlFor="name-entry"
              className="form-label label-title"
              style={{ lineHeight: "20px" }}
            >
              State of Incorporation
              <br />
              <span className="input-subtitle">
                Select the state where your entity will be registered from the
                dropdown menu below:
              </span>
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              style={{ padding: "10px", height: "52px" }}
              onChange={(e) => {
                setEntity({ ...entity, ["state"]: parseInt(e.target.value) });
              }}
            >
              <option value="">State of Incorporation</option>
              {stateList && stateList.length > 0
                ? stateList.map((state, index) => (
                    <option key={index} value={state.CodeId}>
                      {state.Description}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <div
            className="step-card-line"
            style={{ backgroundColor: "#0572D2", width: "89px", height: "1px" }}
          ></div>
        </div>
      ) : null} */}
			{activeStep === 1 ? (
				<>
					<div className="step-card-title mt-5">
						{" "}
						{dataInfo[favoriteLenguaje][3]?.createTitle}
					</div>
					<div className="step-card-line"></div>
					<div className="google-autocomplete">
						{!mapView && (
							<div className="search2">
								<div
									className="mb-2"
									style={{
										display: "flex",
										flexDirection: "column",
									}}
								>
									<label
										htmlFor="location"
										className="form-label label-title"
									>
										{
											dataInfo[favoriteLenguaje][3]
												?.entityName
										}
									</label>
									<input
										type="text"
										placeholder={
											dataInfo[favoriteLenguaje][3]
												?.entityName2
										}
										className="form-control"
										value={entryName}
										onChange={(e) => {
											setEntryName(e.target.value);
										}}
									/>
								</div>
								<div
									className="mb-4"
									style={{
										display: "flex",
										flexDirection: "column",
									}}
								>
									<label
										htmlFor="location"
										className="form-label label-title"
									>
										{
											dataInfo[favoriteLenguaje][3]
												?.yourAddress
										}
									</label>
									<input
										id="location"
										type="text"
										placeholder={
											dataInfo[favoriteLenguaje][3]
												?.yourAddress
										}
										className="form-control"
										value={inputValue}
										onChange={handleInputChange}
									/>
									<button onClick={() => {}}>
										<img
											src={mapI}
											alt="logo-map"
											onClick={() => {
												if (mapView) {
													setMapView(false);
												} else {
													setMapView(true);
													setInputValue("");
													setPredictions([]);
												}
											}}
										/>
									</button>
									{/* <button onClick={getCurrentLocation}>
                  <img src={map} alt="logo-map" />
                </button> */}
									<div className="predictions-place">
										{predictions.map(
											(prediction, index) => (
												<div
													key={prediction.place_id}
													onClick={() =>
														handlePredictionClick(
															prediction
														)
													}
													className="prediction"
													style={{
														borderBottom:
															predictions.length -
																1 ===
															index
																? "1px solid #dee2e6"
																: "none",
													}}
												>
													{prediction.description}
												</div>
											)
										)}
									</div>
								</div>
							</div>
						)}
						{isLoaded && mapView ? (
							<GoogleMap
								mapContainerStyle={containerStyle}
								center={center}
								zoom={8}
								onLoad={onLoad}
								onUnmount={onUnmount}
								options={{
									streetViewControl: false,
									mapTypeControl: false,
								}}
							>
								<Marker
									position={markerPosition}
									draggable={true}
									onDragEnd={onMarkerDragEnd}
								/>
							</GoogleMap>
						) : (
							<></>
						)}
					</div>

					{inputValue ? (
						<>
							<div style={{ width: "90%" }}>
								<div
									className="mb-4"
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
									}}
								>
									<label
										htmlFor="location"
										className="form-label label-title"
									>
										{dataInfo[favoriteLenguaje][3]?.suite}
									</label>
									<input
										type="text"
										placeholder={
											dataInfo[favoriteLenguaje][3]?.suite
										}
										className="form-control form-input-adjust"
										defaultValue={
											entity.suite ? entity.suite : ""
										}
									/>
								</div>
							</div>
							<div
								style={{
									width: "90%",
									display: "flex",
									justifyContent: "space-between",
								}}
							>
								<div
									className="mb-4"
									style={{
										display: "flex",
										flexDirection: "column",
										width: "48%",
									}}
								>
									<label
										htmlFor="state"
										className="form-label label-title"
									>
										{dataInfo[favoriteLenguaje][3]?.state}
									</label>
									<input
										id="state"
										type="text"
										placeholder={
											dataInfo[favoriteLenguaje][3]?.state
										}
										className="form-control form-input-adjust"
										defaultValue={
											entity.state2 ? entity.state2 : ""
										}
									/>
								</div>

								<div
									className="mb-4"
									style={{
										display: "flex",
										flexDirection: "column",
										width: "48%",
									}}
								>
									<label
										htmlFor="zipcode"
										className="form-label label-title"
									>
										{dataInfo[favoriteLenguaje][3]?.zipCode}
									</label>
									<input
										id="zipcode"
										type="text"
										placeholder={
											dataInfo[favoriteLenguaje][3]
												?.zipCode
										}
										className="form-control form-input-adjust"
										defaultValue={
											entity.zipCode ? entity.zipCode : ""
										}
									/>
								</div>
							</div>
							<div
								style={{
									width: "90%",
									display: "flex",
									justifyContent: "space-between",
								}}
							>
								<div className="form-check">
									<input
										className="form-check-input"
										type="checkbox"
										value=""
										id="defaultCheck1"
										checked={checked}
										onChange={handleCheckboxChange}
									/>
									<label
										className="form-check-label"
										htmlFor="defaultCheck1"
									>
										{dataInfo[favoriteLenguaje][3]?.accept1}{" "}
										<a
											href="/nda-terms"
											target="_blank"
											rel="noreferrer"
										>
											{
												dataInfo[favoriteLenguaje][3]
													?.accept2
											}
										</a>
									</label>
								</div>
							</div>
						</>
					) : (
						<div
							style={{ height: `${mapView ? "0px" : "300px"}` }}
						></div>
					)}
					<div
						className="step-card-line"
						style={{
							backgroundColor: "#0572D2",
							width: "89px",
							height: "1px",
						}}
					></div>
				</>
			) : null}
			{/* {activeStep === 2 ? (
        <>
          <div className="step-card-container">
            <div className="step-card-title mb-0">{dataInfo[favoriteLenguaje][3]?.previewNda}</div>
            <div className="step-card-line mb-2 mt-2"></div>
            <div className="nda-preview">
              <div className="iframe iframe-document-stepper">
                <iframe
                  src={iframeSrc}
                  style={{ width: "100%", height: "100vh", border: "none" }}
                  title="NDA Terms"
                ></iframe>
              </div>
            </div>
            <div
              className="step-card-line mb-1"
              style={{
                backgroundColor: "#0572D2",
                width: "89px",
                height: "1px",
              }}
            ></div>
          </div>
        </>
      ) : null} */}
			{activeStep >= 2 ? (
				<>
					<div className="step-card-container">
						<div className="step-card-title mb-0">
							{dataInfo[favoriteLenguaje][3]?.signature}
						</div>
						<div className="step-card-line mb-2 mt-2"></div>
						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
								gap: "70px",
								marginTop: "20px",
							}}
						>
							<span
								onClick={() => {
									setShowSignature(true);
									setSignature("");
								}}
								style={{
									color: `${
										showSingnature
											? "#383E45"
											: "rgba(56, 62, 69, 0.50)"
									}`,
									borderBottom: `${
										showSingnature
											? "1px solid green"
											: "1px solid transparent"
									}`,
									paddingBottom: "4px",
									cursor: "pointer",
								}}
							>
								{dataInfo[favoriteLenguaje][3]?.drawSignature}
							</span>
							<span
								onClick={() => {
									setShowSignature(false);
									setSignature("");
								}}
								style={{
									color: `${
										!showSingnature
											? "#383E45"
											: "rgba(56, 62, 69, 0.50)"
									}`,
									borderBottom: `${
										!showSingnature
											? "1px solid green"
											: "1px solid transparent"
									}`,
									paddingBottom: "4px",
									cursor: "pointer",
								}}
							>
								{dataInfo[favoriteLenguaje][3]?.typeSignature}
							</span>
						</div>
						{showSingnature ? (
							<>
								<div className="type-signature-title ">
									{
										dataInfo[favoriteLenguaje][3]
											?.signatureMessage
									}
								</div>
								<div className="type-signature-container2">
									{/* <SignatureCanvas
                    canvasProps={{
                      width: 550,
                      height: 200,
                      className: "sigCanvas",
                    }}
                    ref={(data) => setSign(data)}
                  /> */}
									<div
										className="container"
										style={{ backgroundColor: "white" }}
									>
										<div className="sigContainer">
											<SignaturePad
												canvasProps={{
													className: "sigPad",
												}}
												ref={sigPad}
											/>
										</div>
										{/* <div>
              <button className={styles.buttons} onClick={clear}>
                Clear
              </button>
              <button className={styles.buttons} onClick={trim}>
                Trim
              </button>
            </div> */}
									</div>
									<button
										className="btn btn-outline-primary btn-sm btn-clear mt-2"
										style={{ fontFamily: "Inter" }}
										onClick={clear}
									>
										{
											dataInfo[favoriteLenguaje][3]
												?.clearButton
										}
									</button>
								</div>
							</>
						) : (
							<div style={{ width: "100%" }} className="mt-2">
								<div
									className="mb-4 w-100"
									style={{
										display: "flex",
										flexDirection: "column",
									}}
								>
									<label
										htmlFor="typeSignature"
										className="form-label label-title"
									>
										{
											dataInfo[favoriteLenguaje][3]
												?.enterSignature
										}
									</label>
									<input
										type="text"
										className="form-control"
										style={{
											padding: "10px",
											height: "52px",
										}}
										id="typeSignature"
										placeholder={
											dataInfo[favoriteLenguaje][3]
												?.enterSignature
										}
										autoComplete="off"
										value={signature ? signature : ""}
										onChange={(e) => {
											setSignature(e.target.value);
										}}
									/>
								</div>
								<div className="border-write-signature">
									<div
										className="draw-signature-container"
										id="signature"
										style={{
											backgroundColor: "transparent",
											border: "none",
										}}
									>
										{signature}
									</div>
								</div>
							</div>
						)}

						<div
							className="step-card-line mb-1"
							style={{
								backgroundColor: "#0572D2",
								width: "89px",
								height: "1px",
							}}
						></div>
					</div>
				</>
			) : null}
			{!mapView && (
				<div className="step-button-container">
					<button
						type="button"
						className="btn btn-outline-primary button-steper"
						onClick={() => {
							dispatch(setAcitveNav("home"));
							navigate("/");
						}}
					>
						{dataInfo[favoriteLenguaje][3]?.cancelButton}
					</button>
					<button
						className="btn btn-primary button-steper"
						type="button"
						disabled={inputValue && entryName && checked ? false : true}
						onClick={() => {
							if (activeStep === 1 && inputValue && entryName && checked) {
								registerSubmit();
							} else if (activeStep === 2) {
								registerEntity();
							} else {
								console.log("Wrong!!!");
							}
							// if (activeStep === 1 && inputValue && entryName) {
							//   registerSubmit();
							// } else if (activeStep === 2) {
							//   setActiveStep((activeStep) => activeStep + 1);
							// } else if (activeStep === 3) {
							//   setActiveStep((activeStep) => activeStep + 1);
							// } else if (activeStep === 4) {
							//   registerEntity();
							// } else {
							//   console.log("Wrong!!!");
							// }
							// if (activeStep <= 4) {
							//   setActiveStep((activeStep) => activeStep + 1);
							//   console.log("=>>", activeStep);
							// }
						}}
					>
						{activeStep === 2
							? dataInfo[favoriteLenguaje][3]?.sign
							: activeStep === 3
							? dataInfo[favoriteLenguaje][2]?.submit
							: dataInfo[favoriteLenguaje][2]?.continue}
					</button>
				</div>
			)}
			{mapView && (
				<div className="step-button-container">
					<button
						type="button"
						className="btn btn-outline-primary button-steper"
						onClick={() => {
							setMapView(false);
						}}
					>
						{dataInfo[favoriteLenguaje][3]?.cancelButton}
					</button>
					<button
						className="btn btn-primary button-steper"
						type="button"
						disabled={street ? false : true}
						onClick={() => {
							setInputValue(street);
							setEntity({
								type: "",
								state: "",
								city: "",
								state2: state,
								zipCode: zipCode,
								street: street,
								latitude: "",
								longitude: "",
								country: "",
								addressState: "",
								suite: suite,
							});
							setMapView(false);
						}}
					>
						{dataInfo[favoriteLenguaje][2]?.continue}
					</button>
				</div>
			)}
			<ToastContainer
				theme="colored"
				position="top-right"
				autoClose={2000}
			/>
			{/* <button onClick={()=>{
        console.log("Objeto", entity)
        console.log("Street, zipcode, suite,state", street, zipCode, suite, state)
        }}>prueba</button> */}
		</div>
	);
};

export default Stepper;
