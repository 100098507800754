import React from "react";
import "./ReceiveNDA.css";
import logo from "../../images/logo2.png";
import received from "../../images/received2.png"
import { useNavigate } from 'react-router-dom';
export const ReceiveNDA = () => {
    const navigate = useNavigate();
    const year = new Date().getFullYear();
  return (
    <div className="receive-principal-container">
      <div className="row d-flex justify-content-center align-items-center mt-5">
        <img src={logo} alt="" />
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 receive-nda-container">
        <div className="receive-nda-title">
          Send NDYou received a <br /> NDA document from
        </div>
        <div className="receive-greenline mt-3"></div>
        <div className="receive-nda-name mt-4">John Smith</div>
        <div>
            <img src={received} alt="received-img" className="mt-4" />
        </div>
        <div className="receive-buttom-container">
            <div className="receive-text-container mt-1">Click to review the Document</div>
            <div className="receive-button">
            <button type="button" className="btn btn-light w-100"
            onClick={()=>{
                navigate("/preview-nda")
            }}
            >Review the Document</button>
            </div>
        </div>
      </div>
      <div className="receive-nda-footer">© Copyright {year} NDABee.</div>
    </div>
  );
};
