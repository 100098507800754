import React from "react";
import logo from "../../images/logo2.png";
import deleteData from "../../images/deleteAccount.png";
import elipse from "../../images/elipse.png";
import "./RemoveAccount.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAcitveNav } from "../../redux/features/userSlice";
import { dataInfo } from "../../pruebasJson/data";

const RemoveAccount = () => {
  const navigate = useNavigate();
  const year = new Date().getFullYear();
  const dispatch = useDispatch();
  const { favoriteLenguaje } = useSelector((state) => state.user);
  return (
    <div className="delete-principal-container">
      <div className="delete-logo">
        <img src={logo} alt="logo" onClick={()=>{
           dispatch(setAcitveNav("home"))
          navigate("/")}
          } style={{cursor:"pointer"}}/>
      </div>
      <div className="delete-main-container mb-2">
        {/* <div className="delete-img-container">
          <img
            src={deleteData}
            alt="delete-data"
            width="250px"
            height="250px"
          />
          <img
            src={elipse}
            alt="delete-data"
            width="360px"
            style={{ marginTop: "-30px" }}
          />
        </div> */}
        <div className="delete-title" style={{marginTop:"0px"}}>{dataInfo[favoriteLenguaje][3]?.removeTitle}</div>
        <div className="white-line-container mt-3">
          <div className="white-line"></div>
        </div>

        <div className="delete-text">
          {dataInfo[favoriteLenguaje][3]?.removeText1} <br /> {dataInfo[favoriteLenguaje][3]?.removeText2}
        </div>

        <div className="white-line-container mb-1">
          <div className="white-line"></div>
        </div>

        <div className="buttons-delete-cont">
          <div className="card-remove-container mt-1">
          <div className="remove-text">
            <span className="remove-title">{dataInfo[favoriteLenguaje][3]?.logIn}</span> {dataInfo[favoriteLenguaje][3]?.logInText}
          </div>
          <div className="remove-text">
            <span className="remove-title">{dataInfo[favoriteLenguaje][3]?.accessAccountSettings}</span> {dataInfo[favoriteLenguaje][3]?.accessAccountSettingsText}
          </div>
          <div className="remove-text">
            <span className="remove-title">{dataInfo[favoriteLenguaje][3]?.findAccountRemovalOption}</span> {dataInfo[favoriteLenguaje][3]?.findAccountRemovalOptionText}
          </div>
          <div className="remove-text">
            <span className="remove-title">{dataInfo[favoriteLenguaje][3]?.followPrompts}</span> {dataInfo[favoriteLenguaje][3]?.followPromptsText}
          </div>
          <div className="remove-text">
            <span className="remove-title">{dataInfo[favoriteLenguaje][3]?.confirmation}</span> {dataInfo[favoriteLenguaje][3]?.confirmationText}
          </div>
          <div className="remove-text">
          {dataInfo[favoriteLenguaje][3]?.pleaseNote}
          </div>
          <div className="remove-text">
          {dataInfo[favoriteLenguaje][3]?.ifYouHaveQuestions}
          <a href="mailto:info@softsmart.com" style={{color:"#0445a5"}}> {dataInfo[favoriteLenguaje][3]?.ifYouHaveQuestionsText} </a>
           {dataInfo[favoriteLenguaje][3]?.forAssistance}
          </div>
          <div className="remove-text d-flex justify-content-center mt-1">
          {dataInfo[favoriteLenguaje][3]?.thankYou}
          </div>

          </div>
        </div>
      </div>
      <div className="delete-footer">© Copyright {year} NDABee.</div>
    </div>
  );
};

export default RemoveAccount;
