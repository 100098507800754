import { Base } from '../Base';

export class Notifications extends Base {
    Items = [];

    constructor() {
        super();
        this.Model = 'Members/Notifications';
    }

    async List(body) {
        this.Params = body
        await super.load();
        this.Items = this.ResponseBody;
    }
}
