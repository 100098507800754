import { Base4 } from '../Base4';

export class SaveAvatar extends Base4 {
    Items = [];

    constructor() {
        super();
        this.Controller = 'Members/Avatar/Save';
    }

    async List(body) {
        this.Params = body
        await super.load();
        this.Items = this.ResponseBody;
    }
}
