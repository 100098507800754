import React, { useEffect, useRef, useState } from 'react'
import "./ConfirmEmail.css"
import forgotLogo from "../../images/forgot-logo.png";
import verificationEmail from "../../images/emailValidation.png";
import confirmEmail from "../../images/emailVerify2.png";
import wrongEmail from "../../images/wrongEmail.png";
import padlock from "../../images/padlock.png";
import { useParams,useNavigate } from "react-router-dom";
import InfoModal from "../../Components/InfoModal/InfoModal";
import notification from "../../images/notification-logo.png";
import { HEADERS } from "../../Constants/Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SendEmail } from './../../Apis/Members/Models/SendEmail';
import { ValidateSms } from '../../Apis/Members/Models/ValidateSms';
import { useDispatch, useSelector } from 'react-redux';
import { setAcitveNav } from '../../redux/features/userSlice';
import { dataInfo } from "../../pruebasJson/data";

const ConfirmEmail = () => {
  const navigate = useNavigate();
  const { Id } = useParams();
  const [step, setStep] = useState(1);
  const [valid, setValid] = useState(true);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpVal, setOtpVal] = useState([]);
  const verification = ["1", "2", "3", "4", "5", "6"];
  const [userOtpInfo, setUserOtpInfo] = useState();
  const year = new Date().getFullYear();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { favoriteLenguaje } = useSelector((state) => state.user);

  const textBase = useRef(null);


  const focusNext = (e) => {
    if (textBase && textBase.current && e.target.value) {
      const childCount = textBase.current.childElementCount;
      const currentIndex = [...e.target.parentNode.children].indexOf(e.target);
      if (currentIndex !== childCount - 1) {
        e.target.nextSibling.focus();
      }
      const values = [];
      textBase.current.childNodes.forEach((child) => {
        values.push(child.value);
      });
      if (values.length === 6) {
        setOtpVal(values);
      }
    }
  };

  const handleKeyUp = (e) => {
    if (e && e.key === "Backspace" && e.target && e.target.previousSibling) {
      e.target.previousSibling.focus();
    }
  };

  const handlePaste = (e) => {
    if (e.clipboardData.getData("text")) {
      const pasteVal = e.clipboardData.getData("text");
      if (pasteVal.length === 6) {
        if (textBase && textBase.current) {
          const vals = pasteVal.split("");
          setOtpVal(vals);
          textBase.current.childNodes.forEach((child, k) => {
            child.value = vals[k];
          });
        }
      }
    }
  };

  const handleClose = () => setShow(false);


  const sendEmail = async () => {
    try {
      const classRef = new SendEmail();
      await classRef.List({
        "MembersId": parseInt(Id)
      })
      let data = classRef.ResponseBody;
      if (data.Status === "Successful!") {
        setShow(true);
      } else {
        toast.warning(dataInfo[favoriteLenguaje][5]?.warningUser);
      }
    } catch (error) {
      console.log("error", error);
    }
    // try {
    //   const myHeaders = new Headers();
    //   myHeaders.append("AppName", "DbBee");
    //   myHeaders.append("Content-Type", "application/json");
    //   myHeaders.append("Authorization", "Bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9");
      
    //   const raw = JSON.stringify({
    //     "MembersId": parseInt(Id)
    //   });
      
    //   const requestOptions = {
    //     method: "POST",
    //     headers: myHeaders,
    //     body: raw,
    //     redirect: "follow"
    //   };

    //   fetch("https://api.softsmartinc.com/Services/v2/Bee/Members/Verify/SendEmail", requestOptions)
    //     .then((response) => response.json())
    //     .then((data) => {
    //       if (data.Status === "Successful!") {
    //         setShow(true);
    //       } else {
    //         toast.warning("User not found");
    //       }
    //     });
    // } catch (error) {
    //   console.log("error", error);
    // }
  };

  const verifyOtp = async() => {
    const otpString = otpVal.join("");

    try {
      const classRef = new ValidateSms();
      await classRef.List({
        "MembersId": parseInt(Id),
        "Otp": otpString,
        "IsEmail": true
      })
      let data = classRef.ResponseBody;
      if (data.OtpValid === "VALID") {
        setStep(2);
        setUserOtpInfo(data);
        toast.success(dataInfo[favoriteLenguaje][5]?.successVerify)
        setTimeout(()=>{
          dispatch(setAcitveNav("home"))
          navigate("/")
        },1500)
      } else {
        setStep(3);
      }
    } catch (error) {
      console.log("Error", error)
    }
  };


  useEffect(()=>{
    sendEmail();
    console.log("id",Id)
  },[])
  return (
    <div className="verifyEmail-principal-container">
    <div className="verify-main w-100">
      <div className="row verify-main-container">
        <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-6 col-sm-12 col-xs-12">
          <div className="verify-img-container">
            <img
              src={forgotLogo}
              alt="confidential-bee"
              style={{ cursor: "pointer" }}
              onClick={()=>{
                dispatch(setAcitveNav("home"))
                navigate("/")
              }}
            />
          </div>
          {step === 1 ? (
            <div className="verify-container">
              <div className="email-device">
                <img src={verificationEmail} alt="device-wait" />
              </div>
              <div className="verify-container-title">{dataInfo[favoriteLenguaje][3]?.emailValidationTitle}</div>
              <div className="verify-container-content">
              {dataInfo[favoriteLenguaje][3]?.emailValidationContent}
              </div>
              <div className="forgot-green-line"></div>
              <div
                className="mb-3 mt-3"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "80%",
                }}
              >
                <div className="h-screen flex justify-center items-center space-x-2">
                  <div className="d-flex opt-text-cn align-items-center justify-content-center mt-4">
                    <div className="otp-base d-flex" ref={textBase}>
                      {new Array(6).fill(null).map((input, k) => {
                        return (
                          <input
                            key={k}
                            onPaste={handlePaste}
                            onKeyUp={handleKeyUp}
                            type="text"
                            pattern="\d*"
                            className="form-control otp-txt"
                            maxLength={1}
                            onChange={(e) => focusNext(e)}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="forgot-green-line2"></div>
              <div
                className="mt-4"
                style={{
                  width: "80%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <button
                  className="btn btn-primary  mb-3"
                  style={{ height: "50px", width: "120px" }}
                  onClick={() => {
                    if (otpVal) {
                      verifyOtp();
                    } else {
                      console.log("fallo");
                      console.log("otpVal", otpVal);
                      console.log("verification", verification);
                    }
                  }}
                >
                  {dataInfo[favoriteLenguaje][3]?.validationButton}
                </button>

                <div
                  className="mb-3 re-send-button"
                  style={{ height: "50px", width: "120px" }}
                  onClick={() => {
                    console.log("id",Id)
                  }}
                >
                  {dataInfo[favoriteLenguaje][3]?.validationButton2}
                </div>
              </div>
            </div>
          ) : null}

          {step === 2 ? (
            <div className="verify-container">
              <div className="email-device">
                <img src={confirmEmail} alt="device-right" />
              </div>
              <div className="verify-container-title">
              {dataInfo[favoriteLenguaje][3]?.validationTitle1}
              <br />
              {dataInfo[favoriteLenguaje][3]?.validationTitle2}
              </div>
              <div className="verify-container-content"></div>
              <div className="forgot-green-line"></div>

              <div className="" style={{ width: "80%", marginTop: "100px" }}>
                <button
                  className="btn btn-primary w-100 mb-3"
                  style={{ height: "50px" }}
                  onClick={() => {
                    dispatch(setAcitveNav("home"))
                    navigate("/")
                  }}
                >
                  {dataInfo[favoriteLenguaje][2]?.continue}
                </button>
              </div>
            </div>
          ) : null}

          {step === 3 ? (
            <div className="verify-container">
              <div className="forgot-device">
                <img src={wrongEmail} alt="device-wrong" />
              </div>
              <div className="verify-container-title">{dataInfo[favoriteLenguaje][3]?.validationWarning}</div>
              <div className="verify-container-content">
              {dataInfo[favoriteLenguaje][3]?.validationWarning2}
              <br />
              {dataInfo[favoriteLenguaje][3]?.validationWarning3}
              </div>
              <div className="forgot-green-line"></div>

              <div className="" style={{ width: "80%", marginTop: "100px" }}>
                <button
                  className="btn btn-primary w-100 mb-3"
                  style={{ height: "50px" }}
                  onClick={() => {
                    setStep(1);
                  }}
                >
                  {dataInfo[favoriteLenguaje][3]?.tryAgain}
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
    <div className="verify-footer">
      <span className="verify-footer-title">© Copyright {year} NDABee.</span>
    </div>

    <InfoModal
      notificationImage={notification}
      notificationTitle={dataInfo[favoriteLenguaje][3]?.notification}
      show={show}
      handleClose={handleClose}
      notificationMessage={
        <div>
          {dataInfo[favoriteLenguaje][3]?.modalMessage}
          <br />
          {dataInfo[favoriteLenguaje][3]?.modalMessage2}
        </div>
      }
    />

    <ToastContainer theme="colored" position="top-right" autoClose={2000} />
  </div>
  )
}

export default ConfirmEmail