import React from 'react'
import "./DashboardCard.css"
const DashboardCard = (props) => {
  return (
    <div className='dc-principal-container'>
        <div className='dc-image'>
            <img src={`${props.img}`} alt="content-logo" />
        </div>
        <div className='dc-info'>
            <div className='dc-confirm'> 
                <img src={props.confirm} alt="confirm-logo" />
            </div>
            <div className='dc-title'>{props.title}</div>
            <div className='dc-subtitle'>{props.subtitle}</div>
            <div className='dc-button'>
            <button type="button" className="btn btn-primary" onClick={props.onClick} disabled={props.disabled}>{props.button}</button>
            </div>
            
        </div>
   </div>
  )
}

export default DashboardCard