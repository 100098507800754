import { createSlice } from "@reduxjs/toolkit"; 

export const setupSlice = createSlice({
    name:"setup",
    initialState:{
        isSetup: false,
    },
    reducers:{
        setupSuccess: (state) => {
            state.isSetup = true;
        },
    }
})

export const {setupSuccess} = setupSlice.actions