import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import notification from "../../images/notification-logo.png"
import "./InfoModal.css";

const InfoModal = (props) => {

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={props.show}
        onHide={props.handleClose}
        className="modal-height"
      >
        <div
          className="modal-background"
        >
          <Modal.Header closeButton style={{ border: "none"}}>
          </Modal.Header>
          <Modal.Body>
            <div className="notification-img">
                <img src={props.notificationImage} alt="notification" />
            </div>
            <div className="notification-body">
                <span className="notification-title">{props.notificationTitle}</span>
                <div className="notification-white-line"></div>
                <span className="notification-message">{props.notificationMessage}</span>
            </div>
          </Modal.Body>
          <Modal.Footer style={{ border: "none" }}>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default InfoModal;
