import React, { useEffect, useState } from "react";
import { Notifications } from "../../Apis/List/Models/Notifications";
import { GetByMember } from "../../Apis/Accounts/Models/GetByMember";
import {GetNotification} from "../../Apis/Members/Models/GetNotification";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../../redux/features/alertSlice";
import {setAcitveNav} from "../../redux/features/userSlice"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { USER_ID } from "../../Constants/User";
import NotificationCard from "../../Components/NotificationCard/NotificationCard";
import avatar2 from "../../images/avatar.png";
import { useNavigate } from "react-router-dom";
import "./Alerts.css";
import { dataInfo } from "../../pruebasJson/data";


const Alerts = () => {
  const [notifications, setNotifications] = useState();
  const [memberInfo, setMemberInfo] = useState();
  const [status, setStatus] = useState("new");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentId = USER_ID();
  const year = new Date().getFullYear();
  const { favoriteLenguaje } = useSelector((state) => state.user);

  const listNotifications = async () => {
    dispatch(showLoading());

    const classRef = new Notifications();
    await classRef.List({ MembersId: parseInt(currentId) });
    let result = classRef.ResponseBody;

    result.NotificationsListInfo.sort((a, b) => {
      const dateA = new Date(a.ChangedOn);
      const dateB = new Date(b.ChangedOn);
      return dateB - dateA;
    });

    dispatch(hideLoading());
    setNotifications(result.NotificationsListInfo);
    console.log("===>>", result.NotificationsListInfo);
  };

  const getMemberInfo = async () => {
    dispatch(showLoading());
    const classRef = new GetByMember();
    await classRef.List({ MembersId: parseInt(currentId) });
    let result = classRef.ResponseBody;
    dispatch(hideLoading());

    setMemberInfo(result);
    console.log("===>>", result.AccountInfo);
  };

  const getInfoNotifiacion = async (id) => {
    const classRef = new GetNotification(id);
    await classRef.List();
    let result = classRef.ResponseBody;
    console.log("Notification Result: ", result)
  }

  useEffect(() => {
    dispatch(setAcitveNav("alerts"))
    listNotifications();
    getMemberInfo();
  }, []);
  return (
    <>
      <div className="principal-container-alerts">
        <div></div>
        <div className="alerts-main-container">
          <div className="alerts-title">{dataInfo[favoriteLenguaje][3]?.alerts}</div>
          <div className="alerts-greenline-container mt-2 mb-2">
            <div className="alert-greenline"></div>
          </div>
          <div className="status_container">
            <span
              onClick={() => {
                setStatus("new");
              }}
              style={{
                borderBottom: `${
                  status === "new" ? "2px solid #06B576" : "none"
                }`,
                paddingBottom: "2px",
              }}
            >
              {dataInfo[favoriteLenguaje][3]?.new}
            </span>
            <span
              onClick={() => {
                setStatus("read");
              }}
              style={{
                borderBottom: `${
                  status === "read" ? "2px solid #06B576" : "none"
                }`,
                paddingBottom: "2px",
              }}
            >
              {dataInfo[favoriteLenguaje][3]?.readAlert}
            </span>
          </div>
          {status === "new" && (
            <div className="alert-container">
              {notifications?.filter((item) => !item.IsRead).length > 0 ? (
                notifications
                  .filter((item) => !item.IsRead)
                  .map((item, index) => {
                    const dateTimeParts = item.ChangedOn.split(" ");
                    const date = dateTimeParts[0].replace(/-/g, "/");
                    const timeParts = dateTimeParts[1].split(":");
                    const time = `${timeParts[0]}:${timeParts[1]} ${dateTimeParts[2]}`;
                    return (
                      <NotificationCard
                        alert="#0572D2"
                        key={index}
                        text={item.Body}
                        name={item.Title}
                        date={date}
                        hour={time}
                        status={dataInfo[favoriteLenguaje][3]?.new}
                        imgContact={item.AvatarUrl ? item.AvatarUrl : avatar2}
                        color="#0d6efd"
                        onClick={() => {
                          getInfoNotifiacion(item.Id)
                          navigate(
                            `/view-document/${memberInfo.AccountInfo.Id}/${item.DocumentsId}/false`
                          );
                        }}
                      />
                    );
                  })
              ) : (
                <div>{dataInfo[favoriteLenguaje][3]?.noNew}</div>
              )}
            </div>
          )}

          {status === "read" && (
            <div className="alert-container">
              {notifications?.filter((item) => item.IsRead).length > 0 ? (
                notifications
                  .filter((item) => item.IsRead)
                  .map((item, index) => {
                    const dateTimeParts = item.ChangedOn.split(" ");
                    const date = dateTimeParts[0].replace(/-/g, "/");
                    const timeParts = dateTimeParts[1].split(":");
                    const time = `${timeParts[0]}:${timeParts[1]} ${dateTimeParts[2]}`;
                    return (
                      <NotificationCard
                       alert="#06b576"
                        key={index}
                        text={item.Body}
                        name={item.Title}
                        date={date}
                        hour={time}
                        status={dataInfo[favoriteLenguaje][3]?.readAlert}
                        imgContact={item.AvatarUrl ? item.AvatarUrl : avatar2}
                        color="rgb(6, 181, 118)"
                        onClick={() => {
                          navigate(
                            `/view-document/${memberInfo.AccountInfo.Id}/${item.DocumentsId}/false`
                          );
                        }}
                      />
                    );
                  })
              ) : (
                <div>{dataInfo[favoriteLenguaje][3]?.noRead}</div>
              )}
            </div>
          )}
        </div>
        <div className="alerts-footer">© Copyright {year} NDABee.</div>
      </div>
    </>
  );
};

export default Alerts;
