import { createSlice } from "@reduxjs/toolkit"; 

export const authSlice = createSlice({
    name:"auth",
    initialState:{
        isAuthenticated: false,
    },
    reducers:{
        loginSuccess: (state) => {
            state.isAuthenticated = true;
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.user = null;
        }
    }
})

export const {loginSuccess,logout} = authSlice.actions