import React from "react";
import { BounceLoader } from "react-spinners";
import './Spinner.css'

const Spinner = () => {


  return (
    <div className="container">
        <div className="sub-container">
        <BounceLoader
          color="rgb(73, 163, 241)"
          cssOverride={{}}
          size={200}
        />
        </div>
    </div>
  );
};

export default Spinner;
