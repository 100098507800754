import React, { useEffect, useState } from 'react'
import "./TermsOfService.css"
import Menu from '../../Components/Menu/Menu'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { dataInfo } from "../../pruebasJson/data";

const TermsOfService = () => {
  const user = localStorage.getItem('User')
  const year = new Date().getFullYear();
  const navigate = useNavigate();

  const[align,setAlign] = useState("left");
  const [size,setSize] = useState(20);
  const [lenguaje, setLenguaje] = useState("en");
  const { favoriteLenguaje } = useSelector((state) => state.user);

  useEffect(() => {
    const userLanguage = navigator.language || navigator.userLanguage;
    const languageCode = userLanguage.startsWith('es') ? 'es' : 'en';
    setLenguaje(languageCode);

  }, []);
  
  return (
    <div>
      {!user && ( <Menu/> )}
      <div className='term-fo-service-principal-container'>
        <div className='terms-container' style={{marginTop:`${user ? "50px" : "200px"}`}}>
        <div style={{display:"flex", justifyContent:"space-between"}}>
          <div
            className="btn-group"
            role="group"
            aria-label="Basic outlined example"
          >
            <button type="button" className="btn btn-outline-primary" onClick={()=>{setAlign("left")}}>
            {dataInfo[favoriteLenguaje][4]?.left}
            </button>
            <button type="button" className="btn btn-outline-primary" onClick={()=>{setAlign("justify")}}>
            {dataInfo[favoriteLenguaje][4]?.justify}
            </button>
            <button type="button" className="btn btn-outline-primary" onClick={()=>{setAlign("right")}}>
              {dataInfo[favoriteLenguaje][4]?.right}
            </button>
          </div>
          <div
            className="btn-group"
            role="group"
            aria-label="Basic outlined example"
          >
            <button type="button" className="btn btn-outline-primary" onClick={() => setSize(prevSize => prevSize + 1)}>
              +
            </button>
            <div  className="btn btn-outline-primary">
              {size === "" ? "20" : size}
            </div>
            <button type="button" className="btn btn-outline-primary" onClick={() => setSize(prevSize => prevSize - 1)}>
              -
            </button>
          </div>
        </div>
          <h1 className='terms-title' style={{fontSize: `${size * 1.5}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][6]?.termsTitle}</h1>
          <div className='green-line-terms'>
            <div className='green-line-term'></div>
          </div>
          <h2 className='terms-subtitle mt-5' style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][6]?.efecitveDate}</h2>
          <p className='terms-text' style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][6]?.termsText1} <a href="https://www.confidentialbee.com/" target='_blank' rel="noreferrer" style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>www.confidentialbee.com</a> {dataInfo[favoriteLenguaje][6]?.termsText2}</p>
          <ol>
            <li style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>
            <h3 className='terms-h3' style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][6]?.termsUseOfSite}</h3>
            <p className='terms-text' style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][6]?.termsUseOfSiteText}</p>
            </li>
            <li style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>
            <h3 className='terms-h3' style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][6]?.termsUserAccount}</h3>
            <p className='terms-text' style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][6]?.termsUserAccountText}</p>
            </li>
            <li style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>
            <h3 className='terms-h3' style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][6]?.termsProhibitedActivities}</h3>
            <p className='terms-text' style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][6]?.termsProhibitedActivitiesText}</p>
            </li>
            <li style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>
            <h3 style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}} className='terms-h3'>{dataInfo[favoriteLenguaje][6]?.termsProhibited}</h3>
            <p style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}className='terms-text'>{dataInfo[favoriteLenguaje][6]?.termsProhibitedText}</p>
            <ul className='ul-list' style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>
              <li style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][6]?.termsProhibitedText2}</li>
              <li style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][6]?.termsProhibitedText3}</li>
              <li style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][6]?.termsProhibitedText4}</li>
            </ul>
            </li>
            <li style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>
            <h3 style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}} className='terms-h3'>{dataInfo[favoriteLenguaje][6]?.termsLimitation}</h3>
            <p style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}} className='terms-text'>{dataInfo[favoriteLenguaje][6]?.termsLimitationText}</p>
            </li>
            <li style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>
            <h3 style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}} className='terms-h3'>{dataInfo[favoriteLenguaje][6]?.termsIndemnification}</h3>
            <p style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}} className='terms-text'>{dataInfo[favoriteLenguaje][6]?.termsIndemnificationText}</p>
            </li>
            <li style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>
            <h3 style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}} className='terms-h3'>{dataInfo[favoriteLenguaje][6]?.termsGoverning}</h3>
            <p style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}} className='terms-text'>{dataInfo[favoriteLenguaje][6]?.termsGoverningText}</p>
            </li>
            <li style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>
            <h3 style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}} className='terms-h3'>{dataInfo[favoriteLenguaje][6]?.termsChanges}</h3>
            <p style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}} className='terms-text'>{dataInfo[favoriteLenguaje][6]?.termsChangesText}</p>
            </li>
          </ol>
          <h2 style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}} className='terms-subtitle2 mt-5'>{dataInfo[favoriteLenguaje][6]?.termsClosing}</h2>
          <p style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}} className='terms-text'>{dataInfo[favoriteLenguaje][6]?.termsClosingText}</p>
        </div>
        <div className='before-footer'>Terms of Service | Privacy Policy</div>
        <div className='footer-terms'>© Copyright {year} NDABee.</div>
      </div>
    </div>
  )
}

export default TermsOfService