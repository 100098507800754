import { Base2 } from '../Base2';

export class GetAvatar extends Base2 {
    Items = [];

    constructor(id) {
        super();
        this.Controller = `Members/Avatar/Get/${id}`;
    }

    async List() {
        await super.load();
        this.Items = this.ResponseBody;
    }
}
