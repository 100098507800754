import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from './features/authSlice';
import { setupSlice } from "./features/setupSlice";
import { alertSlice } from "./features/alertSlice";
import { userSlice } from "./features/userSlice";
import { accountInfo } from "./features/accountInfo";
import { userInfo } from "./features/userInfo";

const store = configureStore({
    reducer: {
      auth: authSlice.reducer,
      setup: setupSlice.reducer,
      alerts: alertSlice.reducer,
      user: userSlice.reducer,
      account: accountInfo.reducer,
      info : userInfo.reducer,
    },
  });
  
  export default store;