import React, { useEffect, useState } from "react";
import "./NdaTerms.css";
import { useSelector } from "react-redux";
import { dataInfo } from "../../pruebasJson/data";

const NdaTerms = () => {
  const[align,setAlign] = useState("left");
  const [size,setSize] = useState(20);
  const [lenguaje, setLenguaje] = useState("en");
  const year = new Date().getFullYear();
  const { favoriteLenguaje } = useSelector((state) => state.user);

  useEffect(() => {
    const userLanguage = navigator.language || navigator.userLanguage;
    const languageCode = userLanguage.startsWith('es') ? 'es' : 'en';
    setLenguaje(languageCode);
  }, []);
  return (
    <div>
      <div className="term-fo-service-principal-container">
        <div className="termsnda-container">
        <div style={{display:"flex", justifyContent:"space-between"}}>
          <div
            className="btn-group"
            role="group"
            aria-label="Basic outlined example"
          >
            <button type="button" className="btn btn-outline-primary" onClick={()=>{setAlign("left")}}>
            {dataInfo[favoriteLenguaje][4]?.left}
            </button>
            <button type="button" className="btn btn-outline-primary" onClick={()=>{setAlign("justify")}}>
            {dataInfo[favoriteLenguaje][4]?.justify}
            </button>
            <button type="button" className="btn btn-outline-primary" onClick={()=>{setAlign("right")}}>
              {dataInfo[favoriteLenguaje][4]?.right}
            </button>
          </div>
          <div
            className="btn-group"
            role="group"
            aria-label="Basic outlined example"
          >
            <button type="button" className="btn btn-outline-primary" onClick={() => setSize(prevSize => prevSize + 1)}>
              +
            </button>
            <div  className="btn btn-outline-primary">
              {size === "" ? "20" : size}
            </div>
            <button type="button" className="btn btn-outline-primary" onClick={() => setSize(prevSize => prevSize - 1)}>
              -
            </button>
          </div>
        </div>
          <h1 className="terms-title">{dataInfo[favoriteLenguaje][4]?.termsnda}</h1>
          <div className="green-line-terms">
            <div className="green-line-term"></div>
          </div>
          <p className="terms-text mt-4" style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>
            {dataInfo[favoriteLenguaje][4]?.text1}
          </p>
          <div className="row p-2">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <p className="terms-text" style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>
                <span style={{ paddingRight: "10px" }}>1.</span>{dataInfo[favoriteLenguaje][4]?.text2}
              </p>
              <p className="terms-text" style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>
                <span style={{ paddingRight: "10px" }}>2.</span>{dataInfo[favoriteLenguaje][4]?.text3}
              </p>
              <p className="terms-text" style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>
                <span style={{ paddingRight: "10px" }}>3.</span>{dataInfo[favoriteLenguaje][4]?.text4}
              </p>
              <p className="terms-text" style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>
                <span style={{ paddingRight: "10px" }}>4.</span>
                {dataInfo[favoriteLenguaje][4]?.text5}
              </p>
              <p className="terms-text" style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>
                <span style={{ paddingRight: "10px" }}>5.</span>
                {dataInfo[favoriteLenguaje][4]?.text6}
              </p>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <p className="terms-text" style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>
              {dataInfo[favoriteLenguaje][4]?.text7}
              </p>
              <p className="terms-text" style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>
                <span style={{ paddingRight: "10px" }}>6.</span>
                {dataInfo[favoriteLenguaje][4]?.text8}
              </p>
              <p className="terms-text" style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>
                <span style={{ paddingRight: "10px" }}>7.</span>
                {dataInfo[favoriteLenguaje][4]?.text9}
              </p>
              <p className="terms-text" style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>
                <span style={{ paddingRight: "10px" }}>8.</span>
                {dataInfo[favoriteLenguaje][4]?.text10}
              </p>
              <p className="terms-text" style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>
                <span style={{ paddingRight: "10px" }}>9.</span>
                {dataInfo[favoriteLenguaje][4]?.text11}
              </p>
              <p className="terms-text" style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>
                <span style={{ paddingRight: "10px" }}>10.</span>
                {dataInfo[favoriteLenguaje][4]?.text12}
              </p>
              <p className="terms-text" style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>
                <span style={{ paddingRight: "10px" }}>11.</span>
                {dataInfo[favoriteLenguaje][4]?.text13}
              </p>
              <p className="terms-text" style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>
                <span style={{ paddingRight: "10px" }}>12.</span>
                {dataInfo[favoriteLenguaje][4]?.text14}
              </p>
              <p className="terms-text" style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>
                <span style={{ paddingRight: "10px" }}>13.</span>
                {dataInfo[favoriteLenguaje][4]?.text15}
              </p>
              <p className="terms-text" style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>
                <span style={{ paddingRight: "10px" }}>14.</span>
                {dataInfo[favoriteLenguaje][4]?.text16}
              </p>
            </div>
          </div>
          <p className="terms-text mt-3 text-center" style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>
            {dataInfo[favoriteLenguaje][4]?.text17}
          </p>
        </div>
        <div className="footer-terms">© Copyright {year} NDABee.</div>
      </div>
    </div>
  );
};

export default NdaTerms;
