import React, { useState } from "react";
import "./SetupEntity.css";
import forgotLogo from "../../images/forgot-logo.png";
import Stepper from "../../Components/Stepper/Stepper";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAcitveNav } from "../../redux/features/userSlice";

const SetupEntity = () => {
  const [activeStep, setActiveStep] = useState(1);
  const year = new Date().getFullYear();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div className="principal-setup-container">
      {/*Logo setup entity */}
      <div className="row mt-4 p-4">
        <img src={forgotLogo} alt="logo bee" style={{cursor:"pointer"}} onClick={()=>{
          dispatch(setAcitveNav("home"))
          navigate("/")
        }}/>
      </div>

      {/*Setup entity*/}
      <div className="steper-container">
       <Stepper/>
      
      </div>

      {/*Footer*/}
      <div className="steper-footer">
      © Copyright {year} NDABee.
      </div>
    </div>
  );
};

export default SetupEntity;
