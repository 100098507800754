import React from 'react'
import out from "../../images/uncoming.png"
import inc from "../../images/incoming.png"
import modelo from "../../images/modelo.png"
import pdf from "../../images/pdf-logo.png"
import "./NotificationCard.css"

const NotificationCard = (props) => {
  return (
    <div className='document-card-container' onClick={props.onClick} style={{cursor:"pointer"}}>
    <div className='first-alert' style={{backgroundColor:`${props.alert}`}}>
    </div>
    <div className='second'>
      <div className='second-step-container'>
        <div className='second-step-img'>
          <img src={props.imgContact} alt="contact" width={50} height={50} style={{objectFit:"cover",borderRadius:"50%"}}/>
        </div>
        <div className='second-step-info'>
          <span className='second-name'>{props.name}</span>
          <span className='second-company'></span>
          <span className='second-type'>secret</span>
        </div>
      </div>
      <div className='second-doc-type'>{props.text}</div>
    </div>
    <div className='third'>
      <div className='third-read-container' style={{backgroundColor:`${props.color}`}}>
        {props.status}
      </div>
      <div className='third-data-content'>
        <span>{props.date}</span>
        <span>{props.hour} {props.period}</span>
      </div>
      <div className='third-img'>
        <img src={pdf} alt="pdf-logo" />
      </div>
    </div>
</div>
  )
}

export default NotificationCard