import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import notification from "../../images/notification-logo.png"
import { dataInfo } from "../../pruebasJson/data";
import "./InfoModal.css";
import { useSelector } from "react-redux";

const InfoModal = (props) => {
  const { favoriteLenguaje } = useSelector((state) => state.user);
  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={props.show}
        onHide={props.handleClose}
      >
        <div
          className="modal-background3"
        >
          <Modal.Header closeButton style={{ border: "none"}}>
          </Modal.Header>
          <Modal.Body>
            <div className="notification-img">
                <img src={props.notificationImage} alt="notification" />
            </div>
            <div className="notification-body">
                <span className="notification-title" style={{color:"black"}}>{props.notificationTitle}</span>
                <div className="notification-green-line"></div>
                <span className="notification-message" style={{color:"black"}}>{props.notificationMessage}</span>
                <div className="notification-green-line"></div>
            </div>
          </Modal.Body>
          <Modal.Footer style={{ border: "none", padding:"0px"}}>
            <div className="footer-modal2">
            {/* <div className="footer-cancel" onClick={props.submit}>{dataInfo[favoriteLenguaje][3]?.cancelButton}</div>
            <div className="footer-accept" onClick={props.cancel}>{dataInfo[favoriteLenguaje][2]?.submit}</div> */}
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default InfoModal;
