import React, { useEffect, useState } from "react";
import "./EditProfile.css";
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput from "react-phone-input-2";
import { USER_ID } from "../../Constants/User";
import { useNavigate } from "react-router-dom";
import { Get } from "../../Apis/Members/Models/Get";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../../redux/features/alertSlice";
import { Save } from "../../Apis/Members/Models/Save";
import { toast, ToastContainer } from "react-toastify";
import { dataInfo } from "../../pruebasJson/data";

const EditProfile = () => {
  const currentId = USER_ID();
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [dialCode2, setDialCode2] = useState("");
  const [valid, setValid] = useState(true);
  const year = new Date().getFullYear();
  const dispatch = useDispatch();
  const { favoriteLenguaje } = useSelector((state) => state.user);

  const [formulario, setFormulario] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    PhoneCountryCode: "",
    PhoneNo: "",
    username: "",
  });

  const resetFrom = () => {
    setFormulario({
      FirstName: "",
      LastName: "",
      Email: "",
      PhoneCountryCode: "",
      PhoneNo: "",
      username: "",
    });
    setPhoneNumber("");
  };

  const handlePhoneChange = (value, country, e, formattedValue) => {
    setPhoneNumber(value);
    setFormulario({ ...formulario, PhoneNo: formattedValue });
    setFormulario({ ...formulario, PhoneCountryCode: `+${country.dialCode}` });
    setDialCode("+" + country.dialCode);
    setDialCode2(country.dialCode);
    setValid(validatePhoneNumber(formattedValue));
    console.log(value);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;

    return phoneNumberPattern.test(phoneNumber);
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setFormulario({ ...formulario, [name]: value });
  };
  const getUserInfo = async () => {
    try {
      dispatch(showLoading());
      const classRef = new Get();
      await classRef.List({ MembersId: parseInt(currentId) });
      let result = classRef.ResponseBody;
      dispatch(hideLoading());
      const user = result.MemberInfo;
      const phone = user.PhoneCountryCode + user.PhoneNo;
      setFormulario({
        FirstName: user.FirstName,
        LastName: user.LastName,
        Email: user.Email,
        PhoneCountryCode: user.PhoneCountryCode,
        PhoneNo: user.PhoneNo,
        username: user.UserName,
      });
      setPhoneNumber(phone);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const saveEditData = async () => {
    dispatch(showLoading());
    try {
      const arrayNumber = phoneNumber.split("");
      const dialArray = dialCode2.split("");
      const cleanArray = [];
      let cleanString = "";
      console.log("Array number", arrayNumber);
      console.log("Dial array", dialArray);

      for (let i = 0; i < arrayNumber.length; i++) {
        if (dialArray[i] !== "") {
          if (dialArray[i] !== arrayNumber[i]) {
            cleanArray.push(arrayNumber[i]);
            cleanString = cleanString + arrayNumber[i];
          }
        } else {
          cleanArray.push(arrayNumber[i]);
          cleanString = cleanString + arrayNumber[i];
        }
      }
      console.log("Clean Array =>>", cleanArray);
      console.log("String number", cleanString);

      const classRef = new Save();
      await classRef.List({
        MembersId: parseInt(currentId),
        Email: formulario.Email,
        Password: null,
        SocialFacebookId: null,
        SocialFacebookToken: null,
        SocialLinkedInId: null,
        SocialLinkedInToken: null,
        SocialGmailId: null,
        FirstName: formulario.FirstName,
        LastName: formulario.LastName,
        // UserName: formulario.username,
        Country: null,
        PhoneCountryCode: dialCode2
          ? `+${dialCode}`
          : formulario.PhoneCountryCode,
        PhoneNo: cleanString.includes("+") ? null : cleanString,
      });
      let result = classRef.ResponseBody;
      dispatch(hideLoading());
      if (result.MemberInfo) {
        toast.success(dataInfo[favoriteLenguaje][5]?.successProfile);
      }
    } catch (error) {
      dispatch(hideLoading())
      console.log("Error",error)
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <>
      <div className="edit-principal-container">
        <div></div>
        <div className="edit-container">
          <div className="edit-title">{dataInfo[favoriteLenguaje][3]?.editProfile}</div>
          <div className="edit-greenline-container mt-3 mb-4">
            <div className="edit-greenline"></div>
          </div>
          <div className="form-edit">
            {/* <div
              className="mb-4"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                paddingBottom: "25px",
                borderBottom: "1px solid #06b576",
              }}
            >
              <label
                htmlFor="username"
                className="form-label label-title label"
              >
                {dataInfo[favoriteLenguaje][2]?.username}
              </label>
              <input
                type="text"
                autoComplete="off"
                value={formulario.username}
                name="username"
                onChange={handleChange2}
                className="form-control"
                style={{ padding: "10px", height: "52px" }}
                id="username"
                placeholder="Username"
                required
              />
            </div> */}

            <div
              className="mb-4"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                paddingBottom: "25px",
                borderBottom: "1px solid #06b576",
              }}
            >
              <label
                htmlFor="firstname"
                className="form-label label-title label"
              >
                {dataInfo[favoriteLenguaje][3]?.firstAndLast}
              </label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "15px",
                }}
              >
                <input
                  type="text"
                  autoComplete="off"
                  value={formulario.FirstName}
                  name="FirstName"
                  onChange={handleChange2}
                  className="form-control"
                  style={{ padding: "10px", height: "52px" }}
                  id="firstname"
                  placeholder="First Name"
                  required
                />
                <input
                  type="text"
                  autoComplete="off"
                  value={formulario.LastName}
                  name="LastName"
                  onChange={handleChange2}
                  className="form-control"
                  style={{ padding: "10px", height: "52px" }}
                  id="lastname"
                  placeholder="Last Name"
                  required
                />
              </div>
            </div>

            <div
              className="mb-4"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                paddingBottom: "25px",
                borderBottom: "1px solid #06b576",
              }}
            >
              <label
                htmlFor="exampleFormControlInput3"
                className="form-label label-title label"
              >
                {dataInfo[favoriteLenguaje][2]?.phoneNumber}
              </label>
              <PhoneInput
                country={"us"}
                required
                value={phoneNumber}
                onChange={handlePhoneChange}
                inputProps={{
                  style: {
                    height: "52px",
                    width: "100%",
                    border: "1px solid lightgray",
                  },
                  required: true,
                }}
              />
            </div>

            <div
              className="mb-4"
              style={{
                display: "flex",
                flexDirection: "column",
                paddingBottom: "25px",
                borderBottom: "1px solid #06b576",
              }}
            >
              <label
                htmlFor="exampleFormControlInput4"
                className="form-label label-title label"
              >
                {dataInfo[favoriteLenguaje][2]?.email}
              </label>
              <input
                type="email"
                className="form-control"
                value={formulario.Email}
                name="Email"
                onChange={handleChange2}
                style={{ padding: "10px", height: "52px" }}
                id="exampleFormControlInput4"
                placeholder="Email"
                required
              />
            </div>

            <div className="col-12">
              <button
                // type="submit"
                onClick={() => {
                  saveEditData();
                }}
                className="btn btn-primary w-100 mb-3"
                style={{ height: "50px" }}
              >
                {dataInfo[favoriteLenguaje][2]?.submit}
              </button>
            </div>
          </div>
        </div>
        <div className="edit-footer">© Copyright {year} NDABee.</div>
      </div>
      <ToastContainer theme="colored" position="top-right" autoClose={2000} />
    </>
  );
};

export default EditProfile;
