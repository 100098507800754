import React, { useEffect, useState } from "react";
import profile from "../../images/profile.png";
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput from "react-phone-input-2";
import paperplane from "../../images/paperplane.png";
import search from "../../images/search.png";
import checkImage from "../../images/check_circle.png";
import pencil from "../../images/pencil.png";
import contactImg2 from "../../images/contact-img.png";
import avatar from "../../images/avatar.png";
import "./NDA.css";
import { data } from "../../pruebasJson/data";
import {
  BASE_URL,
  HEADERS,
  SERVICES,
  SERVICES_NAME,
  VERSION,
} from "../../Constants/Auth";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../../redux/features/alertSlice";
import { Get } from "../../Apis/Members/Models/Get";
import { GetByMember } from "../../Apis/Accounts/Models/GetByMember";
import { GetByPhone } from "../../Apis/Members/Models/GetByPhone";
import { Send } from "../../Apis/Documents/Models/Send";
import { setAcitveNav } from "../../redux/features/userSlice";
import letter from "../../images/letter.png"
import contact from "../../images/contact.png"
import phone from "../../images/phone.png"
import { GetByEmail } from "../../Apis/Members/Models/GetByEmail";
import { dataInfo } from "../../pruebasJson/data";

const NDA = () => {
  const [ndaType,setNdaType] = useState("email");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumber2, setPhoneNumber2] = useState("");
  const [contactStep, setContactStep] = useState(1);
  const [dialCode, setDialCode] = useState("");
  const [valid, setValid] = useState(true);
  const [check, setCheck] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [contactData, setContactData] = useState();
  const [memberInfo, setMemberInfo] = useState();
  const [userData, setUserData] = useState();
  const [formulario, setFormulario] = useState({
    FirstName: "",
    LastName: "",
    Username: "",
    Email: "",
    PhoneCountryCode: "",
    PhoneNo: "",
  });
  const [checkNumberInfo, setCheckNumberInfo] = useState();
  const [contactImg, setContactImg] = useState();
  const navigate = useNavigate();
  const { Id } = useParams();
  const baseUrl = BASE_URL + SERVICES + "/" + VERSION + "/" + SERVICES_NAME;
  const dispatch = useDispatch();
  const year = new Date().getFullYear();
  const { favoriteLenguaje } = useSelector((state) => state.user);

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setFormulario({ ...formulario, [name]: value });
  };

  const handlePhoneChange = (value, country, e, formattedValue) => {
    setPhoneNumber2(value);
    setPhoneNumber(formattedValue);
    setFormulario({ ...formulario, PhoneNo: formattedValue });
    setFormulario({ ...formulario, PhoneCountryCode: `+${country.dialCode}` });
    setDialCode("+" + country.dialCode);
    setValid(validatePhoneNumber(formattedValue));
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;

    return phoneNumberPattern.test(phoneNumber);
  };

  const handleCheck = (index, data) => {
    setSelectedContact(index === selectedContact ? null : index);
    setContactData(data);
    console.log("Datos del contacto", data);
    setContactStep(3);
  };

  const getMemberInfo = async () => {
    try {
      const classRef = new Get();
      await classRef.List({MembersId: parseInt(Id)})
      const result = classRef.ResponseBody;
  
      if(result.MemberInfo){
        setUserData(result.MemberInfo);
      }
    } catch (error) {
      console.log("Error",error)
    }
  };

  /*Check phone number*/
  const checkInfo = async () => {
    dispatch(showLoading())
    if(phoneNumber2){
      try {
        const classRef = new GetByPhone();
        await classRef.List({
          FullPhoneNumber: `+${phoneNumber2}`,
        })
        let result = classRef.ResponseBody;
        console.log("Result",result)
        setCheckNumberInfo(result);
          if (result.MembersId) {
            getAvatar(result.MembersId);
          }
          dispatch(hideLoading());
          setContactStep(3);
          console.log("Result checkphone number", result);
      } catch (error) {
        dispatch(hideLoading());
        console.log("Error",error)
      }
    } else if(formulario?.Email){
      try {
        const classRef = new GetByEmail();
        await classRef.List({
          Email: formulario?.Email,
        })
        let result = classRef.ResponseBody;
        console.log("Result",result)
        setCheckNumberInfo(result);
          if (result.MembersId) {
            getAvatar(result.MembersId);
          }
          dispatch(hideLoading());
          setContactStep(3);
          console.log("Result checkphone number", result);
      } catch (error) {
        dispatch(hideLoading());
        console.log("Error",error)
      }
    }
  };

  /*get avatar*/
  const getAvatar = async (id) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/Members/Avatar/Get/${id}`,
      headers: HEADERS(),
    };

    axios
      .request(config)
      .then((response) => {
        setContactImg(response.data);
        console.log("Img", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*get by member*/
  const getByMember = async () => {
    try {
      const classRef = new GetByMember();
      await classRef.List({ MembersId: parseInt(Id)})
      let result = classRef.ResponseBody;
  
      if(result.AccountInfo.FilesId_Signature > 0){
        setMemberInfo(result);
      } else{
        toast.warning(dataInfo[favoriteLenguaje][5]?.warningEntityAndSignature)
        setTimeout(()=>{
          navigate(`/verify-entity/${Id}`)
        },2500)
      }
    } catch (error) {
      console.log("Error",error)
    }
  };

  /*send document*/
  const sendDocument = async () => {
    dispatch(showLoading())
    try {
      const classRef = new Send();
      await classRef.List({
        MembersId: parseInt(Id),
        MembersIdReceiver: checkNumberInfo?.MembersId
        ? parseInt(`${checkNumberInfo?.MembersId}`) : null,
        PhoneNoRecipient: phoneNumber2 ? `+${phoneNumber2}` : null,
        EmailRecipient: formulario.Email ? formulario.Email : null,
        FullNameRecipient: checkNumberInfo?.MembersId ? `${checkNumberInfo?.FirstName} ${checkNumberInfo?.LastName}` : `${formulario.Username}`,
      })
      let result = classRef.ResponseBody;
      if(result.ListDocumentsSent){
        dispatch(hideLoading())
        toast.success(dataInfo[favoriteLenguaje][5]?.successDocument)
        setTimeout(()=>{
          dispatch(setAcitveNav("home"))
          navigate("/")
        },1500)}
    } catch (error) {
      console.log("Error", error)
    }
  };

  useEffect(() => {
    getMemberInfo();
    getByMember();
  }, []);
  return (
    <>
      <div className="nda-principal-container">
        <div className="nda-profile-container">
          {contactStep === 1 ? (
            <>
              <div className="nda-image-profile">
                <img src={profile} alt="profile" />
              </div>
              <div className="nda-profile-title">{dataInfo[favoriteLenguaje][3]?.ndaRecipient}</div>
              <div className="nda-profile-greenline"></div>
              <div className="nda-profile-form">
                <div
                  className="mb-4"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <label
                    htmlFor="exampleFormControlInput4"
                    className="form-label label-title"
                  >
                    {dataInfo[favoriteLenguaje][3]?.ndaRecipient2}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={formulario?.Username}
                    name="Username"
                    onChange={handleChange2}
                    style={{ padding: "10px", height: "52px" }}
                    id="exampleFormControlInput4"
                    placeholder={dataInfo[favoriteLenguaje][3]?.ndaRecipient2}
                    required
                  />
                </div>
                
                {ndaType === "phone" && (
                   <div
                   className="mb-4"
                   style={{
                     display: "flex",
                     flexDirection: "column",
                     width: "100%",
                   }}
                 >
                   <label
                     htmlFor="exampleFormControlInput3"
                     className="form-label label-title"
                   >
                     {dataInfo[favoriteLenguaje][3]?.ndaPhone}
                   </label>
                   <PhoneInput
                     country={"us"}
                     required
                     value={phoneNumber}
                     onChange={handlePhoneChange}
                     inputProps={{
                       style: {
                         height: "52px",
                         width: "100%",
                         border: "1px solid lightgray",
                       },
                       required: true,
                     }}
                   />
                 </div>
                )}
               
               {ndaType === "email" && (
                   <div
                   className="mb-4"
                   style={{
                     display: "flex",
                     flexDirection: "column",
                     width: "100%",
                   }}
                 >
                   <label
                     htmlFor="exampleFormControlInput4"
                     className="form-label label-title"
                   >
                     {dataInfo[favoriteLenguaje][3]?.ndaEmail}
                   </label>
                   <input
                     type="text"
                     className="form-control"
                     value={formulario?.Email}
                     name="Email"
                     onChange={handleChange2}
                     style={{ padding: "10px", height: "52px" }}
                     id="exampleFormControlInput4"
                     placeholder={dataInfo[favoriteLenguaje][3]?.ndaEmail}
                     required
                   />
                 </div>
                )}

                <div className="select-contact-list">
                  <span className="nda-or mb-2">{dataInfo[favoriteLenguaje][3]?.or}</span>
                  {/* <span
                    className="nda-contact-list-text"
                    onClick={() => {
                      setContactStep(2);
                    }}
                  >
                    Select Contact from your <br /> Contact List
                  </span> */}
                </div>

                <div className="buttons-type-container">
                  {ndaType === "phone" && (
                     <div className="button-type" type="button">
                     <img src={contact} alt="" />
                     {dataInfo[favoriteLenguaje][3]?.contactList}
                   </div>
                  )}

                  <div className="button-type" type="button" onClick={()=>{
                    if(ndaType === "phone"){
                      setNdaType("email")
                      setFormulario({ ...formulario, Email: ""});
                    } else{
                      setNdaType("phone")
                      setPhoneNumber("")
                      setPhoneNumber2("")
                    }
                  }}>
                  <img src={ndaType === "phone" ? letter : phone} alt="" />
                  {ndaType === "phone" ? `${dataInfo[favoriteLenguaje][3]?.sendViaEmail}` : `${dataInfo[favoriteLenguaje][3]?.sendViaPhone}`}
                  </div>
                </div>

                <div className="nda-profile-blueline"></div>
                <div
                  className="button-nda-send"
                  onClick={() => {
                    console.log("formulario", formulario);
                    console.log("phoneNumber1", phoneNumber);
                    console.log("phoneNumber2", phoneNumber2);
                    if (!formulario.Username) {
                      toast.warning(dataInfo[favoriteLenguaje][5]?.warningUsername);
                    } else if (!phoneNumber2 && !formulario.Email) {
                      toast.warning(dataInfo[favoriteLenguaje][5]?.warningPhoneOrEmail);
                    } else {
                      checkInfo();
                    }
                  }}
                >
                  <div>
                    <img src={paperplane} alt="paperplane" />
                  </div>
                  <div className="text-button-nda">{dataInfo[favoriteLenguaje][3]?.send}</div>
                </div>
              </div>
            </>
          ) : null}

          {contactStep === 2 ? (
            <div className="nda-contact-container">
              <div className="nda-profile-title"> {dataInfo[favoriteLenguaje][3]?.contactList}</div>
              <div className="nda-profile-greenline"></div>
              <div
                className="mb-4"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <label
                  htmlFor="exampleFormControlInput10"
                  className="form-label label-title"
                >
                  {dataInfo[favoriteLenguaje][2]?.search}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={formulario.Email}
                  name="Email"
                  onChange={handleChange2}
                  style={{ padding: "10px", height: "52px" }}
                  id="exampleFormControlInput10"
                  placeholder={`🔎 Search`}
                  required
                />
              </div>
              <div className="nda-contact-list-profile">
                {data.map((contact, index) => (
                  <div
                    className="nda-contact-image-name"
                    key={index}
                    onClick={() => {
                      handleCheck(index, contact);
                    }}
                  >
                    <div className="check-container">
                      {selectedContact === index ? (
                        <img
                          src={checkImage}
                          alt="right-check"
                          style={{
                            width: "30px",
                            height: "30px",
                            marginTop: "6px",
                          }}
                        />
                      ) : (
                        <div className="check-container2"></div>
                      )}
                    </div>
                    <div className="image-contact-container">
                      <img src={contact.photo} alt="contact-profile" />
                    </div>
                    <div className="text-contact-container">
                      {contact.first_name} {contact.last_name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          {contactStep === 3 ? (
            <div className="step3-container">
              <div className="nda-profile-title">{dataInfo[favoriteLenguaje][3]?.yourEntity}</div>
              <div className="entity-info-container">
                <div className="entity-info-title">
                  {memberInfo?.AccountInfo.Name}
                </div>
                <div className="entity-info-subtitle">
                  {/* {memberInfo?.EntityTypeInfo.Description} */}
                </div>
                <div className="entity-info-address">
                  {memberInfo?.AddressInfo.Street}
                </div>
                <div className="entity-info-address">
                  {memberInfo?.AddressInfo.City} -{" "}
                  {memberInfo?.AddressInfo.Location}
                </div>
                {/* <div className="entity-info-address">
                {userData?.AddressInfo.Street}
              </div>
              <div className="entity-info-address">
                {userData?.AddressInfo.City} {userData?.AddressInfo.Location}
              </div> */}
              </div>
              <div className="recipient-container">
                <div className="recipient-title">
                  {dataInfo[favoriteLenguaje][3]?.recipient}
                  <img src={pencil} alt="pencil" />
                </div>
                <div className="recipient-contact-info">
                  <div>
                    <img
                      src={contactImg ? contactImg : avatar}
                      alt=""
                      width="81px"
                      height="81px"
                      className="co-img"
                    />
                  </div>
                  <div className="contact-info-data">
                    <span className="fullname-contact">
                      {checkNumberInfo?.FirstName
                        ? `${checkNumberInfo?.FirstName} ${checkNumberInfo?.LastName}`
                        : `${formulario.Username}`}
                    </span>
                    <span className="phone-contact">
                      {" "}
                      {checkNumberInfo?.Email && !phoneNumber2
                        ? `${checkNumberInfo?.Email}`
                        : `${formulario.Email}`}
                      
                      {checkNumberInfo?.PhoneNo && !formulario.Email
                        ? `${checkNumberInfo?.PhoneCountryCode} ${checkNumberInfo?.PhoneNo}`
                        : `${phoneNumber}`}
                    </span>
                  </div>
                  <div className="contact-img-container">
                    <img
                      src={contactImg2}
                      alt="contact-img"
                      className="con-img"
                    />
                  </div>
                  <div className="nda-profile-blueline mt-3"></div>
                  <div className="container-recipient-buttons">
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{ height: "50px" }}
                      onClick={() => {
                        sendDocument();
                      }}
                    >
                    {dataInfo[favoriteLenguaje][3]?.send}
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      style={{ height: "50px" }}
                      onClick={() => {
                        dispatch(setAcitveNav("docs"))
                        navigate("/docs");
                      }}
                    >
                      {dataInfo[favoriteLenguaje][3]?.cancelButton}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="nda-profile-footer">© Copyright {year} NDABee.</div>
      </div>
      <ToastContainer theme="colored" position="top-right" autoClose={2000} />
    </>
  );
};

export default NDA;
