import { Handler } from "../Handler";
import { SERVICE_BEE } from "../../Constants/Auth";

export class Base4 extends Handler {
    Model = '';
    Params = {};
    ResponseBody;

    constructor() {
        super(SERVICE_BEE, '', null);
    }

    async load() {
        this.Request = {
            Modal: this.Model,
            Params: this.Params
        };
        await this.PostBlob2();
        this.ResponseBody = this.Response.data;
    }

}
