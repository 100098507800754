import React, { useEffect, useState } from 'react'
import Menu from '../../Components/Menu/Menu'
import { useNavigate } from 'react-router-dom';
import './Privacy.css'
import { useSelector } from 'react-redux';
import { dataInfo } from "../../pruebasJson/data";

const Privacy = () => {
  const user = localStorage.getItem('User')
  const year = new Date().getFullYear();
  const navigate = useNavigate();

  const[align,setAlign] = useState("left");
  const [size,setSize] = useState(20);
  const [lenguaje, setLenguaje] = useState("en");
  const { favoriteLenguaje } = useSelector((state) => state.user);

  useEffect(() => {
    const userLanguage = navigator.language || navigator.userLanguage;
    const languageCode = userLanguage.startsWith('es') ? 'es' : 'en';
    setLenguaje(languageCode);

  }, []);
  return (
    <div>
      {!user && ( <Menu/> )}
      <div className='term-fo-service-principal-container'>
        <div className='terms-container1'>
        <div style={{display:"flex", justifyContent:"space-between"}}>
          <div
            className="btn-group"
            role="group"
            aria-label="Basic outlined example"
          >
            <button type="button" className="btn btn-outline-primary" onClick={()=>{setAlign("left")}}>
            {dataInfo[favoriteLenguaje][4]?.left}
            </button>
            <button type="button" className="btn btn-outline-primary" onClick={()=>{setAlign("justify")}}>
            {dataInfo[favoriteLenguaje][4]?.justify}
            </button>
            <button type="button" className="btn btn-outline-primary" onClick={()=>{setAlign("right")}}>
              {dataInfo[favoriteLenguaje][4]?.right}
            </button>
          </div>
          <div
            className="btn-group"
            role="group"
            aria-label="Basic outlined example"
          >
            <button type="button" className="btn btn-outline-primary" onClick={() => setSize(prevSize => prevSize + 1)}>
              +
            </button>
            <div  className="btn btn-outline-primary">
              {size === "" ? "20" : size}
            </div>
            <button type="button" className="btn btn-outline-primary" onClick={() => setSize(prevSize => prevSize - 1)}>
              -
            </button>
          </div>
        </div>
          <h1 className='terms-title' style={{fontSize: `${size * 1.5}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][7]?.privacyTitle}</h1>
          <div className='green-line-terms'>
            <div className='green-line-term'></div>
          </div>
          <h2 className='terms-subtitle mt-5' style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][7]?.privacyEffectiveDate}</h2>
          <p className='terms-text' style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>
         {dataInfo[favoriteLenguaje][7]?.privacyText1} <a href="https://www.confidentialbee.com/" target='_blank' rel="noreferrer" style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>www.confidentialbee.com</a> {dataInfo[favoriteLenguaje][7]?.privacyText2}</p>
          <ol>
            <li>
            <h3 className='terms-h3' style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][7]?.privacyInformationCollection}</h3>
            <p className='terms-text' style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][7]?.privacyInformationCollectionText}</p>
            </li>
            <li  style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>
            <h3 className='terms-h3' style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][7]?.privacyHowWeUseCollectedInformation}</h3>
            <p className='terms-text' style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][7]?.privacyHowWeUseCollectedInformationText}</p>
            <ul className='ul-list' style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>
              <li style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][7]?.privacyHowWeUseCollectedInformationText2}</li>
              <li style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][7]?.privacyHowWeUseCollectedInformationText3}</li>
              <li style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][7]?.privacyHowWeUseCollectedInformationText4}</li>
              <li style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][7]?.privacyHowWeUseCollectedInformationText5}</li>
              <li style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][7]?.privacyHowWeUseCollectedInformationText6}</li>
            </ul>
            </li>
            <li>
            <h3 className='terms-h3' style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][7]?.privacyInformationProtection}</h3>
            <p className='terms-text' style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][7]?.privacyInformationProtectionText}</p>
            </li>
            <li>
            <h3 className='terms-h3' style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][7]?.privacySharingYourPersonalInformation}</h3>
            <p className='terms-text' style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][7]?.privacySharingYourPersonalInformationText}</p>
            </li>
            <li>
            <h3 className='terms-h3' style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][7]?.privacyThirdPartyWebsites}</h3>
            <p className='terms-text' style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][7]?.privacyThirdPartyWebsitesText}</p>
            </li>
            <li>
            <h3 className='terms-h3' style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][7]?.privacyChangesToThisPrivacyPolicy}</h3>
            <p className='terms-text' style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][7]?.privacyChangesToThisPrivacyPolicyText}</p>
            </li>
            <li>
            <h3 className='terms-h3' style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][7]?.privacyYourAcceptanceOfTheseTerms}</h3>
            <p className='terms-text' style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][7]?.privacyYourAcceptanceOfTheseTermsText}</p>
            </li>
          </ol>
          <h2 className='terms-subtitle2 mt-5' style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][7]?.termsClosing}</h2>
          <p className='terms-text' style={{textAlign: align, fontSize: `${size}px`, lineHeight: `${size * 1.5}px`}}>{dataInfo[favoriteLenguaje][7]?.termsClosingText}</p>
        </div>
        <div className='before-footer'>Terms of Service | Privacy Policy</div>
        <div className='footer-terms'>© Copyright {year} NDABee.</div>
      </div>
    </div>
  )
}

export default Privacy