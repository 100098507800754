import React, { useRef, useState } from "react";
import "./ForgotPassword.css";
import forgotLogo from "../../images/forgot-logo.png";
import device from "../../images/device.png";
import device2 from "../../images/Device -wait.png";
import device3 from "../../images/Device -right.png";
import device4 from "../../images/Device -password.png";
import device5 from "../../images/Device -wrong.png";
import padlock from "../../images/padlock.png";
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import InfoModal from "../../Components/InfoModal/InfoModal";
import notification from "../../images/notification-logo.png";
import { HEADERS } from "../../Constants/Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SendOtp } from "../../Apis/ForgotPassworf/Models/SendOtp";
import { ValidateOtp } from "../../Apis/ForgotPassworf/Models/ValidateOtp";
import { ResetPassword } from "../../Apis/ForgotPassworf/Models/ResetPassword";
import { dataInfo } from "../../pruebasJson/data";
import { useSelector } from "react-redux";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumber2, setPhoneNumber2] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [dialCode2, setDialCode2] = useState("");
  const [phoneNumberString, setPhoneNumberString] = useState("");
  const [valid, setValid] = useState(true);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpVal, setOtpVal] = useState([]);
  const verification = ["1", "2", "3", "4", "5", "6"];
  const [userOtpInfo, setUserOtpInfo] = useState();
  const [changePassword, setChangePassword] = useState({
    password: "",
    confirmPassword: "",
  });
  const year = new Date().getFullYear();
  const textBase = useRef(null);
  const { favoriteLenguaje } = useSelector((state) => state.user);

  const handleChangePassword = (e) => {
    const { name, value } = e.target;
    setChangePassword({ ...changePassword, [name]: value });
  };

  const focusNext = (e) => {
    if (textBase && textBase.current && e.target.value) {
      const childCount = textBase.current.childElementCount;
      const currentIndex = [...e.target.parentNode.children].indexOf(e.target);
      if (currentIndex !== childCount - 1) {
        e.target.nextSibling.focus();
      }
      const values = [];
      textBase.current.childNodes.forEach((child) => {
        values.push(child.value);
      });
      if (values.length === 6) {
        setOtpVal(values);
      }
    }
  };

  const handleKeyUp = (e) => {
    if (e && e.key === "Backspace" && e.target && e.target.previousSibling) {
      e.target.previousSibling.focus();
    }
  };

  const handlePaste = (e) => {
    if (e.clipboardData.getData("text")) {
      const pasteVal = e.clipboardData.getData("text");
      if (pasteVal.length === 6) {
        if (textBase && textBase.current) {
          const vals = pasteVal.split("");
          setOtpVal(vals);
          textBase.current.childNodes.forEach((child, k) => {
            child.value = vals[k];
          });
        }
      }
    }
  };

  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);

  const handleChange = (value, country, e, formattedValue) => {
    setPhoneNumber2(value);
    setPhoneNumber(formattedValue);
    setDialCode("+" + country.dialCode);
    setDialCode2(country.dialCode);
    setValid(validatePhoneNumber(formattedValue));
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;

    return phoneNumberPattern.test(phoneNumber);
  };

  const sendOtp = async (number, dial) => {
    const arrayNumber = number.split("");
    const dialArray = dial.split("");
    const cleanArray = [];
    let cleanString = "";
    console.log("Array number", arrayNumber);
    console.log("Dial array", dialArray);

    for (let i = 0; i < arrayNumber.length; i++) {
      if (dialArray[i] !== "") {
        if (dialArray[i] !== arrayNumber[i]) {
          cleanArray.push(arrayNumber[i]);
          cleanString = cleanString + arrayNumber[i];
        }
      } else {
        cleanArray.push(arrayNumber[i]);
        cleanString = cleanString + arrayNumber[i];
      }
    }
    setPhoneNumberString(cleanString);
    console.log("Clean Array =>>", cleanArray);
    console.log("String number", cleanString);

    try {
      const classRef = new SendOtp();
      await classRef.List({
        Email: null,
        PhoneCountryCode: dialCode,
        PhoneNo: cleanString,
      })
      let result = classRef.ResponseBody;
      if (result.SentOtpPhone) {
        setStep(2);
        setShow(true);
      } else {
        toast.warning(dataInfo[favoriteLenguaje][5]?.warningUser);
      }
    } catch (error) {
      console.log("Error", error)
    }

    // const myHeaders = new Headers();
    // myHeaders.append("AppName", "DbBee");
    // myHeaders.append("Content-Type", "application/json");
    // myHeaders.append(
    //   "Authorization",
    //   "Bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9"
    // );

    // const raw = JSON.stringify({
     
    // });

    // const requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow",
    // };

    // fetch(
    //   "https://api.softsmartinc.com/Services/v2/Bee/Members/ForgotPassword/SendOtp",
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((data) => {
    //     if (data.SentOtpPhone) {
    //       setStep(2);
    //       setShow(true);
    //     } else {
    //       toast.warning("User not found");
    //     }
    //   });
  };

  const verifyOtp = async () => {
    const otpString = otpVal.join("");

    try {
      const classRef = new ValidateOtp();
      await classRef.List({
        Email: null,
        PhoneCountryCode: dialCode,
        PhoneNo: phoneNumberString,
        Otp: otpString,
      })
      let result = classRef.ResponseBody;
      if (result.OtpValid === "VALID") {
        setStep(3);
        setUserOtpInfo(result);
      } else {
        setStep(5);
      }
    } catch (error) {
      console.log("Error", error)
    }

    // const myHeaders = new Headers();
    // myHeaders.append("AppName", "DbBee");
    // myHeaders.append("Content-Type", "application/json");
    // myHeaders.append(
    //   "Authorization",
    //   "Bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9"
    // );

    // const raw = JSON.stringify({
    //   Email: null,
    //   PhoneCountryCode: dialCode,
    //   PhoneNo: phoneNumberString,
    //   Otp: otpString,
    // });

    // const requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow",
    // };

    // fetch(
    //   "https://api.softsmartinc.com/Services/v2/Bee/Members/ForgotPassword/Validate",
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((result) => {
    //     if (result.OtpValid === "VALID") {
    //       setStep(3);
    //       setUserOtpInfo(result);
    //     } else {
    //       setStep(5);
    //     }
    //   })
    //   .catch((error) => console.error(error));
  };

  const resetPassword = async () => {
    if (
      changePassword.password === "" ||
      changePassword.confirmPassword === ""
    ) {
      return toast.warning(dataInfo[favoriteLenguaje][5]?.warningTwoFields);
    }

    if (changePassword.password !== changePassword.confirmPassword) {
      return toast.warning(dataInfo[favoriteLenguaje][5]?.warningConfirmPassword2);
    }

    const otpString = otpVal.join("");

    try {
      const classRef = new ResetPassword();
      await classRef.List({
        MembersId: userOtpInfo.Id,
        NewPassword: changePassword.password,
        Otp: otpString,
      })
      let result = classRef.ResponseBody;
      if (result.MemberInfo) {
        toast.success(dataInfo[favoriteLenguaje][5]?.successUpdatedPassword);
        setTimeout(() => {
          login();
        }, 1000);
      } else {
        toast.warning(result.message);
      }
    } catch (error) {
      console.log("Error", error)
    }

    // const myHeaders = new Headers();
    // myHeaders.append("AppName", "DbBee");
    // myHeaders.append("Content-Type", "application/json");
    // myHeaders.append(
    //   "Authorization",
    //   "Bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9"
    // );

    // const raw = JSON.stringify({
    //   MembersId: userOtpInfo.Id,
    //   NewPassword: changePassword.password,
    //   Otp: otpString,
    // });

    // const requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow",
    // };

    // fetch(
    //   "https://api.softsmartinc.com/Services/v2/Bee/Members/ForgotPassword/ResetPassword",
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((result) => {
    //     if (result.MemberInfo) {
    //       toast.success("Password changed successfully");
    //       setTimeout(() => {
    //         login();
    //       }, 1000);
    //     } else {
    //       toast.warning(result.message);
    //     }
    //   })
    //   .catch((error) => console.error(error));
  };

  const login = () => {
    navigate("/login");
  };
  return (
    <div className="forgotPasswword-principal-container">
      <div className="forgot-main w-100">
        <div className="row forgot-main-container">
          <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-6 col-sm-12 col-xs-12">
            <div className="forgot-img-container">
              <img
                src={forgotLogo}
                alt="confidential-bee"
                onClick={login}
                style={{ cursor: "pointer" }}
              />
            </div>
            {step === 1 ? (
              <div className="forgot-container">
                <div className="forgot-device">
                  <img src={device} alt="device" />
                </div>
                <div className="forgot-container-title">{dataInfo[favoriteLenguaje][3]?.forgotPassword}</div>
                <div className="forgot-container-content">
                {dataInfo[favoriteLenguaje][3]?.SendVerification}
                </div>
                <div className="forgot-green-line"></div>
                <div
                  className="mb-3 mt-3"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "80%",
                  }}
                >
                  <label
                    htmlFor="forgot-phone-number"
                    className="form-label label-title"
                  >
                    {dataInfo[favoriteLenguaje][2]?.phoneNumber}
                  </label>
                  <PhoneInput
                    country={"us"}
                    value={phoneNumber}
                    onChange={handleChange}
                    inputProps={{
                      style: {
                        height: "52px",
                        width: "100%",
                        border: "1px solid lightgray",
                      },
                      required: true,
                    }}
                  />
                </div>
                <div className="forgot-green-line2"></div>
                <div className="mt-4" style={{ width: "80%" }}>
                  <button
                    className="btn btn-primary w-100 mb-3"
                    style={{ height: "50px" }}
                    onClick={() => {
                      if (phoneNumber) {
                        sendOtp(phoneNumber2, dialCode2);
                        console.log("phone number", phoneNumber);
                        console.log("phone number2", phoneNumber2);
                        console.log("dial code", dialCode);
                      } else {
                        toast.warning("You must enter your phone number");
                      }
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            ) : null}

            {step === 2 ? (
              <div className="forgot-container">
                <div className="forgot-device">
                  <img src={device2} alt="device-wait" />
                </div>
                <div className="forgot-container-title">{dataInfo[favoriteLenguaje][3]?.phoneValidationTitle}</div>
                <div className="forgot-container-content">
                  {dataInfo[favoriteLenguaje][3]?.phoneValidationContent}
                </div>
                <div className="forgot-green-line"></div>
                <div
                  className="mb-3 mt-3"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "80%",
                  }}
                >
                  <div className="h-screen flex justify-center items-center space-x-2">
                    <div className="d-flex opt-text-cn align-items-center justify-content-center mt-4">
                      <div className="otp-base d-flex" ref={textBase}>
                        {new Array(6).fill(null).map((input, k) => {
                          return (
                            <input
                              key={k}
                              onPaste={handlePaste}
                              onKeyUp={handleKeyUp}
                              type="text"
                              pattern="\d*"
                              className="form-control otp-txt"
                              maxLength={1}
                              onChange={(e) => focusNext(e)}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="forgot-green-line2"></div>
                <div
                  className="mt-4"
                  style={{
                    width: "80%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <button
                    className="btn btn-primary  mb-3"
                    style={{ height: "50px", width: "120px" }}
                    onClick={() => {
                      if (otpVal) {
                        verifyOtp();
                      } else {
                        console.log("fallo");
                        console.log("otpVal", otpVal);
                        console.log("verification", verification);
                      }
                    }}
                  >
                    {dataInfo[favoriteLenguaje][3]?.validationButton}
                  </button>

                  <div
                    className="mb-3 re-send-button"
                    style={{ height: "50px", width: "120px" }}
                    onClick={() => {
                      sendOtp(phoneNumber2, dialCode2);
                    }}
                  >
                    {dataInfo[favoriteLenguaje][3]?.validationButton2}
                  </div>
                </div>
              </div>
            ) : null}

            {step === 3 ? (
              <div className="forgot-container">
                <div className="forgot-device">
                  <img src={device3} alt="device-right" />
                </div>
                <div className="forgot-container-title">
                  {dataInfo[favoriteLenguaje][3]?.validationTitle3} <br /> 
                  {dataInfo[favoriteLenguaje][3]?.validationTitle4}
                </div>
                <div className="forgot-container-content"></div>
                <div className="forgot-green-line"></div>

                <div className="" style={{ width: "80%", marginTop: "100px" }}>
                  <button
                    className="btn btn-primary w-100 mb-3"
                    style={{ height: "50px" }}
                    onClick={() => {
                      setStep(4);
                      setShow2(true);
                    }}
                  >
                    {dataInfo[favoriteLenguaje][2]?.continue}
                  </button>
                </div>
              </div>
            ) : null}

            {step === 4 ? (
              <div className="forgot-container">
                <div className="forgot-device">
                  <img src={device4} alt="device-password" />
                </div>
                <div className="forgot-container-title">{dataInfo[favoriteLenguaje][3]?.forgotPassword}</div>
                <div className="forgot-container-content">
                  {dataInfo[favoriteLenguaje][3]?.newPassword}
                </div>
                <div className="forgot-green-line"></div>
                <div
                  className="mb-3 mt-3"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "80%",
                  }}
                >
                  <div
                    className="mb-4"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <label
                      htmlFor="new-password"
                      className="form-label label-title"
                    >
                      {dataInfo[favoriteLenguaje][3]?.newPassword2}
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      style={{ padding: "10px", height: "52px" }}
                      id="new-password"
                      placeholder={dataInfo[favoriteLenguaje][3]?.newPassword2}
                      autoComplete="off"
                      name="password"
                      onChange={handleChangePassword}
                    />
                  </div>

                  <div
                    className="mb-4"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <label
                      htmlFor="confirm-password"
                      className="form-label label-title"
                    >
                      {dataInfo[favoriteLenguaje][2]?.confirmPassword}
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      style={{ padding: "10px", height: "52px" }}
                      id="confirm-password"
                      placeholder={dataInfo[favoriteLenguaje][2]?.confirmPassword}
                      autoComplete="off"
                      name="confirmPassword"
                      onChange={handleChangePassword}
                    />
                  </div>
                </div>
                <div className="forgot-green-line2"></div>
                <div className="mt-4" style={{ width: "80%" }}>
                  <button
                    className="btn btn-primary w-100 mb-3"
                    style={{ height: "50px" }}
                    onClick={() => {
                      resetPassword();
                    }}
                  >
                    {dataInfo[favoriteLenguaje][2]?.submit}
                  </button>
                </div>
              </div>
            ) : null}

            {step === 5 ? (
              <div className="forgot-container">
                <div className="forgot-device">
                  <img src={device5} alt="device-wrong" />
                </div>
                <div className="forgot-container-title">{dataInfo[favoriteLenguaje][3]?.validationWarning4}</div>
                <div className="forgot-container-content">
                {dataInfo[favoriteLenguaje][3]?.validationWarning2}
                <br />
                  {dataInfo[favoriteLenguaje][3]?.validationWarning3}
                </div>
                <div className="forgot-green-line"></div>

                <div className="" style={{ width: "80%", marginTop: "100px" }}>
                  <button
                    className="btn btn-primary w-100 mb-3"
                    style={{ height: "50px" }}
                    onClick={() => {
                      setStep(1);
                    }}
                  >
                    {dataInfo[favoriteLenguaje][3]?.tryAgain}
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="forgot-footer">
        <span className="forgot-footer-title">© Copyright {year} NDABee.</span>
      </div>

      <InfoModal
        notificationImage={notification}
        notificationTitle={dataInfo[favoriteLenguaje][3]?.notification}
        show={show}
        handleClose={handleClose}
        notificationMessage={
          <div>
            {dataInfo[favoriteLenguaje][3]?.forgotMessage}
            <br />
              {dataInfo[favoriteLenguaje][3]?.forgotMessage2}
          </div>
        }
      />

      <InfoModal
        notificationImage={padlock}
        notificationTitle={dataInfo[favoriteLenguaje][3]?.notification}
        show={show2}
        handleClose={handleClose2}
        notificationMessage={
          <div>
            {dataInfo[favoriteLenguaje][3]?.passwordMessage1}
            <br />
              {dataInfo[favoriteLenguaje][3]?.passwordMessage2}
            <br /> {dataInfo[favoriteLenguaje][3]?.passwordMessage3}
          </div>
        }
      />
      <ToastContainer theme="colored" position="top-right" autoClose={2000} />
    </div>
  );
};

export default ForgotPassword;
