import { Base5 } from '../Base5';

export class DeleteAccountInfo extends Base5 {
    Items = [];

    constructor() {
        super();
        this.Controller = 'Members/Delete';
    }

    async List(body) {
        this.Params = body
        await super.load();
        this.Items = this.ResponseBody;
    }
}
