import React, { useState } from "react";
import logo from "../../images/logo2.png";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import LoginCard from "../../Components/LoginCard/LoginCard";
import credentilasLogo from "../../images/message.png";
import facebook from "../../images/facebook.png";
import google from "../../images/google.png";
import linkedin from "../../images/linkeding.png";
import apple from "../../images/apple.png";
import facebookLogo from "../../images/facebook-logo.png";
import googleLogo from "../../images/google-logo.png";
import linkedinLogo from "../../images/linkedin-logo.png";
import appleLogo from "../../images/apple-logo.png";
import SignupCard from "../../Components/SignupCard/SignupCard";
import { useSelector } from "react-redux";
import { dataInfo } from "../../pruebasJson/data";

const Login = () => {
  const [option, setOption] = useState("login");
  const [loginStage, setLoginStage] = useState("credentials");
  const [signupStage, setSignupStage] = useState("credentials");


  const navigate = useNavigate();
  const year = new Date().getFullYear();
  const { favoriteLenguaje } = useSelector((state) => state.user);

  const homepage = () => {
    navigate("/");
  };

  const forgotPaswword = () => {
    navigate("/forgot-password");
  };

  return (
    <>
      <div className="login-principal-container">
        <div className="login-header">
          <div>
            <img src={logo} alt="logo-confidential-bee" onClick={homepage} />
          </div>
        </div>
        <div className="login-main">
          <div className="login-main-title">
            <div className="login-main-title-container">
              <h1>{dataInfo[favoriteLenguaje][3]?.welcomeTitle}</h1>
              <div className="login-white-line">
                <div></div>
              </div>
              <ul className="mt-4 ul-login">
                <li>
                {dataInfo[favoriteLenguaje][3]?.text1}
                </li>
                <li>
                {dataInfo[favoriteLenguaje][3]?.text2}
                </li>
              </ul>
              <div className="container-terms-and-privacy">
              <div
                onClick={() => {
                  navigate("/terms-and-conditions");
                }}
                style={{ cursor: "pointer" }}
              >
                {dataInfo[favoriteLenguaje][3]?.terms}
              </div>
              <div
                onClick={() => {
                  navigate("/privacy-policy");
                }}
                style={{ cursor: "pointer" }}
              >
                {dataInfo[favoriteLenguaje][3]?.privacy}
              </div>
            </div>
            </div>
          </div>
          <div className="login-main-form">
            <div className="login-main-form-container">
              <div className="login-tabs">
                <div
                  className="login-fields"
                  style={{
                    color: `${
                      option === "login" ? "#0572D2" : "rgba(96, 96, 97, 0.25)"
                    }`,
                    borderBottom: `${
                      option === "login"
                        ? "3px solid #06B576"
                        : "1px solid transparent"
                    }`,
                  }}
                  onClick={() => {
                    setOption("login");
                    setLoginStage("credentials");
                  }}
                >
                  {dataInfo[favoriteLenguaje][3]?.login}
                </div>
                <div className="login-fields">/</div>
                <div
                  className="login-fields"
                  style={{
                    color: `${
                      option === "signup" ? "#0572D2" : "rgba(96, 96, 97, 0.25)"
                    }`,
                    borderBottom: `${
                      option === "signup"
                        ? "3px solid #06B576"
                        : "1px solid transparent"
                    }`,
                  }}
                  onClick={() => {
                    setOption("signup");
                  }}
                >
                 {dataInfo[favoriteLenguaje][3]?.signup}
                </div>
              </div>
              <div className="card-login-setup">
                <div className="card-container">
                  {option === "login" && loginStage === "credentials" ? (
                    <LoginCard
                      image={credentilasLogo}
                      loginMode={dataInfo[favoriteLenguaje][2]?.email}
                      loginStage={loginStage}
                      onClick={() => {
                        forgotPaswword();
                      }}
                    />
                  ) : null}
                  {option === "login" && loginStage === "facebook" ? (
                    <LoginCard
                      image={facebookLogo}
                      loginMode="facebook account"
                      loginStage={loginStage}
                    />
                  ) : null}
                  {option === "login" && loginStage === "google" ? (
                    <LoginCard
                      image={googleLogo}
                      loginMode="google account"
                      loginStage={loginStage}
                    />
                  ) : null}
                  {option === "login" && loginStage === "linkedin" ? (
                    <LoginCard
                      image={linkedinLogo}
                      loginMode="linkedin account"
                      loginStage={loginStage}
                    />
                  ) : null}
                  {option === "login" && loginStage === "apple" ? (
                    <LoginCard
                      image={appleLogo}
                      loginMode="apple account"
                      loginStage={loginStage}
                    />
                  ) : null}

                  {option === "signup" && signupStage === "credentials" ? (
                    <SignupCard
                      singupStage="credentials"
                      onClick={() => {
                        setOption("login");
                        setLoginStage("credentials");
                      }}
                    />
                  ) : null}
                </div>
              </div>
              <div className="card-login-media">
                <div className="card-login-media-text">
                  {favoriteLenguaje === "es" ? "o" : "or"} <br /> 
                  {option === "login" ? dataInfo[favoriteLenguaje][3]?.socialLogin : dataInfo[favoriteLenguaje][3]?.socialRegister}
                </div>
                <div className="card-login-media-imgsocial">
                  <div>
                    <img
                      src={credentilasLogo}
                      alt="facebook-login"
                      width="30px"
                      height="31px"
                      onClick={() => {
                        setLoginStage("credentials");
                      }}
                    />
                  </div>
                  <div>
                    <img
                      src={facebook}
                      alt="facebook-login"
                      onClick={() => {
                        setLoginStage("facebook");
                      }}
                    />
                  </div>
                  <div>
                    <img
                      src={google}
                      alt="google-login"
                      onClick={() => {
                        setLoginStage("google");
                      }}
                    />
                  </div>
                  <div>
                    <img
                      src={linkedin}
                      alt="linkedin-login"
                      onClick={() => {
                        setLoginStage("linkedin");
                      }}
                    />
                  </div>
                  <div>
                    <img
                      src={apple}
                      alt="apple-login"
                      onClick={() => {
                        setLoginStage("apple");
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="login-footer">
          <div className="hide-terms">
          <div
            onClick={() => {
              navigate("/terms-and-conditions");
            }}
            style={{ cursor: "pointer" }}
          >
            Terms of Service
          </div>
          <div
            onClick={() => {
              navigate("/privacy-policy");
            }}
            style={{ cursor: "pointer" }}
          >
            Privacy Policy
          </div>
          </div>
          <div className="year-css">© {year} NDABee.</div>
        </div>
      </div>
    </>
  );
};

export default Login;
