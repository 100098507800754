import React from "react";
import logo from "../../images/logo2.png";
import fileNda from "../../images/ndaLock.png";
import "./SendNDA.css";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {Link} from "react-router-dom"
import { setAcitveNav } from "../../redux/features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { dataInfo } from "../../pruebasJson/data";

const SendNDA = () => {
    const navigate = useNavigate();
    const {Id} = useParams();
    const year = new Date().getFullYear();
    const dispatch = useDispatch();
    const { favoriteLenguaje } = useSelector((state) => state.user);
  return (
    <div className="sendNda-principal-container">
      <div className="sendNda-logo-container">
        <img src={logo} alt="confidentialbee-logo" 
        onClick={()=>{
          dispatch(setAcitveNav("home"))
            navigate("/")
        }}
        style={{cursor:"pointer"}}
        />
      </div>
      {/*main part*/}
      <div className="row w-100 send-nda-container">
        <div className="sendNda-title">{dataInfo[favoriteLenguaje][3]?.sendNda}</div>
        <div style={{width:"500px", display:"flex",justifyContent:"center"}}>
        <div className="greenlineNda"></div>
        </div>
        <div className="send-nda-image-container">
          <img src={fileNda} alt="file-nda" />
          <div className="send-nda-select">
            <div className="send-nda-select-title">{dataInfo[favoriteLenguaje][3]?.note}</div>
            <div className="send-nda-select-text">
              {/* {dataInfo[favoriteLenguaje][3]?.textNote} */}
              {dataInfo[favoriteLenguaje][3]?.textNote} <br />
              {dataInfo[favoriteLenguaje][3]?.textNote2}
            </div>
            <div
              className="d-flex justify-content-center"
              style={{ width: "100%" }}
            >
              <button
                type="button"
                className="btn btn-light"
                style={{ width: "80%" }}
                onClick={()=>{
                    navigate(`/nda/${Id}`)
                }}
              >
                {dataInfo[favoriteLenguaje][3]?.agreeButton}
              </button>
            </div>
          </div>
        </div>
        <div className="send-nda-show-page">
              <input
                className="form-check-input me-1"
                type="checkbox"
                value=""
                id="firstCheckbox"
                style={{marginBottom:"27px"}}
              />
              <label className="form-check-label label-input" htmlFor="firstCheckbox">
              {dataInfo[favoriteLenguaje][3]?.checkboxText} <Link to="/terms-and-conditions" target="_blank" style={{color:"white", fontWeight:"600"}}>{dataInfo[favoriteLenguaje][3]?.checkboxText2}</Link><br />
              {dataInfo[favoriteLenguaje][3]?.checkboxText3}
              </label>
        </div>
      </div>

      {/*footer*/}
      <div className="nda-footer">© Copyright {year} NDABee.</div>
    </div>
  );
};

export default SendNDA;
