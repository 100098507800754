import { Base } from '../Base';

export class GetByReferenceCode extends Base {
    Items = [];

    constructor() {
        super();
        this.Model = 'Documents';
        this.Controller="GetByReferenceCode";
    }

    async List(body) {
        this.Params = body
        await super.load();
        this.Items = this.ResponseBody;
    }
}
