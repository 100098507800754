import React, { useState } from "react";
import logo from "../../images/logo.png";
import "./Menu.css";
import { NavLink, useNavigate } from "react-router-dom";
import menu from "../../images/menu.png";
import close from "../../images/close.png";

const Menu = () => {
    const navigate = useNavigate();
  const home = () => {
    navigate("/");
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="menu-principal-container">
      <div className="menu-sub-container">
        <div className="logo-sub-container">
          <img src={logo} alt="logo" onClick={home} style={{cursor:"pointer"}}/>
        </div>
        <div className="menu-second-container">
          <div className="web2-menu">
            <NavLink to="/privacy-policy">PRIVACY POLICY</NavLink>
            <NavLink to="/terms-and-conditions">TERMS AND CONDITIONS</NavLink>
            <NavLink to="/remove-account">DISCLAIMER</NavLink>

            <NavLink to="/wait-list">
              <div className="box-waitlist">WAIT LIST</div>
            </NavLink>

            <NavLink to="/login">
              <div className="box-login">Login</div>
            </NavLink>
          </div>
          <div className="mobile-menu2">
            <div onClick={toggleDrawer}>
              <img src={menu} alt="menu" className="img-menu" />
            </div>
            <div className={`drawer2 ${isOpen ? "open" : ""}`}>
              <div className="header2-menu">
                <div></div>
                <div onClick={toggleDrawer} className="closeBtn2">
                  <img src={close} alt="close" />
                </div>
              </div>
              <div className="drawer2-menu">
                <NavLink to="/privacy-policy">PRIVACY POLICY</NavLink>
                <NavLink to="/terms-and-conditions">
                  TERMS AND CONDITIONS
                </NavLink>
                <NavLink to="/remove-account">DISCLAIMER</NavLink>
                <NavLink to="/wait-list">WAIT LIST</NavLink>
                <NavLink to="/login">LOGIN</NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
