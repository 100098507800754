import React from "react";
import logo from "../../images/logo2.png";
import deleteData from "../../images/deleteIMG.png";
import elipse from "../../images/elipse.png";
import "./DeleteData.css";
import { useNavigate } from 'react-router-dom';
const DeleteData = () => {
    const naviagte = useNavigate();
    const year = new Date().getFullYear();
  return (
    <div className="delete-principal-container">
      <div className="delete-logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="delete-main-container">
        <div className="delete-img-container">
          <img
            src={deleteData}
            alt="delete-data"
            width="350px"
            height="350px"
          />
          <img
            src={elipse}
            alt="delete-data"
            width="360px"
            style={{ marginTop: "-30px" }}
          />
        </div>
        <div className="delete-title">Delete Data?</div>
        <div className="white-line-container">
        <div className="white-line"></div>
        </div>

        <div className="delete-text">Are you sure you want to remove all the data in hour <br /> Alerts, Docs, and all Activity you have performed to this <br />point?  This  will not remove your user account data.</div>

        <div className="white-line-container">
        <div className="white-line"></div>
        </div>

        <div className="buttons-delete-cont">
            <div className="buttons-dt-cont">
            <button type="button" className="btn btn-outline-light"
            onClick={()=>{
                naviagte("/Settings")
            }}
            >No</button>
            <button type="button" className="btn btn-light"
            onClick={()=>{
                naviagte("/Settings")
            }}
            >Yes</button>
            </div>
        </div>
      </div>
      <div className="delete-footer">© Copyright {year} NDABee.</div>
    </div>
  );
};

export default DeleteData;
