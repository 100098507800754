import React, { useEffect, useState } from "react";
import arrow from "../../images/arrow.png";
import Form from "react-bootstrap/Form";
import "./Settings.css";
import { useNavigate } from "react-router-dom";
import { USER_ID } from "../../Constants/User";
import { Get } from "../../Apis/Members/Models/Get";
import InfoModal from "../../Components/InfoModal/InfoModal";
import notification from "../../images/notification-logo.png";
import { SaveNotifications } from "../../Apis/Members/Models/SaveNotifications";
import { useDispatch, useSelector } from "react-redux";
import { setAcitveNav, setFavoriteLenguaje } from "../../redux/features/userSlice";
import { dataInfo } from "../../pruebasJson/data";
import { toast } from "react-toastify";

const Settings = () => {
  const [fconected, setFconected] = useState(false);
  const [gconected, setGconected] = useState(true);
  const [lconected, setLconected] = useState(false);
  const [aconected, setAconected] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [notificationUpdate, setNotificationUpdate] = useState(false)
  const [permissionStatus, setPermissionStatus] = useState(null);
  const [show,setShow] = useState(false);
  const [show2,setShow2] = useState(false);
  const [active, setActive] = useState(null);
  const { favoriteLenguaje } = useSelector((state) => state.user);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentId = USER_ID();
  const year = new Date().getFullYear();

  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  
  const handleClose = () => {
    setShow(false)
  }

  const handleClose2 = () => {
    setShow2(false)
  }


  const getUserInfo = async () => {
    try {
      const classRef = new Get()
      await classRef.List({MembersId: parseInt(currentId)})
      let result = classRef.ResponseBody;
  
      if(result.MemberInfo){
        setUserInfo(result)
        setNotificationUpdate(result.MemberInfo.SettingsNotifications)
      }
    } catch (error) {
      console.log("Error",error)
    }
   
  };

  const notifications = async (boolean) => {
    try {
      const classRef = new SaveNotifications();
      await classRef.List({
        MembersId: parseInt(currentId),
        SettingsFaceId: false,
        SettingsNotifications: boolean,
      })
      let result = classRef.ResponseBody;

      if(result.MemberInfo.SettingsNotifications){
        setShow(true)
      } else{
        setShow2(true)
      }

    } catch (error) {
      console.log("Error",error)
    }
  };

  const updateLanguage = async (language) => {
    try {
      const classRef = new SaveNotifications();
      await classRef.List({
        MembersId: parseInt(currentId),
        SettingsFaceId: false,
        SettingsPreferredLanguageWeb: language,
      })
      let result = classRef.ResponseBody;
      if(result.MemberInfo.SettingsPreferredLanguageWeb){
        dispatch(setFavoriteLenguaje(result.MemberInfo.SettingsPreferredLanguageWeb))
        toast.success("Language updated successfully")
      } 
    } catch (error) {
      console.log("Error",error)
    }
  };

  useEffect(() => {
    dispatch(setAcitveNav("settings"))
    getUserInfo();
  }, []);

  return (
    <>
    <div className="settings-principal-container">
      <div></div>
      <div className="main-settings-container">
        <div className="settings-title">{dataInfo[favoriteLenguaje][3]?.settings}</div>
        <div className="settings-greenline-container mt-2 mb-3">
          <div className="settings-greenline"></div>
        </div>
        <div className="account-settings">
          <div className="settings-option-title">{dataInfo[favoriteLenguaje][3]?.accountSettings}</div>
          <div
            className="card-option-settings"
            onClick={() => {
              navigate("/edit-info");
            }}
          >
            <div>{dataInfo[favoriteLenguaje][3]?.editProfile}</div>
            <img src={arrow} alt="arrow" />
          </div>
          <div
            className="card-option-settings boder-t"
            onClick={() => {
              navigate("/edit-password");
            }}
          >
            <div>{dataInfo[favoriteLenguaje][3]?.editPassword}</div>
            <img src={arrow} alt="arrow" />
          </div>
          <div
            className="card-option-settings boder-t"
            onClick={() => {
              navigate("/edit-avatar");
            }}
          >
            <div>{dataInfo[favoriteLenguaje][3]?.editAvatar}</div>
            <img src={arrow} alt="arrow" />
          </div>
          <div
            className="card-option-settings boder-t"
            onClick={() => {
              navigate(`/edit-entity`);
            }}
          >
            <div>{dataInfo[favoriteLenguaje][3]?.setupEntity}</div>
            <img src={arrow} alt="arrow" />
          </div>
        </div>
        <div className="settings-greenline-container mt- mb-1">
          <div
            className="settings-greenline"
            style={{ width: "80%", height: "1px" }}
          ></div>
        </div>
        <div className="preferences-settings ">
          <div className="settings-option-title">{dataInfo[favoriteLenguaje][3]?.preferences}</div>
          <div className="card-option-settings">
            <div>{dataInfo[favoriteLenguaje][3]?.notifications}</div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                checked={notificationUpdate}
                onChange={()=>{
                  if(!notificationUpdate){
                    setNotificationUpdate(true)
                    notifications(true)
                  } else{
                    setNotificationUpdate(false)
                    notifications(false)
                  }
                }}
              />
            </div>
          </div>
          <div
            className="card-option-settings boder-t"
            onClick={() => {
             if(active === null){
              setActive(true)
             } else {
              setActive(null)
             }
            }}
          >
            <div>{dataInfo[favoriteLenguaje][3]?.selectLenguaje}</div>
            <img src={arrow} alt="arrow" className={`${active === null ? "rotate-0" : "rotate-90"}`} />
          </div>

              <div
              className={`card-option-settings boder-t ${active === null ? "show-card" : ""}`}
              style={{backgroundColor:"#f1f1f1"}}
              onClick={() => {
               if(active === null){
                setActive(true)
               } else {
                updateLanguage("es")
                setActive(null)
               }
              }}
            >
              <div>Spanish</div>
             
            </div>
            <div
              className={`card-option-settings boder-t ${active === null ? "show-card" : ""}`}
              onClick={() => {
               if(active === null){
                setActive(true)
               } else {
                updateLanguage("en")
                setActive(null)
               }
              }}
            >
              <div>English</div>
             
            </div>
          {/* <div className="card-option-settings boder-t">
            <div>Face ID</div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault2"
                disabled
              />
            </div>
          </div> */}
        </div>
        <div className="settings-greenline-container mt-2">
          <div
            className="settings-greenline"
            style={{ width: "80%", height: "1px" }}
          ></div>
        </div>
        <div className="socialmedia-settings ">
          <div className="settings-option-title">{dataInfo[favoriteLenguaje][3]?.socialmedia}</div>
          <div className="card-option-settings">
            <div>{dataInfo[favoriteLenguaje][3]?.facebookAccount}</div>
            <div className="socialmedia-container">
              <span
                className="socialmedia-text"
                style={{ color: `${fconected ? "color: #06B576" : "red"}` }}
              >
                {fconected ? "Connected" : "Not Connected"}
              </span>
              <img src={arrow} alt="arrow" />
            </div>
          </div>
          <div className="card-option-settings boder-t">
            <div>{dataInfo[favoriteLenguaje][3]?.googleAccount}</div>
            <div className="socialmedia-container">
              <span
                className="socialmedia-text"
                style={{ color: `${fconected ? "color: #06B576" : "red"}` }}
              >
                {fconected ? "Connected" : "Not Connected"}
              </span>
              <img src={arrow} alt="arrow" />
            </div>
          </div>
          <div className="card-option-settings boder-t">
            <div>{dataInfo[favoriteLenguaje][3]?.linkedinAccount}</div>
            <div className="socialmedia-container">
              <span
                className="socialmedia-text"
                style={{ color: `${fconected ? "color: #06B576" : "red"}` }}
              >
                {fconected ? "Connected" : "Not Connected"}
              </span>
              <img src={arrow} alt="arrow" />
            </div>
          </div>
          <div className="card-option-settings boder-t">
            <div>{dataInfo[favoriteLenguaje][3]?.appleAccount}</div>
            <div className="socialmedia-container">
              <span
                className="socialmedia-text"
                style={{ color: `${fconected ? "color: #06B576" : "red"}` }}
              >
                {fconected ? "Connected" : "Not Connected"}
              </span>
              <img src={arrow} alt="arrow" />
            </div>
          </div>
        </div>
        <div className="settings-greenline-container mt-2 ">
          <div
            className="settings-greenline"
            style={{ width: "80%", height: "1px" }}
          ></div>
        </div>
        <div className="desactive-settings ">
          <div className="settings-option-title">{dataInfo[favoriteLenguaje][3]?.desactive}</div>
          {/* <div
            className="card-option-settings"
            onClick={() => {
              navigate("/delete-data");
            }}
          >
            <div>Delete Data</div>
            <img src={arrow} alt="arrow" />
          </div> */}
          <div
            className="card-option-settings boder-t"
            onClick={() => {
              navigate("/delete-account");
            }}
          >
            <div>{dataInfo[favoriteLenguaje][3]?.deleteAccount}</div>
            <img src={arrow} alt="arrow" />
          </div>
        </div>
        <div className="settings-greenline-container mt-2">
          <div
            className="settings-greenline"
            style={{ width: "80%", height: "1px" }}
          ></div>
        </div>
        <div className="logout-settings mt-2 ">
          <div
            className="card-option-settings"
            onClick={() => {
              logout();
            }}
          >
            <div>{dataInfo[favoriteLenguaje][3]?.logout}</div>
            <img src={arrow} alt="arrow" />
          </div>
        </div>
      </div>
      <div className="footer-settings">© Copyright {year} NDABee.</div>
    </div>
    <InfoModal
      notificationImage={notification}
      notificationTitle="Notification"
      show={show}
      handleClose={handleClose}
      notificationMessage={
        <div>
          {dataInfo[favoriteLenguaje][3]?.settingsModal1}
          <br />
          {dataInfo[favoriteLenguaje][3]?.settingsModal2}
        </div>
      }
    />

    <InfoModal
      notificationImage={notification}
      notificationTitle="Notification"
      show={show2}
      handleClose={handleClose2}
      notificationMessage={
        <div>
         {dataInfo[favoriteLenguaje][3]?.settingsModal3}
          <br />
          {dataInfo[favoriteLenguaje][3]?.settingsModal4}
        </div>
      }
    />
    </>
  );
};

export default Settings;
