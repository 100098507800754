import axios from "axios";
import { BASE_URL, HEADERS, HEADERS_FORM_DATA } from "../Constants/Auth";

export class Handler {
  ServiceName = "";
  Controller = "";
  Request = {};
  Response;
  Action = "";
  Output = "";

  constructor(serviceName, controller, request) {
    this.ServiceName = serviceName;
    this.Controller = controller;
    this.Request = request;
  }

  // async Post() {
  //   var url = `${BASE_URL}${this.ServiceName}/${this.Request.Modal}/${this.Controller}`;
  //   await axios
  //     .post(url, this.Request.Params, { headers: HEADERS() })
  //     .then((response) => {
  //       this.Response = response;
  //     });
  // }

  async Post() {
    var url = `${BASE_URL}${this.ServiceName}/${this.Request.Modal}/${this.Controller}`;
    return axios
      .post(url, this.Request.Params, { headers: HEADERS() })
      .then((response) => {
        this.Response = response;
      })
      .catch((error) => {
        // console.log("Error",error);
        this.Response = error.response;
      });
  }

  async Patch() {
    var url = `${BASE_URL}${this.ServiceName}/${this.Controller}`;
    await axios
      .patch(url, this.Request.Params, { headers: HEADERS() })
      .then((response) => {
        this.Response = response;
      });
  }

  async Get() {
    var url = `${BASE_URL}${this.ServiceName}/${this.Controller}`;
    await axios.get(url, { headers: HEADERS() }).then((response) => {
      this.Response = response;
    });
  }

  async Put() {
    var url = `${BASE_URL}${this.ServiceName}/${this.Controller}`;
    await axios
      .put(url, this.Request, { headers: HEADERS() })
      .then((response) => {
        this.Response = response;
      });
  }

  async Delete() {
    var url = `${BASE_URL}${this.ServiceName}/${this.Controller}`;
    await axios
      .delete(url, { headers: HEADERS(), data: this.Request.Params })
      .then((response) => {
        this.Response = response;
      });
  }

  async PostBlob() {
    // var url = `${BASE_URL}${this.ServiceName}/${this.Controller}`;
    // await axios.post(url, this.Params, { headers: HEADERS_FORM_DATA() }).then((response) => {
    //     this.Response = response;
    // });
    const myHeaders = new Headers();
    myHeaders.append("AppName", "DbBee");
    myHeaders.append(
      "Authorization",
      "Bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9"
    );

    const requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: this.Params,
      redirect: "follow",
    };

    var url = `${BASE_URL}${this.ServiceName}/${this.Controller}`;
    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  }

  async PostBlob2() {
    var url = `${BASE_URL}${this.ServiceName}/${this.Controller}`;
    await axios.post(url, this.Params, { headers: HEADERS_FORM_DATA() }).then((response) => {
    this.Response = response;
    });
   
}
}